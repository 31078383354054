import React, { useState, useContext, useEffect, useRef } from "react";
import { Select, Input } from "antd";
import { Upload, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputField from "../Generic/InputField";
import { Avatar } from "@mui/material";
import "../AddNewCandidate/addnewcandidate.css";
import SimpleModal from "../Modal";
import AppContext from "../../context/appState/AppContext";
import {
  useUpdateCandidateMutation,
  useGetAllCitiesMutation,
  useGetAllCountriesMutation,
  useGetCandidateByIDQuery,
} from "../../services/nodeAPI";
import { VariantType, useSnackbar } from "notistack";
import { XIcon } from "@heroicons/react/solid";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import WorkExpForm from "./WorkExpForm";
import { useSelector } from "react-redux";

export default function EditCandidate(props) {
  const { enqueueSnackbar } = useSnackbar();
  // console.log(location.state);

  const { candidateId } = useParams();
  const formRef = useRef(null);
  const [updateCandidate] = useUpdateCandidateMutation();
  const [getAllCities] = useGetAllCitiesMutation();
  const [getAllCountries] = useGetAllCountriesMutation();
  // const candidateId = useSelector((state) => state.candidate.candidateId);
  const navigate = useNavigate();

  const {
    data: candidateData,
    isLoading,
    isFetching,
  } = useGetCandidateByIDQuery(candidateId);

  // const { Dragger } = Upload;
  // const { Option, OptGroup } = Select;
  // const { TextArea } = Input;
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const [tags, setTags] = useState([]);

  const [workExpData, setWorkExpData] = useState([]);
  const [workExpForm, setWorkExpForm] = useState([]);
  const [data, setData] = useState({ ...candidateData?.candidateData });

  function handleFormData(key, value) {
    if (key === "countryId") {
      allCities(value);
    }

    setData({
      ...data,
      [key]: value,
    });

    // console.log(event.target.name, event.target.value);
  }

  useEffect(() => {
    setTimeout(() => {
      handleFormData("skills", tags);
    }, 0);
  }, [tags]);

  useEffect(() => {
    if (candidateData) {
      setData({ ...candidateData.candidateData });
      setTimeout(() => {
        setTags([...candidateData.candidateData.skills]);
        allCities(candidateData.candidateData.countryId);
        preFiledWorkExpForm(candidateData.candidateData.experiences);
      }, 0);
      console.log("fetched candidate data", candidateData);
    }
  }, [isLoading]);

  const updateWorkData = (index, object) => {
    setWorkExpData(() => {
      workExpData[index] = object;
      return workExpData;
    });
  };

  const updateTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  const removeTags = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const allCountries = async () => {
    const { data } = await getAllCountries();
    setCountries(data.countryData);
  };

  const allCities = (id) => {
    getAllCities(id).then((res) => {
      console.log("Fetched All data");
      console.log(res.data.citiesData);
      setCities(res.data.citiesData);
    });
  };

  useEffect(() => {
    allCountries();
  }, [cities]);

  useEffect(() => {
    setTimeout(() => {
      handleFormData("skills", tags);
    }, 0);
  }, [tags]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [photoURL, setPhotoURL] = useState(null);
  const [realPhotoURL, setRealPhotoURL] = useState(null);
  const [file, setFile] = useState("");

  const [openCrop, setOpenCrop] = useState(false);

  const handleFileUpload = (e) => {
    const f = e.target.files[0];
    if (f) {
      setOpenCrop(true);
      setPhotoURL(URL.createObjectURL(f));
      setFile(f);
      showModal();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const img = new File([file], `profile_img${Date.now()}`);
    let formData = new FormData();
    formData.append("candidate_id", data.id);
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    formData.append("phone_number", data.phone_number);
    formData.append("date_of_birth", data.date_of_birth);
    formData.append("countryId", data.countryId);
    formData.append("cityId", data.cityId);
    formData.append("gender", data.gender);
    formData.append("profile_img", img);
    formData.append("education", data.education);
    formData.append("experiences", JSON.stringify(workExpData));
    formData.append("skills", data.skills);

    updateCandidate(formData).then((response) => {
      if (response.data.success) {
        enqueueSnackbar("Record Updated successfully!", {
          variant: "success",
        });
        window.location.reload(true);
        // window.location.reload(true);
      } else {
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      }
    });
  };

  function addWorkExperienceForm() {
    setWorkExpForm([...workExpForm, workExperienceForm({})]);
  }

  function preFiledWorkExpForm(experiences) {
    if (experiences !== null) {
      var expForms = [];
      for (var i = 0; i < experiences.length; i++) {
        expForms.push(workExperienceForm(experiences[i], i));
      }
      setWorkExpForm(expForms);
    }
  }

  function workExperienceForm(previousData, form_index) {
    var curr_index = workExpForm.length;
    if (form_index !== null && form_index !== undefined) {
      curr_index = form_index;
    }
    return (
      <WorkExpForm
        index={curr_index}
        updateWorkExpData={updateWorkData}
        candidateData={previousData}
      />
    );
  }

  function removeWorkExperienceForm(indexToRemove) {
    setWorkExpForm(workExpForm.filter((_, index) => index !== indexToRemove));
    setWorkExpData(workExpData.filter((_, index) => index !== indexToRemove));
  }

  return (
    <>
      <div className="px-2">
        <div className="mb-3">
          <div className="col-4 main_Header mt-5">
            <p className="text-2xl font-medium">Edit Candidate</p>
            <p
              className="text-sm font-normal text-gray-400"
              style={{ marginTop: "-20px", width: "400px" }}
            >
              In this section you can edit candidates data.
            </p>
          </div>
        </div>

        {/* <form > */}

        <div
          className="my-3 pt-2"
          style={{ fontSize: "20px", fontWeight: "500" }}
        >
          Candidate Details
        </div>

        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-6">
                <div className="mb-4">
                  {data?.firstname === undefined &&
                  data.firstname === " " &&
                  data.firstname === ""
                    ? window.location.reload(true)
                    : console.log("data founded: ", data)}
                  <InputField
                    label="First Name"
                    name="firstname"
                    onChange={(event) =>
                      handleFormData(event.target.name, event.target.value)
                    }
                    placeholder="First Name"
                    type="text"
                    defaultValue={data.firstname}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-4">
                  <InputField
                    label="Last Name"
                    name="lastname"
                    onChange={(event) =>
                      handleFormData(event.target.name, event.target.value)
                    }
                    placeholder="Last Name"
                    type="text"
                    defaultValue={data.lastname}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="mb-4">
                  <InputField
                    label="Email"
                    name="email"
                    onChange={(event) =>
                      handleFormData(event.target.name, event.target.value)
                    }
                    placeholder="Email"
                    type="email"
                    defaultValue={data.email}
                    disabled
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-4">
                  <InputField
                    label="LinkedIn Profile Url"
                    onChange={(event) =>
                      handleFormData(event.target.name, event.target.value)
                    }
                    name="linkedin_url"
                    type="text"
                    placeholder="LinkedIn profile url"
                    defaultValue={data.linkedin_url}
                    disabled
                  />
                  <p
                    id="helper-text-explanation"
                    class="mt-2 text-xs text-gray-500 dark:text-gray-400"
                  >
                    Linkedin Profile Handle.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 ps-4">
            <div className="d-flex">
              <div className="">
                <label
                  for="profileImage"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Profile Image
                </label>
                <div className="p-2">
                  {data?.profile_img !== null ? (
                    <Avatar
                      src={data.profileImg}
                      alt={data.firstname}
                      sx={{ width: 100, height: 100 }}
                      style={{ marginTop: "" }}
                    />
                  ) : (
                    <div className="flex items-center justify-center w-28 h-28 text-4xl font-normal text-white bg-blue-800 rounded-full">
                      {data.firstname.charAt(0).toUpperCase()}
                    </div>
                  )}
                </div>
              </div>
              <div
                className="pb-5 ms-4 relative"
                style={{ paddingTop: "4rem" }}
              >
                <Input
                  className="z-10 border relative opacity-0 w-50"
                  onChange={handleFileUpload}
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                />
                <button
                  type="button"
                  class="text-white absolute left-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-normal rounded text-sm px-4 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Upload Image
                </button>
                <label htmlFor="contained-button-file">
                  {/* <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#265BC4",
                      borderRadius: "4px",
                      color: "white",
                      width: "170px",
                      height: "37px",
                    }}
                    className="btn img_upload_btn"
                    component="span"
                  >
                    Upload image
                  </Button> */}
                </label>
              </div>

              {
                <SimpleModal
                  handleOk={handleOk}
                  handleCancel={handleCancel}
                  isModalVisible={isModalVisible}
                  realPhotoURL={realPhotoURL}
                  setRealPhotoURL={setRealPhotoURL}
                  photoURL={photoURL}
                  setPhotoURL={setPhotoURL}
                  setOpenCrop={setOpenCrop}
                  setFile={setFile}
                />
              }
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            <div className="row">
              <div className="col-12">
                {/* <span>Select Gender</span> */}
                <div className="">
                  <label
                    for="gender"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  >
                    Select Gender
                  </label>
                  <select
                    id="gender"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(event) =>
                      handleFormData(event.target.name, event.target.value)
                    }
                    name="gender"
                  >
                    <option selected>Gender</option>
                    <option
                      value="male"
                      selected={data.gender === "male" ? true : false}
                    >
                      Male
                    </option>
                    <option
                      value="female"
                      selected={data.gender === "female" ? true : false}
                    >
                      Female
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <label
                  for="dob"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  Date Of Birth
                </label>
                <DatePicker
                  id="dob"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="date_of_birth"
                  onChange={(_, dateString) =>
                    handleFormData("date_of_birth", dateString)
                  }
                  defaultValue={data.date_of_birth}
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              <div className="col-12">
                <InputField
                  label="Phone Number"
                  name="phone_number"
                  onChange={(event) =>
                    handleFormData(event.target.name, event.target.value)
                  }
                  placeholder="Phone Number"
                  type="text"
                  width={"100%"}
                  height="32px"
                  defaultValue={data.phone_number}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <div>
                  <label
                    for="country"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  >
                    Select Country
                  </label>
                  <select
                    id="country"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(event) =>
                      handleFormData(event.target.name, event.target.value)
                    }
                    name="countryId"
                  >
                    <option selected>Select Country</option>
                    {countries.map((el, i) => {
                      return (
                        <option
                          key={i}
                          value={el.id}
                          selected={data.countryId === el.id ? true : false}
                        >
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              <div className="col-12">
                <div>
                  <InputField
                    label="Highest Education"
                    name="education"
                    onChange={(event) =>
                      handleFormData(event.target.name, event.target.value)
                    }
                    placeholder="Bachelors, Masters, etc..."
                    type="text"
                    defaultValue={data.education}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <div>
                  <label
                    for="city"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  >
                    Select City
                  </label>
                  <select
                    id="city"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(event) =>
                      handleFormData(event.target.name, event.target.value)
                    }
                    name="cityId"
                  >
                    <option selected value="none">
                      Select City
                    </option>
                    {cities.map((el, i) => {
                      return (
                        <option
                          key={i}
                          value={el.id}
                          selected={data.cityId === el.id ? true : false}
                        >
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-9">
            <div className="col-12">
              <label
                for="address"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Candidate's Skills
              </label>

              <div className="flex flex-wrap w-100 border-1 rounded-lg p-2 hover:border-blue-600 focus:border-blue-600 ">
                <ul id="" className="flex flex-wrap space-x-2">
                  {tags.map((tag, index) => (
                    <li
                      key={index}
                      className="flex block bg-blue-100 py-1.5 px-2.5 text-center text-blue-800 rounded-xl mt-2"
                    >
                      <span className="font-normal text-sm">{tag}</span>
                      <XIcon
                        className="h-4 w-4 ms-1 mt-0.5 cursor-pointer"
                        aria-hidden="true"
                        onClick={() => removeTags(index)}
                      />
                    </li>
                  ))}
                </ul>
                <input
                  type="text"
                  placeholder="Press Enter to add skills..."
                  className="border-transparent focus:border-transparent focus:ring-0 text-sm block focus:outline-none"
                  onKeyUp={(event) =>
                    event.key === "Enter" ? updateTags(event) : null
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* 
            ////////////////////////////////////////////
            ///////////////////////////////////////////
          */}

        <div className="my-3 pt-2">
          <div style={{ fontSize: "20px", fontWeight: "500" }}>
            Work Experience
          </div>

          <div>
            {workExpForm.map((expForm, index) => (
              <div key={index}>
                {expForm}

                <div
                  className="flex col-1 justify-content-start cursor-pointer"
                  onClick={() => removeWorkExperienceForm(index)}
                >
                  <span className="text-red-600">
                    <FontAwesomeIcon icon={faMinus} />{" "}
                    <span className="ms-2">Remove</span>
                  </span>
                </div>
              </div>
            ))}
          </div>

          <div
            className="flex col-6 justify-content-end cursor-pointer"
            onClick={addWorkExperienceForm}
          >
            <span className="text-blue-600">
              <FontAwesomeIcon icon={faPlus} />{" "}
              <span className="ms-2">Add Work Experience</span>
            </span>
          </div>
        </div>

        {/* 
            //////////////////////////////////////////
            /////////////////////////////////////////
          */}

        <div className="flex justify-content-end">
          <button
            onClick={handleSubmit}
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 mr-2 mb-2 mt-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Save Candidate Data
          </button>
        </div>

        {/* <Button type="submit" className="my-5 px-3" style={{backgroundColor:'#265BC4',color:'white',borderRadius:'4px'}}>
            
          </Button> */}
        {/* </form> */}
      </div>
    </>
  );
}
