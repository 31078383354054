import React, { useEffect, useState } from "react";
import DashboardCard from "./DashboardCard";
import "./dashboard.css";
import Consultant from "./Consultant";
import SimpleMap from "./SimpleMap";
import CHART from "../Chart/CHART";
import { Select } from "antd";
import { ReactComponent as CandidatesIcon } from "../../img/candidates.svg";
import { ReactComponent as ConsultantsIcon } from "../../img/consult.svg";
import { ReactComponent as JobIcon } from "../../img/job.svg";
import { ReactComponent as MailIcon } from "../../img/mail.svg";
import {
  useMonthlyCandidatesMutation,
  useMonthlyJobsMutation,
  useCardStatsMutation,
  useNewConsultantsMutation,
} from "../../services/nodeAPI";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const { Option } = Select;

  const resources = JSON.parse(localStorage.getItem("resources"));

  const [getMonthlyCandidates] = useMonthlyCandidatesMutation();
  const [getMonthlyJobs] = useMonthlyJobsMutation();
  const [getCardStats] = useCardStatsMutation();
  const [getNewConsultants] = useNewConsultantsMutation();

  const [cardTotalJobs, setCardTotalJobs] = useState(0);
  const [cardTotalCandidates, setCardTotalCandidates] = useState(0);
  const [cardTotalConsultants, setCardTotalConsultants] = useState(0);

  const [monthlyCandidates, setMonthlyCandidates] = useState(Array(12).fill(0));
  const [monthlyJobs, setMonthlyJobs] = useState(Array(12).fill(0));

  const [newConsultants, setNewConsultants] = useState([]);

  useEffect(() => {
    getCardStats("1 year").then((response) => {
      setCardTotalCandidates(response.data.stats[0].total_candidates);
      setCardTotalConsultants(response.data.stats[0].total_consultants);
      setCardTotalJobs(response.data.stats[0].total_jobs);
    });

    getMonthlyCandidates().then((response) => {
      console.log(response);
      var newMonthlyData = [...monthlyCandidates];
      for (let i = 0; i < response.data.monthly_candidates.length; i++) {
        let obj = response.data.monthly_candidates[i];
        newMonthlyData[obj.month - 1] = parseInt(obj.count);
      }
      setMonthlyCandidates(newMonthlyData);
    });

    getMonthlyJobs().then((response) => {
      console.log(response);
      var newMonthlyData = [...monthlyJobs];
      for (let i = 0; i < response.data.monthly_jobs.length; i++) {
        let obj = response.data.monthly_jobs[i];
        newMonthlyData[obj.month - 1] = parseInt(obj.count);
      }
      setMonthlyJobs(newMonthlyData);
    });

    getNewConsultants().then((response) => {
      setNewConsultants(response.data.consultants);
    });

    console.log(monthlyCandidates);
  }, []);

  function updateCardStatsInterval(interval) {
    getCardStats(interval).then((response) => {
      setCardTotalCandidates(response.data.stats[0].total_candidates);
      setCardTotalConsultants(response.data.stats[0].total_consultants);
      setCardTotalJobs(response.data.stats[0].total_jobs);
    });
  }

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <>
      <div className="px-3 pe-4 pt-3">
        <div className="row">
          <div className="flex flex-wrap justify-between">
            <div className="">
              <h4 className="text-2xl font-medium">Overview</h4>
            </div>
            <div className="flex flex-wrap">
              <div className="ms-2 dashboard_btns">
                <button
                  className="btn btn-sm days_btn"
                  onClick={() => updateCardStatsInterval("24 hours")}
                >
                  Day
                </button>
              </div>
              <div className="ms-2 dashboard_btns">
                <button
                  className="btn  btn-sm weeks_btn"
                  onClick={() => updateCardStatsInterval("1 week")}
                >
                  Week
                </button>
              </div>
              <div className="ms-2 dashboard_btns">
                <button
                  className="btn btn-sm months_btn"
                  onClick={() => updateCardStatsInterval("1 month")}
                >
                  Month
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="gap-3 flex flex-wrap my-5">
          <div className="w-72">
            <DashboardCard
              name="Total consultants"
              value={cardTotalConsultants}
              icon={ConsultantsIcon}
            />
          </div>
          <div className="w-72">
            <DashboardCard
              name="Total Job Openings"
              value={cardTotalJobs}
              icon={JobIcon}
            />
          </div>
          <div className="w-72">
            <DashboardCard
              name="Total Candidates Data"
              value={cardTotalCandidates}
              icon={CandidatesIcon}
            />
          </div>
        </div>
        <div className="row">
          <div className="w-full">
            <div
              id="chartContainer"
              style={{
                width: "100%",
                border: "1px solid #D7DBEC",
                borderRadius: "8px",
              }}
            >
              <div className="row p-4">
                <div className="flex flex-wrap justify-between">
                  <div className="">
                    {" "}
                    <span className="text-xl font-normal">
                      Candidates and Jobs Analysis
                    </span>
                  </div>
                  <div className="">
                    <span>Past Year</span>
                  </div>
                </div>
              </div>
              <div className="text-center">
                {" "}
                <span
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#21D59B",
                    display: "inline-block",
                  }}
                ></span>
                <span>&nbsp;Candidates Data&nbsp;&nbsp;&nbsp;&nbsp; </span>
                <span
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#0058FF",
                    display: "inline-block",
                  }}
                ></span>
                <span>&nbsp;Jobs Opening</span>
              </div>
              <CHART
                monthlyJobs={monthlyJobs}
                monthlyCandidates={monthlyCandidates}
              />
            </div>
          </div>
        </div>

        <div className="gap-2 flex flex-wrap justify-between mt-10">
          <div className="mb-4 w-full md:w-6/12 lg:w-5/12 2xl:w-6/12">
            <div class="p-4 bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
              <div class="flex justify-between items-center">
                <h5 class="text-xl font-semibold leading-none text-gray-900 dark:text-white">
                  New Consultants
                </h5>

                {resources.includes("3") && (
                  <Link
                    to="/dashboard/consultants"
                    class="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                  >
                    View all
                  </Link>
                )}
              </div>
              <div class="flow-root">
                <ul
                  role="list"
                  class="divide-y divide-gray-200 dark:divide-gray-700"
                >
                  {newConsultants.length > 0 ? (
                    newConsultants.map((item) => {
                      return (
                        <li class="py-2.5 sm:py-4">
                          <Consultant
                            key={item.id}
                            data={{
                              name: item.firstname + " " + item.lastname,
                              email: item.email,
                              profile_img: item.profile_img,
                            }}
                          />
                        </li>
                      );
                    })
                  ) : (
                    <li class="py-2.5 sm:py-4">
                      <span>No Data Found!</span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="mb-4 w-full lg:w-6/12 2xl:w-5/12">
            <div className="p-4 bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
              <h5 class="text-base font-semibold leading-none text-gray-900 dark:text-white">
                Jobs opening by country
              </h5>
              <div className="">
                <SimpleMap />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
