import "../css/SideNavbar.css";
import "../css/sideNavAnimate.scss";
import { useLocation } from "react-router-dom";
import { Link, Outlet } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { ReactComponent as CandSearch } from "../img/candidate_search.svg";
import { ReactComponent as Candidates } from "../img/candidates.svg";
import { ReactComponent as Consultants } from "../img/consult.svg";
import { ReactComponent as Dashboard } from "../img/dash12.svg";
import { ReactComponent as Employee } from "../img/employee.svg";
import { ReactComponent as ManageRole } from "../img/roles.svg";
import { ReactComponent as Job } from "../img/job.svg";

export const SideNavbar = (props) => {
  const location = useLocation();

  const resources = JSON.parse(localStorage.getItem("resources"));

  return (
    // d-flex flex-column flex-shrink-0
    <>
      <aside
        className={`${
          props.mobileMenu === true ? "md:block" : "hidden md:block"
        } overflow-y-hidden w-full md:w-20 2xl:w-64 max-h-screen bg-white border-1 border-gray-150 fixed z-10 shadow-md top-0`}
        aria-label="Sidebar"
        id="side-bar-menu"
      >
        <div className="h-screen py-4 bg-white rounded dark:bg-gray-800">
          <ul className="space-y-2 mt-20 px-3">
            <li className="">
              <Link
                to="/dashboard/home"
                className={`flex items-center px-2.5 py-2.5 text-base font-normal rounded-lg text-gray-800 ${
                  location.pathname.includes("home")
                    ? "bg-blue-500 text-white"
                    : "hover:bg-gray-100 hover:text-gray-800"
                }`}
              >
                <Dashboard className="" fill="CurrentColor" />
                <span className="md:hidden block 2xl:block flex-1 ml-4 whitespace-nowrap">
                  Dashboard
                </span>
              </Link>
            </li>

            {resources.includes("1") && (
              <li className="">
                <Link
                  to="/dashboard/jobs"
                  className={`flex items-center px-2.5 py-2.5 text-base font-normal rounded-lg text-gray-800 ${
                    location.pathname.includes("job")
                      ? "bg-blue-500 text-white"
                      : "hover:bg-gray-100 hover:text-gray-800"
                  }`}
                >
                  <Job className="" fill="CurrentColor" />
                  <span className="md:hidden block 2xl:block flex-1 ml-4 whitespace-nowrap">
                    Jobs
                  </span>
                </Link>
              </li>
            )}

            {resources.includes("2") && (
              <li className="">
                <Link
                  to="/dashboard/candidates"
                  className={`flex items-center px-2.5 py-2.5 text-base font-normal rounded-lg text-gray-800 ${
                    location.pathname.includes("candidate")
                      ? "bg-blue-500 text-white"
                      : "hover:bg-gray-100 hover:text-gray-800"
                  }`}
                >
                  <Candidates className="" fill="CurrentColor" />
                  <span className="md:hidden block 2xl:block flex-1 ml-4 whitespace-nowrap">
                    Candidates
                  </span>
                </Link>
              </li>
            )}

            {resources.includes("4") && (
              <li className="">
                <Link
                  to="/dashboard/employees"
                  className={`flex items-center px-2.5 py-2.5 text-base font-normal rounded-lg text-gray-800 ${
                    location.pathname.includes("employee")
                      ? "bg-blue-500 text-white"
                      : "hover:bg-gray-100 hover:text-gray-800"
                  }`}
                >
                  <Employee className="" fill="CurrentColor" />
                  <span className="md:hidden block 2xl:block flex-1 ml-4 whitespace-nowrap">
                    Employees
                  </span>
                </Link>
              </li>
            )}

            {resources.includes("2") && (
              <li className="">
                <Link
                  to="/dashboard/smartsearch"
                  className={`flex items-center px-2.5 py-2.5 text-base font-normal rounded-lg text-gray-800 ${
                    location.pathname.includes("smartsearch")
                      ? "bg-blue-500 text-white"
                      : "hover:bg-gray-100 hover:text-gray-800"
                  }`}
                >
                  <CandSearch className="" fill="CurrentColor" />
                  <span className="md:hidden block 2xl:block flex-1 ml-4 whitespace-nowrap">
                    Smart Search
                  </span>
                </Link>
              </li>
            )}

            {resources.includes("3") && (
              <li className="">
                <Link
                  to="/dashboard/consultants"
                  className={`flex items-center px-2.5 py-2.5 text-base font-normal rounded-lg text-gray-800 ${
                    location.pathname.includes("consultant")
                      ? "bg-blue-500 text-white"
                      : "hover:bg-gray-100 hover:text-gray-800"
                  }`}
                >
                  <Consultants className="" fill="CurrentColor" />
                  <span className="md:hidden block 2xl:block flex-1 ml-4 whitespace-nowrap">
                    Consultants
                  </span>
                </Link>
              </li>
            )}

            {resources.includes("5") && (
              <li className="">
                <Link
                  to="/dashboard/manageroles"
                  className={`flex items-center px-2.5 py-2.5 text-base font-normal rounded-lg text-gray-800 ${
                    location.pathname.includes("role")
                      ? "bg-blue-500 text-white"
                      : "hover:bg-gray-100 hover:text-gray-800"
                  }`}
                >
                  <ManageRole className="" fill="CurrentColor" />
                  <span className="md:hidden block 2xl:block flex-1 ml-4 whitespace-nowrap">
                    Manage Role
                  </span>
                </Link>
              </li>
            )}
          </ul>
          <div
            id="dropdown-cta"
            className="m-2 p-4 mt-6 bg-blue-50 rounded-lg dark:bg-blue-900 md:hidden 2xl:block"
            role="alert"
          >
            <div className="flex items-center mb-3">
              <span className="bg-orange-100 text-orange-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-orange-200 dark:text-orange-900">
                Notice
              </span>
              <button
                type="button"
                className="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-900 rounded-lg focus:ring-2 focus:ring-blue-400 p-1 hover:bg-blue-200 inline-flex h-6 w-6 dark:bg-blue-900 dark:text-blue-400 dark:hover:bg-blue-800"
                data-collapse-toggle="dropdown-cta"
                aria-label="Close"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <p className="mb-3 text-sm text-blue-900 dark:text-blue-400">
              This is the Beta version of the website.
              <br />A new update of Machine Learning Model is coming soon!
              <br />
              In case of any error please contact support by using the given
              email below:
            </p>
            <a
              className="text-sm text-blue-900 underline hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
              href="mailto:abdul@meerkatsearch.com"
            >
              abdul@meerkatsearch.com
            </a>
          </div>
        </div>
      </aside>

      {/* <div className="side_bar">
        <ul className="nav nav-pills flex-column mb-auto">
          {
            <li >
              <Link to='/dashboard' className={`nav-link ${location.pathname.endsWith( "dashboard" )||location.pathname.endsWith( '/' )||location.pathname.endsWith( 'd' )? "side_bar_active":'side_bar_link'}`} aria-current="page">
//                <i className="fa-solid fa-user side_bar_icon" />
                <span className='side_bar_icon'> <DashboardIcon /> </span>
                Dashboards
              </Link>
            </li>
          }

          {
            <li>
              <Link to='/dashboard/jobs' className={`nav-link  ${location.pathname.includes( "job" )? "side_bar_active":'side_bar_link'}`}>
                <span className='side_bar_icon'> <WorkIcon /> </span>

                Jobs
              </Link>
            </li>
          }

          {
            <li>
              <Link to='/dashboard/candidates' className={`nav-link  ${location.pathname.includes( "candidate" )? "side_bar_active":'side_bar_link'}`}>
                <span className='side_bar_icon'> <PeopleIcon /> </span>
                Candidates
              </Link>
            </li>
          }

          {
            <li>
              <Link to='/dashboard/employees' className={`nav-link  ${location.pathname.includes( "employee" )? "side_bar_active":'side_bar_link'}`}>
                <span className='side_bar_icon'> <PeopleIcon /> </span>
                Employees
              </Link>
            </li>
          }
          {
            <li>
              <Link to='/dashboard/smartsearch' className={`nav-link  ${location.pathname.includes( "smartsearch" )? "side_bar_active":'side_bar_link'}`}>
                <span className='side_bar_icon'> <SearchTwoToneIcon /> </span>
                Smart Search
              </Link>
            </li>
          }

          {
            <li>
              <Link to='/dashboard/consultants' className={`nav-link  ${location.pathname.includes( "consultant" )? "side_bar_active":'side_bar_link'}`}>
                <span className='side_bar_icon'> <PeopleIcon /> </span>
                Consultants
              </Link>
            </li>
          }

          {
            <li>
              <Link to='/dashboard/manageroles' className={`nav-link  ${location.pathname.includes( "role" )? "side_bar_active":'side_bar_link'}`}>
                <span className='side_bar_icon'> <PermDeviceInformationIcon /> </span>
                Manage Roles
              </Link>
            </li>
          }




        </ul>
      </div> */}
    </>
  );
};
