import React, { Component, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import TableComp from "../TableComp/TableComp";
import { Link } from "react-router-dom";
// import { useGetRolesQuery } from "../../services/nodeAPI";
import Tooltip from "@mui/material/Tooltip";
import AddNewRole from "../AddNewRole/AddNewRole";
import {
  useGetRolesQuery,
  useCountRolesQuery,
  useFindRolePermissionsMutation,
} from "../../services/nodeAPI";
import UpdateRole from "./UpdateRole";
import { ReactComponent as DeleteIcon } from "../../img/delete.svg";
import { ReactComponent as EditIcon } from "../../img/edit.svg";
import { ReactComponent as VisibilityIcon } from "../../img/visibility.svg";
import DeleteRole from "./DeleteRole";

export default function RoleManagement() {
  // const [ DATA, setDATA ]=useState( null );

  const [pageNumber, setPageNumber] = useState(1);
  const [itemLimit, setitemLimit] = useState(8);

  const rId = "5";

  const getPermissionsOfUserForResource = () => {
    let data = [];
    const permRes = JSON.parse(localStorage.getItem("perm_res"));

    permRes.forEach((el) => {
      if (el.resourceId === rId) {
        if (!data.find((e) => e === el.permissionId)) {
          data.push(el.permissionId);
        }
      }
    });

    return data;
  };

  const [tempRolesData, setRolesData] = useState({});

  var { data: totalRoles } = useCountRolesQuery();
  // console.log(totalRoles);

  var {
    data: rolesData,
    isLoading,
    isFetching,
  } = useGetRolesQuery({ itemLimit, pageNumber });

  const [showModal, setShowModal] = useState(false);
  // console.log( rolesData )

  // setDATA( rolesData );

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      width: "1%",
      align: "center",
    },
    {
      title: "Role Name",
      dataIndex: "rolename",
      key: "rolename",
      width: "10%",
      align: "center",
    },
    {
      title: "Permissions for role",
      dataIndex: "permissions",
      key: "permissions",
      width: "60%",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      width: "29%",
      align: "center",
      render: () => (
        <>
          {/* <Tag
            color="green"
            className=""
            style={{
              borderRadius: "3px",
              width: "75px",
              height: "26px",
              paddingTop: "2.1px",
            }}
          >
            Details          </Tag>
          <Tag
            color="blue"
            className=""
            style={{
              borderRadius: "3px",
              width: "75px",
              height: "26px",
              paddingTop: "2.1px",
            }}
          >
            Edit          </Tag>

          <Popconfirm title="Sure to delete?" >
            <Tag
              color="red"
              className=""
              style={{
                borderRadius: "3px",
                width: "75px",
                height: "26px",
                paddingTop: "2.1px",
              }}
            >
              Delete          </Tag>
          </Popconfirm> */}

          <div className="d-flex justify-content-left">
            <div>
              <Tooltip title="Detail" placement="top" arrow>
                <Link
                  to="/dashboard/viewcandidate"
                  style={{ color: "#6F747E" }}
                >
                  <VisibilityIcon
                    style={{ color: "#6F747E" }}
                    fill="CurrentColor"
                  />
                  {/* <FeaturedPlayListIcon  style={{color:'#69AC64'}}/> */}
                </Link>
              </Tooltip>
            </div>

            <div className="cursor-pointer ps-2">
              <Tooltip title="Edit" placement="top" arrow>
                <EditIcon
                  className=""
                  style={{ color: "#6F747E" }}
                  fill="CurrentColor"
                />
              </Tooltip>
            </div>
            <div className="cursor-pointer ps-2">
              <Tooltip title="Delete" placement="top" arrow>
                <div>
                  <DeleteIcon
                    style={{ color: "#6F747E" }}
                    fill="CurrentColor"
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </>
      ),
    },
  ];

  function prevPageData() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  function nextPageData() {
    var maxRoles = totalRoles.roles[0].count;
    if (itemLimit * pageNumber < maxRoles) {
      setPageNumber(pageNumber + 1);
    }
  }

  const dataS =
    !isLoading &&
    rolesData?.roles.map((el, i) => {
      return {
        key: el.roleid,
        index: i + 1,
        rolename: el.rolename,
        permissions: el.permissions.join(", ").slice(0, 72) + "...",
      };
    });

  const [dataSource, setDataSource] = useState(dataS);
  const [value, setValue] = useState("");

  const [updateRoleId, setUpdateRoleId] = useState(undefined);
  const [updateRoleName, setUpdateRoleName] = useState("");
  const [updateRolePermissions, setUpdateRolePermissions] = useState([]);
  const [fetchRolePermissions] = useFindRolePermissionsMutation();
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  function openUpdateModal() {
    setShowUpdateModal(true);
  }

  function closeUpdateModal() {
    setShowUpdateModal(false);
    setUpdateRoleId(undefined);
    setUpdateRolePermissions([]);
  }

  const searchByName = (e) => {
    const currValue = e.target.value;
    setValue(currValue);
    const filteredData = dataS.filter((entry) =>
      entry.rolename.toLowerCase().includes(currValue.toLowerCase())
    );
    setDataSource(filteredData);
  };

  function showUpdateRoleDialog(id, roleName) {
    console.log("=>>>", id, roleName);
    if (id !== undefined) {
      setUpdateRoleId(id);
      setUpdateRoleName(roleName);
      fetchRolePermissions(id).then((response) => {
        if (response.data.success) {
          var permisisonResources = response.data.data.map((permissions) => {
            return {
              pId: permissions.permissionId,
              rId: permissions.resourceId,
            };
          });
          setUpdateRolePermissions(permisisonResources);
          openUpdateModal();
        }
      });
    }

    // setUpdateRolePermissions(permissions);
  }

  const [delRoleData, setDelRoleData] = useState({});
  const [showDelModal, setShowDelModal] = useState(false);

  function openDelModal(id, name) {
    setDelRoleData({ id, name });
    setShowDelModal(true);
  }

  function closeDelModal() {
    setShowDelModal(false);
  }

  const permissions = getPermissionsOfUserForResource();
  console.log(permissions);

  return (
    <>
      <AddNewRole showModal={showModal} closeModal={closeModal} />
      <UpdateRole
        showModal={showUpdateModal}
        closeModal={closeUpdateModal}
        roleId={updateRoleId}
        updateRoleName={updateRoleName}
        rolePermissions={updateRolePermissions}
      />
      <DeleteRole
        showModal={showDelModal}
        closeModal={closeDelModal}
        roleId={delRoleData.id}
        roleName={delRoleData.name}
      />

      <div className="px-3">
        <div className="row my-4 pt-3">
          <div className="d-flex justify-content-between">
            <div>
              <span className="text-2xl font-medium dark:text-white">
                Role Management
              </span>
              <p className="test-base font-base text-gray-500 dark:text-white">
                You can manage roles and permissions in this section{" "}
              </p>
            </div>

            {permissions.includes("2") && (
              <div className="my-auto">
                <button
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-base rounded text-sm px-4 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  onClick={openModal}
                >
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  <span className="ms-2"> Add New Role</span>
                </button>
              </div>
            )}
          </div>
        </div>
        {/* <div className="row">
          <div className="col-5">
            <span className="text-lg font-semibold dark:text-white">
              Search Filters
            </span>
            <p className="test-base font-base text-gray-500 dark:text-white">
              With the help of filters you can easily find roles.{" "}
            </p>
          
            <div className="flex items-center mt-3">
              <div className="relative w-50">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search Roles by name..."
                  value={value}
                  onChange={searchByName}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="mt-4">
          {isLoading === true ? (
            <div class="text-center mt-40">
              <svg
                role="status"
                class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : (
            <div>
              <TableComp
                data={rolesData?.roles.map((el, i) => {
                  return {
                    key: el.roleid,
                    index: i + 1,
                    rolename: el.rolename,
                    permissions: el.permissions.join(", ").slice(0, 72) + "...",
                  };
                })}
                showUpdateRoleDialog={showUpdateRoleDialog}
                deleteModelOpen={openDelModal}
                footer="3 Roles found"
                columns={columns}
                tableName="roles"
                permissions={permissions}
              />
              {rolesData !== undefined && rolesData.roles.length > 0 && (
                <div className="mt-4">
                  <div class="flex flex-col items-end">
                    {/* <!-- Help text --> */}
                    <span class="text-sm text-gray-700 dark:text-gray-400">
                      <span class="text-sm text-gray-700 dark:text-gray-400">
                        Showing{" "}
                        <span class="font-semibold text-gray-900 dark:text-white">
                          {itemLimit * pageNumber - (itemLimit - 1)}
                        </span>{" "}
                        to{" "}
                        <span class="font-semibold text-gray-900 dark:text-white">
                          {itemLimit * pageNumber > totalRoles.roles[0].count
                            ? totalRoles.roles[0].count
                            : itemLimit * pageNumber}
                        </span>{" "}
                        of{" "}
                        <span class="font-semibold text-gray-900 dark:text-white">
                          {totalRoles !== undefined
                            ? totalRoles.roles[0].count
                            : 0}
                        </span>{" "}
                        Roles
                      </span>
                    </span>
                    <div class="inline-flex mt-2 xs:mt-0">
                      {/* <!-- Buttons --> */}
                      <button
                        class="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-700 bg-gray-200 rounded-l hover:bg-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={prevPageData}
                      >
                        <svg
                          class="mr-2 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Prev
                      </button>
                      <button
                        class="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-700 bg-gray-200 rounded-r border-0 border-l border-gray-700 hover:bg-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={nextPageData}
                      >
                        Next
                        <svg
                          class="ml-2 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
