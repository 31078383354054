import React from 'react';
import PropTypes from 'prop-types';
import { isStringEmpty } from '../../../lib/helper';

const Avatar = ({ 
  className,
  profileData,
  textSize,
  ...divProps
}) => {
  return (
    <div {...divProps}>
      {isStringEmpty(profileData?.profile_img) ? (
        <div
          id="avatar"
          type="button"
          className={`${className} flex justify-center items-center text-white bg-blue-800 rounded-full cursor-pointer`}
        >
          <span className={`${textSize} font-normal`}>
            {
              profileData?.firstname?.charAt(0)
            }
          </span>
        </div>
      ) : (
        <img
          id="avatar"
          type="button"
          data-dropdown-toggle="userDropdown"
          data-dropdown-placement="bottom-start"
          className={`${className} rounded-full cursor-pointer`}
          src={profileData?.profile_img}
          alt={profileData?.firstname}
        />
      )}
    </div>
  )
}

export default Avatar;

Avatar.defaultProps = {
};

Avatar.propTypes = {
  profileData: PropTypes.object,
  className: PropTypes.string,
  textSize: PropTypes.string
};
