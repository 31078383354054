import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import InputField from "../Generic/InputField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import SimpleModal from "../Modal";
import {  useGetUserDetailsMutation, useUpdateUserDetailsMutation } from "../../services/nodeAPI";
import { VariantType, useSnackbar } from "notistack";


const Details = () => {
  const Input = styled("input")({
    display: "none",
  });
  const [userProfileData, setUserProfileData] = useState({});
  const [getUserDetails] = useGetUserDetailsMutation();
  const [updateUserDetails] = useUpdateUserDetailsMutation();
  const [profileFormData, setProfileFormData] = useState({});


  useEffect(() => {
    getUserDetails().then((response)=>{
      if(response.data.success){
        setUserProfileData(response.data.user_data)
      }
    })
  }, [])

  const updateProfileFormData = (key, value) => {
    setProfileFormData({
      ...profileFormData,
      [key]: value,
    })
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [file, setFile] = useState("");
  // const [updateProfileImage] = useUpdateUserImageMutation();
  const { enqueueSnackbar } = useSnackbar();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);

    if (file) {
      const img = new File([file], `user_img-${Date.now()}`);
      let formData = new FormData();
      formData.append("profile_img", file);

    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const [ photoURL, setPhotoURL ]=useState( currentUser?.photoURL );
  const [photoURL, setPhotoURL] = useState(null);
  const [realPhotoURL, setRealPhotoURL] = useState(null);

  const [openCrop, setOpenCrop] = useState(false);

  const handleFileUpload = (e) => {
    console.log(e.target.files[0]);
    const f = e.target.files[0];
    if (f) {
      setOpenCrop(true);
      setPhotoURL(URL.createObjectURL(f));
      setFile(f);
      showModal();
    }
  };

  const handleDeletImg = () => {
    setRealPhotoURL(null);
    setFile("");
  };

  const submitUserDetails = () => {
    updateUserDetails(profileFormData).then((response) => {
      if (response.data.success) {
        enqueueSnackbar("Profile Details Updated!", { variant: "success" });
      } else {
        enqueueSnackbar("Unable to update!", {
          variant: "error",
        });
      }
    })

  }

  return (
    <div id="details">
      <div className="mt-5">
        <p className="text-xl font-medium dark:text-white">Profile Details</p>
        <p className="test-base font-base text-gray-500 dark:text-white">
          Update your profile picture and personal details here.
        </p>
        <hr className="horizontal_bar w-80" />
      </div>

      <div className="w-100 space-y-4">
        <div className="gap-3 flex flex-wrap mt-4">
          <div className="w-72">
            <div className="md:pt-4">
              <span className="text-lg font-semibold dark:text-white">
                Profile Picture
              </span>
              <p className="test-base font-base text-gray-500 dark:text-white">
                This will be displayed on your profile.
              </p>
            </div>
          </div>

          <div className="md:w-50">
            <div className="d-flex">
              <div className="">
                <Avatar
                  alt={userProfileData.firstname + userProfileData.lastname}
                  src={"https://www.meerkatsearch.com"+userProfileData.profile_img}
                  sx={{ width: 100, height: 100 }}
                  style={{ marginTop: "" }}
                />
              </div>

              <div className="ms-5 pt-4">
                <div>
                  <input
                    onChange={handleFileUpload}
                    class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    id="user_avatar"
                    type="file"
                    hidden
                  />
                  <button
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-normal rounded text-sm px-4 py-2.5 mr-2 mb-2 focus:outline-none"
                    onClick={() => {
                      document.getElementById("user_avatar").click();
                    }}
                  >
                    Upload Image
                  </button>
                  {/* <button
                    type="button"
                    className="ms-2 py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    onClick={handleDeletImg}
                  >
                    Delete
                  </button> */}
                </div>
              </div>

              {
                <SimpleModal
                  handleOk={handleOk}
                  handleCancel={handleCancel}
                  isModalVisible={isModalVisible}
                  realPhotoURL={realPhotoURL}
                  setRealPhotoURL={setRealPhotoURL}
                  photoURL={photoURL}
                  setPhotoURL={setPhotoURL}
                  setOpenCrop={setOpenCrop}
                  setFile={setFile}
                  type="userPic"
                />
              }

              {/* {openCrop&&<CropEasy handleOk={handleOk} handleCancel={handleCancel} isModalVisible={isModalVisible} photoURL={photoURL} setOpenCrop={setOpenCrop} />} */}
            </div>
          </div>

        </div>

        {/* <hr className='horizontal_bar2' /> */}
        <div className="gap-3 flex flex-wrap mt-4">
          <div className="w-72">
            <div className="md:pt-3">
              <span
                className="text-lg font-semibold dark:text-white"
              >
                First name and Last name
              </span>
            </div>

          </div>
          <div className="gap-3 flex flex-wrap">
            <div className="md:w-50">
              <InputField placeholder="Firstname" type="text" width="100%" name="firstname" onChange={(event) => updateProfileFormData(event.target.name, event.target.value)} defaultValue={userProfileData.firstname}/>
            </div>
            <div className="">
              <InputField placeholder="Lastname" type="text" width="100%" name="lastname" onChange={(event) => updateProfileFormData(event.target.name, event.target.value)} defaultValue={userProfileData.lastname}/>
            </div>
          </div>
        </div>

        {/* <hr className='horizontal_bar2' /> */}
        <div className="gap-3 flex flex-wrap mt-4">
          <div className="w-72">
            <div className="md:pt-3">
              <span
                className="text-lg font-semibold dark:text-white"
              >
                Email Address
              </span>
            </div>
          </div>
          <div className="md:w-72">
            <div className="row">
              <div className="w-80">
                <InputField placeholder="Email" type="email" width="100%" defaultValue={userProfileData.email} disabled={true}/>
              </div>
            </div>
          </div>
        </div>


        <div className="gap-3 flex flex-wrap mt-4">
          <div className="w-72">
            <div className="md:pt-3">
              <span
                className="text-lg font-semibold dark:text-white"
              >
                Username
              </span>
            </div>
          </div>

          <div className="md:w-72">
            <div className="row">
              <div className="w-80">
                <InputField placeholder="Username" type="text" width="100%" defaultValue={userProfileData.username} disabled={true}/>
              </div>
            </div>
          </div>        
        </div>

        <div className="text-end mt-5">
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={submitUserDetails}
          >
            Save Changes
          </button>

          {/* <button className='btn save_changes' style={{ marginRight: "-20px !important" }} >Save changes</button> */}
        </div>
      </div>
    </div>
  );
};

export default Details;
