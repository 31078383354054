function isStringEmpty(text) {
  if (text === undefined || text === null) {
    return true
  }

  return /^\s*$/.test(text)
}

function getFormattedDate(date) {
  if (date !== undefined && date !== null) {
    const formatted_date = new Date(date).toLocaleString("default", {
      month: "short",
      year: "numeric"
    });

    if (formatted_date === "Jan 1") {
      return undefined
    }
    return formatted_date
  }
  return undefined
}

function setupDate(start_date, end_date) {
  const formatted_start_date = getFormattedDate(start_date)
  const formatted_end_date = getFormattedDate(end_date)

  if (formatted_start_date === undefined && formatted_end_date === undefined) {
    return ""
  } else if (formatted_start_date !== undefined && formatted_end_date === undefined) {
    return `${formatted_start_date} - Present`
  } else {
    return `${formatted_start_date} - ${formatted_end_date}`
  }
}

module.exports = {
  isStringEmpty,
  getFormattedDate,
  setupDate
}