import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const StaticFilter = ({
    name,
    icon,
    label,
    className,
    filter_items,
    updateFilter,
    ...divProps 
}) => {
    const [selectedItems, setSelectedItems] = useState([])

    const toggleItem = (data) => {
        if (selectedItems.includes(data)) {
            const filteredItems = selectedItems.filter(item => item !== data)
            setSelectedItems(filteredItems)
        } else {
            setSelectedItems([...selectedItems, data])
        }
    }

    useEffect(() => {
        if (name !== undefined) {
            updateFilter(name, selectedItems)
        }
    }, [selectedItems])

    return (
        <div
        {...divProps}
        className={`flex flex-row border-1 border-gray-300 rounded-md h-11 overflow-hidden ${className}`}
        >
            <div className='gap-2 flex flex-row flex-wrap items-center text-sm px-2.5'>
                <div className='flex flex-row space-x-1'>
                    {icon}
                    <span className='font-medium text-black'>
                        {label}
                    </span>
                </div>
                {
                    filter_items.map((item, index) => {
                        return (
                            <div 
                                key={index}
                                className={`flex space-x-2 px-2.5 py-0.5 text-sm rounded-xl cursor-pointer ${
                                    selectedItems.includes(item) ? 'bg-blue-500 text-white border-1 border-blue-500' : 'border hover:bg-gray-200'
                                }`}
                                onClick={() => toggleItem(item)}                          
                            >
                                <span>{item}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default StaticFilter;

StaticFilter.defaultProps = {};

StaticFilter.propTypes = {
    key: PropTypes.string,
    icon: PropTypes.object,
    label: PropTypes.string,
    className: PropTypes.string,
    filter_items: PropTypes.array,
    updateFilter: PropTypes.func
};
