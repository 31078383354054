import React, { useState } from "react";
import { Select } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import { Form, Input, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setPhaseDefault, setQualification } from "../../redux/jobSlice";
import { useAddJobSkillMutation } from "../../services/nodeAPI";
import { useCreateJobMutation } from "../../services/nodeAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const { Option } = Select;

const Qualification = ({ moveNext, moveBack }) => {
  const dispatch = useDispatch();
  const jobId = useSelector((state) => state.job.jobId);
  const jobInfo = useSelector((state) => state.job.jobInfo);
  const defQualification = useSelector((state) => state.job.qualification);
  const [addJobSkill] = useAddJobSkillMutation();
  const [createJob] = useCreateJobMutation();
  const [action, setAction] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const onFinish = async (values) => {
    dispatch(setQualification(values.qualification));
    const qualification = values.qualification;
    if (action === "save") {
      handleQualification(qualification);
      dispatch(setPhaseDefault());
      enqueueSnackbar("Saved", { variant: "success" });
      navigate("/dashboard/jobs");
    }
    if (action === "next") moveNext();
    if (action === "back") moveBack();
  };

  const handleQualification = async (qualification) => {
    if (qualification) {
      console.log(qualification);
      const qual = qualification.map((el) => {
        if ((el.exp = "zero")) el.exp = 0;
        return { skill: el.skill, exp: Number(el.exp) };
      });
      let res = await createJob({
        basicInfo: jobInfo,
        skills: qual,
        question: [],
      });
    }
  };

  return (
    <>
      <div className="px-3 py-3">
        <Form
          name="dynamic_form_nest_item"
          initialValues={{ qualification: defQualification }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.List name="qualification">
            {(fields, { add, remove }) => (
              <>
                <div className="mb-3">
                  <p className="text-xl font-medium">Required Qualifications</p>
                </div>

                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ marginBottom: 8 }}>
                    <div className="gap-2 flex flex-wrap">
                      <div className="w-72">
                        <h5 className="text-md">Skill</h5>
                        <Form.Item
                          {...restField}
                          name={[name, "skill"]}
                          rules={[
                            { required: true, message: "Missing skill name" },
                          ]}
                        >
                          <input
                            type="text"
                            name="title"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                            placeholder="Skill name"
                          />
                        </Form.Item>
                      </div>

                      <div className="w-72">
                        <h5 className="text-md">Experience</h5>
                        <Form.Item
                          {...restField}
                          name={[name, "exp"]}
                          rules={[
                            { required: true, message: "Missing experience" },
                          ]}
                        >
                          <select
                            id="countries"
                            placeholder="Select years of experience"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                            name="exp"
                          >
                            {/* <option value="zero">Fresh</option> */}
                            <option name="exp" value="1">
                              1+ year
                            </option>
                            <option name="exp" value="2">
                              2+ years
                            </option>
                            <option name="exp" value="3">
                              3+ years
                            </option>
                            <option name="exp" value="4">
                              4+ years
                            </option>
                            <option name="exp" value="5">
                              5+ years
                            </option>
                          </select>
                        </Form.Item>
                      </div>

                      <div className="flex w-72 justify-end md:w-12 md:mt-7">
                        <span
                          className="delete_icon"
                          onClick={() => remove(name)}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}

                <Form.Item>
                  <div className="mt-2 cursor-pointer">
                    <span className="text-blue-600" onClick={() => add()}>
                      <FontAwesomeIcon icon={faPlus} />{" "}
                      <span className="ms-2">Add another Required Skill</span>
                    </span>
                  </div>
                </Form.Item>
              </>
            )}
          </Form.List>

          <div className="BTN_GROUP">
            <div className="gap-2 flex flex-wrap justify-content-end mb-2 pt-3 ">
              <div>
                <button
                  onClick={() => setAction("back")}
                  type="submit"
                  class="py-2 px-3 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                >
                  Back
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  onClick={() => setAction("save")}
                  class="py-2 px-3 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                >
                  Save & Exit
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  onClick={() => setAction("next")}
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2 text-center inline-flex items-center"
                >
                  Next
                  <svg
                    class="w-5 h-5 ml-2 -mr-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Qualification;
