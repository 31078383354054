import React from 'react'
import InputField from '../Generic/InputField'
import { Button, Input , Select  } from 'antd'
import Avatar from '@mui/material/Avatar';






export default function AddConsultant() {

    const { TextArea } = Input;
    const { Option, OptGroup } = Select;


    function handleChange(value) {
        console.log(`selected ${value}`);
      }
    



  return (
    <>
      <div className='px-3'>
        <div className='my-4'><h3>Add Consultant</h3></div>
        <div className='mb-4'><h5>Consultant Details</h5></div>
        <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    
                    <InputField label="First Name" placeholder='First Name' type="text" width={'100%'} height='32px'/>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                  
                    <InputField label="Last Name" placeholder='Last Name' type="text" width={'100%'}  height='32px'/>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    
                    <InputField label="Email" placeholder='Email' type="email" width={'100%'}  height='32px'/>

                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                   
                    <InputField label="Phone Number" placeholder='Phone Number' type="text" width={'100%'}  height='32px'/>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 ps-4">
              <div className="row">
                <div className="col-3">
                  <span>Profile Image</span>
                  <div className="p-2">
            
                  <Avatar
                    alt="Remy Sharp"
                    src=""
                    sx={{ width: 100, height: 100 }}
                    style={{ marginTop: "" }}
                  />

                  </div>
                </div>
                <div className="col-6  pb-5" style={{ paddingTop: "4rem" }}>
                  <Button
                    className=""
                    style={{
                      backgroundColor: "#265BC4",
                      borderRadius: "4px",
                      color: "white",
                      width: "143px",
                      height: "37px",
                    }}
                  >
                    Upload Image
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
          <div className="col-3">
                  <span>Address</span>
                  <TextArea
                    placeholder="Add Consultant Address..."
                    rows={5}
                    className="my-3"
                  />
                </div>
                <div className="col-3 ">
                  <span>Select Gender</span>
                  <div className="my-3">
                    <Select
                      defaultValue="Gender"
                      style={{ width: 200 , height:31 , borderRadius:'3px' }}
                      onChange={handleChange}
                    >
                      <OptGroup label="Gender">
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                      </OptGroup>
                    </Select>
                  </div>
                </div>
          </div>
        <div className=' ms-auto text-end my-5' style={{ width: '50%' }}>
              <Button type="submit" className="px-3" style={{backgroundColor:'#265BC4',color:'white',borderRadius:'4px'}}>
          Save Employee Data
          </Button>
          </div>
    </div>
    </>
  )
}
