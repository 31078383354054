import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../img/delete.svg";
import { ReactComponent as EditIcon } from "../../img/edit.svg";
import { ReactComponent as VisibilityIcon } from "../../img/visibility.svg";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { setCandidateId } from "../../redux/candidateSlice";
import Avatar from "../../updates/components/avatar/Avatar";
import { FaLinkedin } from 'react-icons/fa';

export default function TableComp(props) {
  const [tableData, setTableData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tableData !== props.data) {
      setTableData(props.data);
    }
  });

  function renderImgElement(rowData) {
    return (
      <td className="ps-4 whitespace-nowrap">
        <Avatar 
          className='w-12 h-12'
          textSize='text-2xl'
          profileData={{profile_img: rowData.profile_img, firstname: rowData.fullname}}
        />
      </td>
    );
  }

  function renderDataElements(row) {
    var table_elements = [];
    // console.log(row)
    for (const [key, value] of Object.entries(row)) {
      if (key !== "key") {
        if (key === "profile_img") {
          table_elements.push(renderImgElement(row));
        } else if (key === 'linkedin') {
          table_elements.push(<td className="px-6 py-4">
            <a
              href={value}
              target="_blank"
              type='button'
              className='flex flex-row space-x-2 bg-blue-600 text-white rounded-md shadow-sm px-4 py-2 w-max'
            >
              <FaLinkedin className='w-5 h-5' />
              <span className='text-sm font-medium'>LinkedIn Profile</span>
            </a>
          </td>);     
        } else if (key === 'experience') {
          table_elements.push(
            <td className="px-6 py-4">
              {
                value?.years ? <> {value?.years} {value?.years > 1 ? 'years' : 'year'} </> : <></>
              }
              { 
                value?.months ? <>{value?.months} {value?.months > 1 ? 'months' : 'month'}</> : <></>
              }
            </td>
          );
        } else {
          table_elements.push(<td className="px-6 py-4">{value}</td>);
        }
      }
    }
    return table_elements;
  }

  function renderAction(row) {
    if (props.columns.filter((value) => value.key === "action").length > 0) {
      return (
        <td className="px-6 py-4 text-right">
          {props.columns.map((data) => {
            if (data.key === "action") {
              // console.log(data)
              return (
                <div className="d-flex justify-content-left">
                  {/* <div>
                    <Tooltip title="Detail" placement="top" arrow>
                      <Link
                      <Link
                        to="/dashboard/viewcandidate"
                        style={{ color: "#6F747E" }}
                      >
                        <VisibilityIcon
                          style={{ color: "#6F747E" }}
                          fill="CurrentColor"
                        />
                      </Link>
                    </Tooltip>
                  </div> */}
                  {props.tableName !== "roles" && (
                    <div
                      className="cursor-pointer ps-2"
                      onClick={() => {
                        props.showViewModal(row.key);
                      }}
                    >
                      <Tooltip title="view" placement="top" arrow>
                        <div>
                          <VisibilityIcon
                            style={{ color: "#6F747E" }}
                            fill="CurrentColor"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  )}

                  {props.tableName === "candidate" &&
                    props.permissions.includes("1") && (
                      <div className="ps-2">
                        <Tooltip title="Edit" placement="top" arrow>
                          <Link
                            className="ps-2"
                            to={`/dashboard/editcandidate/${row.key}`}
                            style={{ color: "#6F747E" }}
                            onClick={() => {
                              console.log(row.key);
                              dispatch(setCandidateId(row.key));
                            }}
                          >
                            <EditIcon
                              className="-mt-5"
                              style={{ color: "#6F747E" }}
                              fill="CurrentColor"
                            />
                          </Link>
                        </Tooltip>
                      </div>
                    )}

                  {props.tableName === "roles" &&
                    props.permissions.includes("1") && (
                      <button
                        disabled={
                          row.key === "2" || row.key === "1" ? true : false
                        }
                        className={`cursor-pointer ps-2  ${
                          row.key === "2" || row.key === "1" ? "disableBtn" : ""
                        }`}
                        onClick={() =>
                          props.showUpdateRoleDialog(row.key, row.rolename)
                        }
                      >
                        <Tooltip title="Edit" placement="top" arrow>
                          <EditIcon
                            className=""
                            style={{ color: "#6F747E" }}
                            fill="CurrentColor"
                          />
                        </Tooltip>
                      </button>
                    )}

                  {props.tableName === "roles" &&
                    props.permissions.includes("4") && (
                      <button
                        disabled={
                          row.key === "2" || row.key === "1" ? true : false
                        }
                        className={`cursor-pointer ps-2  ${
                          row.key === "2" || row.key === "1" ? "disableBtn" : ""
                        }`}
                        onClick={() => {
                          props.deleteModelOpen(row.key, row.rolename);
                        }}
                      >
                        <Tooltip title="Delete" placement="top" arrow>
                          <div className="dddd">
                            <DeleteIcon
                              style={{ color: "#6F747E" }}
                              fill="CurrentColor"
                            />
                          </div>
                        </Tooltip>
                      </button>
                    )}

                  {props.tableName === "candidate" &&
                    props.permissions.includes("4") && (
                      <div
                        className="cursor-pointer ps-2"
                        onClick={() => {
                          props.deleteModelOpen(row.key, row.fullname);
                        }}
                      >
                        <Tooltip title="Delete" placement="top" arrow>
                          <div>
                            <DeleteIcon
                              style={{ color: "#6F747E" }}
                              fill="CurrentColor"
                            />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                </div>
              );

              // return data.render(row.key);
            }
          })}
        </td>
      );
    }

    return null;
  }

  function onPageChange() {}

  return (
    // <Table  columns={props.columns} dataSource={props.data} pagination={{ pageSize: 10 }} />
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {props.columns.map((row) => (
              <th
                key={row.key}
                scope="col"
                className="px-6 py-3"
                colSpan={row.colSpan}
              >
                {row.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props?.data !== false &&
            props?.data?.map((row) => (
              <tr
                key={row.key}
                className="bg-gray border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                {renderDataElements(row)}
                {renderAction(row)}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
