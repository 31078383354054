import React, { useEffect, useState } from "react";
import {
  LineChart,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
export default function CHART(props) {
  const month_names = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [data, setChartData] = useState([]);
  const [maxCount, setDomainMax] = useState(0);

  useEffect(() => {
    var chartData = [];
    for (let i = 0; i < month_names.length; i++) {
      chartData.push({
        month: month_names[i],
        jobs: props.monthlyJobs[i],
        candidates: props.monthlyCandidates[i],
      });
    }
    setChartData(chartData);

    if (Math.max(props.monthlyJobs) > Math.max(props.monthlyCandidates)) {
      setDomainMax(Math.max(props.monthlyJobs));
    } else {
      setDomainMax(Math.max(props.monthlyCandidates));
    }
  }, [props.monthlyCandidates, props.monthlyJobs]);

  return (
    <div className="pt-5 pb-5">
      <ResponsiveContainer width="100%" aspect={4.0 / 0.8}>
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis dataKey="month" axisLine={false} tickLine={false} dy={15} />
          <YAxis
            axisLine={false}
            tickLine={false}
            domain={[0, maxCount]}
            dx={-20}
          />
          <CartesianGrid horizontal={false} opacity={0.5} />
          <Tooltip />
          {/* <Legend layout="horizontal" verticalAlign="top" align="center" /> */}
          <Line type="monotone" dataKey="jobs" dot={false} stroke="#0058FF" />
          <Line
            type="monotone"
            dataKey="candidates"
            dot={false}
            stroke="#21D59B"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
