import axios from 'axios';
import Cookies from "js-cookie";

const BASE_URL = "https://www.meerkatsearch.com/api";
// const baseUrl = "https://3.85.232.76/";
// const BASE_URL = "http://127.0.0.1:8000/api";

async function getCandidateData(candidateId) {
  const apiURL = `${BASE_URL}/candidate/details/${candidateId}`;
  const options = {};

  const response = await axios.get(apiURL, options);
  return response.data;
}

async function getDegreeTypes() {
  const apiURL = `${BASE_URL}/candidate/degree-types`;
  const options = {};

  const response = await axios.get(apiURL, options);
  return response.data;  
}

async function getCandidateDataV2(candidateId) {
  const apiURL = `${BASE_URL}/candidate/details/v2/${candidateId}`;
  const options = {};

  const response = await axios.get(apiURL, options);
  return response.data;
}

export default {
  getDegreeTypes,
  getCandidateData,
  getCandidateDataV2
};
