import PropTypes from 'prop-types';
import React, { useState, Fragment, useEffect } from "react";
import { Transition, Listbox } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

const SingleSelectListBox = ({
  filter_id,
  className,
  filter_name,
  filter_data, 
  filter_label,
  btn_className,
  opt_className,
  updateFilterData,
  ...divProps
}) => {
  const [selectedOption, setSelectedOption] = useState({});

  function updateData(data) {
    // updateFilterData(data);
    setSelectedOption(data);
  }

  useEffect(() => {
    if (filter_data.length > 0) {
      setSelectedOption(filter_data[0])
    }
  }, [filter_data])

  return (
    <div {...divProps} className={`${className} w-max z-10`}>
      <Listbox
        value={selectedOption}
        onChange={updateData}
        id={`listbox-${filter_id}`}
      >
        <div className="relative">
          <Listbox.Button
            className={`${btn_className}`}
          >
            <span className="block truncate capitalize">
              {selectedOption?.text}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className={`${opt_className}`}>
              {filter_data &&
                filter_data.map((element, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                        active ? "bg-blue-100 text-blue-700" : "text-gray-700"
                      }`
                    }
                    // value={JSON.stringify({
                    //   id: element.id,
                    //   text: element.text,
                    // })}
                    value={element}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate capitalize ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {element.text}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export default SingleSelectListBox;

SingleSelectListBox.defaultProps = {};

SingleSelectListBox.propTypes = {
  filter_id: PropTypes.string,
  className: PropTypes.string,
  filter_data: PropTypes.array,
  filter_name: PropTypes.string,
  filter_label: PropTypes.string,
  btn_className: PropTypes.string,
  opt_className: PropTypes.string,
  updateFilterData: PropTypes.func
};
