import React from "react";
// import { PeopleTwoTone } from '@material-ui/icons'
import "./dashboard.css";
import { ReactComponent as MailIcon } from "../../img/mail.svg";

const Consultant = (props) => {
  return (
    <div class="flex items-center space-x-4">
      <div class="flex-shrink-0">
        {
          props.data.profile_img ?         
          <img
            class="w-8 h-8 rounded-full"
            src="https://flowbite.com/docs/images/people/profile-picture-2.jpg"
            alt="Bonnie image"
          />
        : 
        <div
          id="avatar"
          type="button"
          className="flex items-center justify-center w-10 h-10 text-base font-medium text-white bg-blue-800 rounded-full cursor-pointer"
        >
          {props.data.name !== undefined
            ? props.data.name.toUpperCase().charAt(0)
            : ""}
        </div>
        }

      </div>
      <div class="flex-1 min-w-0">
        <span class="text-sm font-medium text-gray-900 truncate dark:text-white">
          {props.data.name}
        </span>
        <p class="text-sm text-gray-500 truncate dark:text-gray-400">
          {props.data.email}
        </p>
      </div>
      <div class="inline-flex items-center text-base text-gray-900 dark:text-white">
        <MailIcon className="cursor-pointer" fill="CurrentColor" />
      </div>
    </div>
  );
};

export default Consultant;
