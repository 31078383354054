import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import moment from "moment";

export default function WorkExpForm(props) {
  // const [workExpForm, setWorkExpForm] = useState([]);
  const [workExpData, setWorkExpData] = useState({
    role_title: props.candidateData.position,
    company: props.candidateData.company_name,
    currently_working: props.candidateData.currently_working,
    start_date: props.candidateData.join_date,
    end_date: props.candidateData.end_date,
    description: props.candidateData.description,
  
  });

  useEffect(() => {
    setTimeout(() => {
      // console.log(Object.values(workExpData).some( item => item === undefined))
      if(!Object.values(workExpData).some( item => item === undefined)){
        props.updateWorkExpData(props.index, workExpData);
      }
    }, 0);
  }, [workExpData]);

  function updateFormData(field, data) {
    setWorkExpData(() => {
      let objData = { ...workExpData };
      objData[field] = data;
      return objData;
    });
  }

  function setCurrentlyWorkingStatus() {
    setWorkExpData(() => {
      let objData = { ...workExpData };
      objData.currently_working = true;
      objData.end_date = null;
      return objData;
    });
    // updateFormData("currently_working", true);
    // updateFormData("endDate", null);
  }

  const setEndDate = (date_string) => {
    setWorkExpData(() => {
      let objData = { ...workExpData };
      objData.currently_working = false;
      objData.end_date = date_string;
      return objData;
    });
    // updateFormData("currently_working", false);
    // updateFormData("endDate", date_string);
  };

  return (
    <>
      <div key={props.index} className="mt-3">
        <div className="row col-6">
          <div className="d-flex space-x-6">
            <div class="mb-6 col-6">
              <label
                for={`role_title`}
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Role Title
              </label>
              <input
                type="text"
                value={workExpData.role_title}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Software Engineer, etc..."
                onChange={(event) =>
                  updateFormData("role_title", event.target.value)
                }
              />
            </div>
            <div class="mb-6 col-6">
              <label
                for={`company`}
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Company Name
              </label>
              <input
                type="text"
                value={workExpData.company}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Company Name"
                onChange={(event) =>
                  updateFormData("company", event.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="row col-6">
          <div className="d-flex space-x-6">
            <div class="mb-6 col-6">
              <label
                for={`start_date`}
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Start Date
              </label>
              <DatePicker
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                name="start_date"
                defaultValue={moment(workExpData.start_date)}
                onChange={(_, dateString) =>
                  updateFormData("start_date", dateString)
                }
              />

              <div class="flex items-center mt-2">
                <input
                  type="checkbox"
                  value=""
                  defaultChecked={workExpData.currently_working}
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  onChange={(event) => {
                    if (event.target.checked === true) {
                      document.getElementById(`endDateDiv-${props.index}`).style.display =
                        "none";
                      setCurrentlyWorkingStatus();
                    } else {
                      document.getElementById(`endDateDiv-${props.index}`).style.display =
                        "block";
                    }
                  }}
                />

                <label
                  id={`checkbox`}
                  class="ml-2 text-xs font-normal text-gray-600 dark:text-gray-300"
                >
                  Currently Working
                </label>
              </div>
            </div>
            <div class="mb-6 col-6" id={`endDateDiv-${props.index}`}>
              <label
                for={`endDate`}
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                End Date
              </label>
              <DatePicker
                // disabled={ document.getElementById(`checkbox`).checked }
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                name="end_date"
                defaultValue={ workExpData.end_date === null
                  ? null
                  : moment(workExpData.end_date)}
                onChange={(_, dateString) => setEndDate(dateString)}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div class="mb-6">
              <label
                for={`description`}
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Description
              </label>
              <textarea
                rows="4"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Write down your key roles and responsibilities..."
                onChange={(event) =>
                  updateFormData("description", event.target.value)
                }
                value={workExpData.description}
                name={`description`}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
