import { createSlice } from "@reduxjs/toolkit";

//*** Declare your state variable here
const initialState = {
  phase: 1,
  myJob: false,
  jobId: null,
  jobInfo: {
    title: null,
    description: null,
    resp: null,
    salary_min: null,
    salary_max: null,
    typeId: null,
  },
  qualification: [],
  jobQuestion: [],
};

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    next: (state) => {
      state.phase += 1;
    },
    back: (state) => {
      state.phase -= 1;
    },
    setPhaseDefault: (state) => {
      state.phase = 1;
      state.jobId = null;
      state.jobInfo = {
        title: null,
        description: null,
        resp: null,
        salary_min: null,
        salary_max: null,
        typeId: null,
      };
      state.qualification = [];
      state.jobQuestion = [];
    },
    setJobInfo: (state, action) => {
      state.jobInfo = action.payload;
    },
    setQualification: (state, action) => {
      state.qualification = action.payload;
    },
    setJobQuestion: (state, action) => {
      state.jobQuestion = action.payload;
    },
    setJobId: (state, action) => {
      state.jobId = action.payload;
      console.log(state.jobId);
    },
    setMyJob: (state, action) => {
      state.myJob = action.payload;
    },

    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  next,
  back,
  setJobInfo,
  setQualification,
  setJobQuestion,
  setJobId,
  setPhaseDefault,
  setMyJob,
} = jobSlice.actions;

export default jobSlice.reducer;
