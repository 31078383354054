import React, { useEffect } from "react";
import { Radio, Input, Button } from "antd";
import { Timeline } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./newJob.css";
import BasicInformation from "./BasicInformation";
import Questions from "./Questions";
import Qualification from "./Qualification";
import Review from "./Review";
import { useSelector, useDispatch } from "react-redux";
import { next, back } from "../../redux/jobSlice";
import AppContext from "../../context/appState/AppContext";
import { useGetJobForUpdateQuery } from "../../services/nodeAPI";
import {
  setJobInfo,
  setJobQuestion,
  setQualification,
} from "../../redux/jobSlice";

export default function EditJob() {
  const { TextArea } = Input;
  const { Search } = Input;
  const phase = useSelector((state) => state.job.phase);
  const dispatch = useDispatch();

  const { jobId } = useSelector((state) => state.job);

  console.log(jobId);

  const {
    data: jobData,
    isLoading,
    isSuccess,
  } = useGetJobForUpdateQuery(jobId);

  console.log(jobData);

  const moveNext = () => dispatch(next());
  const moveBack = () => dispatch(back());

  useEffect(() => {
    if (jobData !== undefined) {
      const splitted_salary = jobData?.job_details[0]?.salary?.split("-");
      const jobDetails = jobData?.job_details[0];
      var skills = [];
      if (jobDetails?.skills !== null) {
        skills = JSON.parse(
          jobDetails?.skills.replace("{", "[").replace("}", "]")
        )
          .map((item) => item.replace("(", "").replace(")", ""))
          .map((item) =>
            item.split(",").reduce((exp, skill) => ({ exp: exp, skill: skill }))
          );
      }
      // console.log(skills);
      dispatch(
        setJobInfo({
          title: jobDetails?.title,
          description: jobDetails?.description,
          resp: jobDetails?.responsibilites,
          salary_min: splitted_salary[0],
          salary_max: splitted_salary[1],
          typeId: jobDetails?.job_type_id,
        })
      );

      dispatch(setQualification(skills));
      dispatch(
        setJobQuestion(
          jobDetails?.questions.map((item) => ({ job_question: item }))
        )
      );
    }
  }, [isLoading]);

  return (
    <>
      <div className="gap-3 flex flex-wrap w-full lg:justify-between">
        <div className="hidden lg:block lg:w-7/12 lg:mx-auto py-4 px-2">
          <div className="border-1 border-gray-200 rounded-lg shadow-md">
            {phase === 1 && <BasicInformation moveNext={moveNext} />}
            {phase === 2 && (
              <Qualification moveNext={moveNext} moveBack={moveBack} />
            )}
            {phase === 3 && (
              <Questions moveNext={moveNext} moveBack={moveBack} />
            )}
            {phase === 4 && <Review moveBack={moveBack} />}
          </div>
        </div>

        <div className="w-full lg:w-4/12 2xl:w-3/12 lg:pt-20 h-100 flex justify-center">
          <div className="sm:w-8/12 md:w-6/12 lg:w-full px-5 pt-4 bg-white rounded-lg shadow-md border-1 border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <p className="text-center text-2xl font-normal">
              Job Creation Progress
            </p>
            <div className="py-4">
              <Timeline>
                <Timeline.Item
                  className="my-4"
                  dot={
                    phase <= 1 ? (
                      <div className="timeline_icon_div1">
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="timeline_icons timeline_icon_active"
                        />
                        <span className="active_number text-lg">1</span>
                      </div>
                    ) : (
                      <FontAwesomeIcon className="done_tick" icon={faCheck} />
                    )
                  }
                >
                  <span className="ps-3 text-lg font-medium">
                    Basic Information
                  </span>
                </Timeline.Item>

                <Timeline.Item
                  className="my-4"
                  dot={
                    phase <= 2 ? (
                      <div className="timeline_icon_div2">
                        {" "}
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="timeline_icons timeline_icon_active"
                        />{" "}
                        <span className="active_number text-lg">2</span>{" "}
                      </div>
                    ) : (
                      <FontAwesomeIcon className="done_tick" icon={faCheck} />
                    )
                  }
                >
                  <span className="ps-3 text-lg font-medium">
                    Qualification
                  </span>
                </Timeline.Item>

                <Timeline.Item
                  className="my-4"
                  dot={
                    phase <= 3 ? (
                      <div className="timeline_icon_div2">
                        {" "}
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="timeline_icons timeline_icon_active"
                        />{" "}
                        <span className="active_number text-lg">3</span>{" "}
                      </div>
                    ) : (
                      <FontAwesomeIcon className="done_tick" icon={faCheck} />
                    )
                  }
                >
                  <span className="ps-3 text-lg font-medium">Questions</span>
                </Timeline.Item>

                <Timeline.Item
                  className=""
                  dot={
                    phase <= 4 ? (
                      <div className="timeline_icon_div2">
                        {" "}
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="timeline_icons timeline_icon_active"
                        />{" "}
                        <span className="active_number text-lg">4</span>{" "}
                      </div>
                    ) : (
                      <FontAwesomeIcon className="done_tick" icon={faCheck} />
                    )
                  }
                >
                  <span className="ps-3 text-lg font-medium">Review</span>
                </Timeline.Item>
              </Timeline>
            </div>
          </div>
        </div>

        <div className="block lg:hidden py-4 px-2 w-100">
          <div className="border-1 border-gray-200 rounded-lg shadow-md">
            {phase === 1 && <BasicInformation moveNext={moveNext} />}
            {phase === 2 && (
              <Qualification moveNext={moveNext} moveBack={moveBack} />
            )}
            {phase === 3 && (
              <Questions moveNext={moveNext} moveBack={moveBack} />
            )}
            {phase === 4 && <Review moveBack={moveBack} />}
          </div>
        </div>
      </div>
    </>
  );
}
