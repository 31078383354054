import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Radio, Input, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { setJobInfo } from "../../redux/jobSlice";
import { useCreateJobMutation } from "../../services/nodeAPI";
import { useSnackbar } from "notistack";

const BasicInformation = ({ moveNext }) => {
  const { TextArea } = Input;
  const { Search } = Input;
  const [createJob] = useCreateJobMutation();
  const jobInfo = useSelector((state) => state.job.jobInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnchange = (event) => {
    dispatch(
      setJobInfo({ ...jobInfo, [event.target.name]: event.target.value })
    );
  };

  const handleSaveNExit = async () => {
    let res = await createJob({ basicInfo: jobInfo, skills: [], question: [] });
    enqueueSnackbar("Saved", { variant: "success" });
    navigate("/dashboard/jobs");
  };

  return (
    <>
      <form className="px-3 py-3 ">
        <div className="">
          <div className="w-full">
            <div className="mb-3">
              <label className="text-xl font-medium">Job Title</label>
            </div>
            <input
              type="text"
              name="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
              placeholder="Enter Job Title"
              defaultValue={jobInfo.title}
              onChange={handleOnchange}
            />
          </div>
          <div className="w-full">
            <div className="my-3">
              <label className="text-xl font-medium">Job Description</label>
            </div>

            <Radio.Group
              defaultValue={jobInfo.typeId}
              name="typeId"
              onChange={handleOnchange}
            >
              <Radio value="1" name="typeId">
                Full Time
              </Radio>
              <Radio value="2" name="typeId">
                Part Time
              </Radio>
              <Radio value="3" name="typeId">
                Contract
              </Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="flex flex-wrap my-3">
          <div className="w-100">
            <textarea
              rows={10}
              placeholder="Add Job Description"
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              defaultValue={jobInfo.description}
              name="description"
              onChange={handleOnchange}
            />
          </div>
        </div>
        <div className="flex flex-wrap my-3">
          <div className="w-100">
            <div className="mb-3">
              <label className="text-xl font-medium">Responsibilities</label>
            </div>

            <textarea
              rows={10}
              placeholder="Add Job Responsibilities..."
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              name="resp"
              onChange={handleOnchange}
              defaultValue={jobInfo.resp}
            />
          </div>
        </div>
        <div className="my-4">
          <div className="mb-3">
            <label className="text-xl font-medium">Add Salary</label>
            <br />
            <span className="text- font-medium text-gray-400">
              Salary in USD per anum
            </span>
          </div>
          <div className="gap-3 flex flex-wrap">
            <div class="relative w-64">
              <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-currency-dollar"
                  viewBox="0 0 16 16"
                >
                  <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                </svg>
              </div>
              <input
                type="text"
                id="input-group-1"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Minimum Salary"
                name="salary_min"
                onChange={handleOnchange}
                defaultValue={jobInfo.salary_min}
              />
            </div>
            <div class="relative w-64">
              <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-currency-dollar"
                  viewBox="0 0 16 16"
                >
                  <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                </svg>
              </div>
              <input
                type="text"
                id="input-group-1"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Maximum Salary"
                name="salary_max"
                onChange={handleOnchange}
                defaultValue={jobInfo.salary_max}
              />
            </div>
          </div>
        </div>

        <div className="BTN_GROUP pt-3">
          <div className="gap-2 flex flex-wrap justify-content-end my-2">
            <div>
              <Link to="/dashboard/jobs">
                <button
                  type="button"
                  class="py-2 px-3 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                >
                  Back to Jobs
                </button>
              </Link>
            </div>
            <div>
              <button
                type="button"
                onClick={handleSaveNExit}
                class="py-2 px-3 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
              >
                Save & Exit
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={moveNext}
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2 text-center inline-flex items-center"
              >
                Next
                <svg
                  class="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default BasicInformation;
