import "../css/Navbar.css";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { Fragment, useEffect, useContext, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useGetUserDetailsMutation } from "../services/nodeAPI";
import AppContext from "../context/appState/AppContext";
import { useNavigate } from "react-router-dom";

export const Navbar = (props) => {
  const [userProfileData, setUserProfileData] = useState({});
  const [getUserDetails] = useGetUserDetailsMutation();
  const navigate = useNavigate();
  const { Cookies } = useContext(AppContext);

  useEffect(() => {
    getUserDetails().then((response) => {
      if (response.data.success) {
        setUserProfileData(response.data.user_data);
      }
    });
  }, []);

  function logoutUser() {
    Cookies.remove("jwt");
    navigate("/");
  }

  return (
    <nav className="bg-white border-1 border-gray-100 px-2 sm:px-4 py-2.5 dark:bg-gray-800 w-full fixed top-0 left-0 right-0 z-30">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <a
          href="/dashboard/home"
          className="flex items-center text-blue-600 hover:text-blue-600"
        >
          <img
            src={require("./../img/Group 2.png")}
            className="w-28"
            alt="Meerkat"
          />
          {/* <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
            Meerkat
          </span> */}
        </a>
        <div className="flex items-center md:order-2">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="">
                {userProfileData.profile_img === null ? (
                  <div
                    id="avatar"
                    type="button"
                    data-dropdown-toggle="userDropdown"
                    data-dropdown-placement="bottom-start"
                    className="flex items-center justify-center w-10 h-10 text-base font-medium text-white bg-blue-800 rounded-full cursor-pointer"
                  >
                    {userProfileData.firstname !== undefined
                      ? userProfileData.firstname.charAt(0)
                      : ""}
                  </div>
                ) : (
                  <img
                    id="avatar"
                    type="button"
                    data-dropdown-toggle="userDropdown"
                    data-dropdown-placement="bottom-start"
                    className="w-10 h-10 rounded-full cursor-pointer"
                    src={
                      "https://www.meerkatsearch.com" +
                      userProfileData.profile_img
                    }
                    alt={userProfileData.firstname}
                  />
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="focus:outline-none absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white border shadow-md ring-1 ring-black ring-opacity-5">
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    <div className="px-3 py-3 text-sm text-gray-900 dark:text-white">
                      <div>
                        {userProfileData.firstname +
                          " " +
                          userProfileData.lastname}
                      </div>
                      <div className="font-medium truncate">
                        {userProfileData.email}
                      </div>
                    </div>
                  </Menu.Item>
                </div>
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        type="button"
                        className={`${
                          active ? "bg-blue-600 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        to="/dashboard/settings"
                      >
                        <span className="me-2">
                          <SettingsIcon />
                        </span>
                        Settings
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={`${
                          active ? "bg-blue-600 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={logoutUser}
                      >
                        <span className="me-2">
                          <LogoutIcon />
                        </span>
                        Logout
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <button
            type="button"
            className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            onClick={props.toggleMobileMenu}
          >
            <svg
              className={`w-6 h-6 ${props.mobileMenu == true ? "hidden" : ""}`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className={`w-6 h-6 ${props.mobileMenu == false ? "hidden" : ""}`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </nav>

    // <nav className="navbar navbar-expand nav_bar fixed-top">
    //   <div className="container-fluid">
    //     <Link className="navbar-brand nav_bar_name" to="/dashboard">
    //       Meerkat
    //     </Link>
    //     <div className id="navbarScroll">
    //       <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
    //         <li className="nav-item notify_item">
    //           <a className="notify position-relative">
    //             <i className="fas fa-bell" />
    //             <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill num_badge">
    //               99+
    //             </span>
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <Menu as="div" className="relative inline-block text-left">
    //             <div>
    //               <Menu.Button className="">
    //                 {userProfileData.profile_img === null ? (
    //                   <div
    //                     id="avatar"
    //                     type="button"
    //                     data-dropdown-toggle="userDropdown"
    //                     data-dropdown-placement="bottom-start"
    //                     className="flex items-center justify-center w-10 h-10 text-base font-medium text-white bg-blue-800 rounded-full cursor-pointer"
    //                   >
    //                     {userProfileData.fullname !== undefined? userProfileData.fullname.charAt(0) : ""}
    //                   </div>
    //                 ) : (
    //                   <img
    //                     id="avatar"
    //                     type="button"
    //                     data-dropdown-toggle="userDropdown"
    //                     data-dropdown-placement="bottom-start"
    //                     className="w-10 h-10 rounded-full cursor-pointer"
    //                     src={
    //                       "http://127.0.0.1:8000" + userProfileData.profile_img
    //                     }
    //                     alt={userProfileData.firstname}
    //                   />
    //                 )}
    //               </Menu.Button>
    //             </div>
    //             <Transition
    //               as={Fragment}
    //               enter="transition ease-out duration-100"
    //               enterFrom="transform opacity-0 scale-95"
    //               enterTo="transform opacity-100 scale-100"
    //               leave="transition ease-in duration-75"
    //               leaveFrom="transform opacity-100 scale-100"
    //               leaveTo="transform opacity-0 scale-95"
    //             >
    //               <Menu.Items className="focus:outline-none absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white border shadow-md ring-1 ring-black ring-opacity-5">
    //                 <div className="px-1 py-1 ">
    //                   <Menu.Item>
    //                     <div className="px-3 py-3 text-sm text-gray-900 dark:text-white">
    //                       <div>
    //                         {userProfileData.firstname +
    //                           " " +
    //                           userProfileData.lastname}
    //                       </div>
    //                       <div className="font-medium truncate">
    //                         {userProfileData.email}
    //                       </div>
    //                     </div>
    //                   </Menu.Item>
    //                 </div>
    //                 <div className="px-1 py-1 ">
    //                   <Menu.Item>
    //                     {({ active }) => (
    //                       <Link
    //                         type="button"
    //                         className={`${
    //                           active
    //                             ? "bg-blue-600 text-white"
    //                             : "text-gray-900"
    //                         } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
    //                         to="/dashboard/settings"
    //                       >
    //                         <span className="me-2">
    //                           <SettingsIcon />
    //                         </span>
    //                         Settings
    //                       </Link>
    //                     )}
    //                   </Menu.Item>
    //                 </div>
    //                 <div className="px-1 py-1 ">
    //                   <Menu.Item>
    //                     {({ active }) => (
    //                       <Link
    //                         type="button"
    //                         className={`${
    //                           active
    //                             ? "bg-blue-600 text-white"
    //                             : "text-gray-900"
    //                         } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
    //                         to="/login"
    //                       >
    //                         <span className="me-2">
    //                           <LogoutIcon />
    //                         </span>
    //                         Logout
    //                       </Link>
    //                     )}
    //                   </Menu.Item>
    //                 </div>
    //               </Menu.Items>
    //             </Transition>
    //           </Menu>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </nav>
  );
};
