import React from "react";
// import { PeopleTwoTone } from '@material-ui/icons'
import "./dashboard.css";

export default function DashboardCard(props) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <>
      <div className="block bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100">
        <div className="px-3 py-3">
          <div className="flex justify-between">
            <div className="">
              <p className="text-gray-500 text-sm font-normal">{props.name}</p>
              <h4 className="pt-3 text-gray-800 text-2xl font-medium">
                {numberWithCommas(props.value)}
              </h4>
            </div>
            <div className="mt-4">
              <div className="circle_icon">
                {<props.icon className="" fill="CurrentColor" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
