import { createSlice } from '@reduxjs/toolkit'

//*** Declare your state variable here
const initialState = {
  candidateId: null,
}

export const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    setCandidateId: (state,action) => {
        state.candidateId = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCandidateId } = candidateSlice.actions

export default candidateSlice.reducer