import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

// baseUrl: "https://www.meerkatsearch.com/"

const baseUrl = "https://www.meerkatsearch.com";
// const baseUrl = "https://3.85.232.76/";
// const baseUrl = "http://127.0.0.1:8000";

export const nodeAPI = createApi({
  reducerPath: "nodeAPI",
  baseQuery: fetchBaseQuery({ baseUrl }),

  // Entities of API
  tagTypes: [
    "Roles",
    "Candidate",
    "User",
    "Job",
    "Country",
    "Consultant",
    "City",
    "Employee",
  ],

  endpoints: (builder) => ({
    //Optimize:  ************************** Authentication ******************************

    //********** Login query
    login: builder.mutation({
      query: (body) => ({
        url: "/auth/login",
        method: "POST",
        body,
      }),
      // invalidatesTags: [ 'User' ],
    }),

    //********** Sign up query
    signup: builder.mutation({
      query: (body) => ({
        url: "/auth/register",
        method: "POST",
        body,
      }),
      // invalidatesTags: [ 'User' ],
    }),

    //********** Send Reset Password Link to Email
    passwordResetEmail: builder.mutation({
      query: (body) => ({
        url: "/auth/get-password-reset-link",
        method: "POST",
        body,
      }),
      // invalidatesTags: [ 'User' ],
    }),

    //********** Send Reset Password Link to Email
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "/auth//password-reset",
        method: "POST",
        body,
      }),
      // invalidatesTags: [ 'User' ],
    }),

    //Optimize:  ************************** Roles and permission ******************************
    //******** Get All roles query
    getRoles: builder.query({
      query: (data) => ({
        url: `/role/all-roles/${data.itemLimit}/${data.pageNumber}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: (result, error, data) =>
        result
          ? [
              ...result.roles.map(({ id }) => ({ type: "Roles", id })),
              { type: "Roles", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Roles", id: "PARTIAL-LIST" }],
      // providesTags: ["Role"],
    }),

    // -----------------CANDIDATES------------------------
    addCandidate: builder.mutation({
      query: (body) => ({
        url: "/candidate/create-candidate",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
    }),
    updateCandidate: builder.mutation({
      query: (body) => ({
        url: "/candidate/update-candidate",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
    }),
    getAllCountries: builder.mutation({
      query: () => ({
        url: "/utils/countries",
        method: "POST",
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      // invalidatesTags: [ "City" ],
    }),

    getAllCities: builder.mutation({
      query: (id) => ({
        url: "/utils/fetch-cities",
        method: "POST",
        body: {
          countryId: id,
        },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      // providesTags:['City']
    }),

    deleteCandidate: builder.mutation({
      query: (id) => ({
        url: "/candidate/delete-candidate",
        method: "POST",
        body: {
          id,
        },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["Candidate"],
    }),
    getAllCandidates: builder.mutation({
      query: (body) => ({
        url: "/candidate/find-candidate-by-name",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Candidate"],
    }),
    getCandidateByID: builder.query({
      query: (id) => ({
        url: `/candidate/candidate-details/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      // providesTags: [ 'Candidate' ],
    }),

    getCountryByID: builder.mutation({
      query: (id) => ({
        url: `/utils/country`,
        method: "POST",
        body: {
          id,
        },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      // providesTags: [ 'Candidate' ],
    }),
    getCityByID: builder.mutation({
      query: (id) => ({
        url: `/utils/city`,
        method: "POST",
        body: { id },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      // providesTags: [ 'Candidate' ],
    }),
    //******** Get Permission and resources query
    getPermissionResources: builder.query({
      query: () => ({
        url: "/role/permissions-resources",
        method: "GET",
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Roles"],
    }),

    //******** Create new role query
    addRole: builder.mutation({
      query: (body) => ({
        url: "/role/new-role",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["Roles", "countRoles"],
    }),

    deleteRole: builder.mutation({
      query: (body) => ({
        url: "/role/delete-role",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["Roles", "countRoles"],
    }),

    // ********************* Consultants *************************
    //********* GET all consultants
    getConsultants: builder.mutation({
      query: (fullname) => ({
        url: "/consultant/find-consultant-by-name",
        method: "POST",
        body: {
          fullname,
        },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["Consultant"],
    }),
    updateRole: builder.mutation({
      query: (body) => ({
        url: "/role/update-role",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["Roles", "countRoles"],
    }),

    //Optimize:  ************************** Employeee ******************************
    //******** Create emplyee query
    addEmployee: builder.mutation({
      query: (body) => ({
        url: "/employee/create-employee",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["Employee"],
    }),

    //********* GET all employee
    getEmployees: builder.mutation({
      query: (body) => ({
        url: "/employee/find-employee-by-name",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["Employee"],
    }),

    //********* GET employee based on id
    getEmployeeWithID: builder.query({
      query: (id) => ({
        url: `/employee/employee-details/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
    }),
    //********* Update employee
    updateEmployee: builder.mutation({
      query: (body) => ({
        url: `/employee/update-employee`,
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["Employee"],
    }),

    //************************************** Job  ***********************************

    //************ Get job Detail - Get Query
    getAllJob: builder.mutation({
      query: (body) => ({
        url: "/job/all-jobs",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["Job"],
    }),

    getJob: builder.query({
      query: (id) => ({
        url: `/job/job-details/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Job"],
    }),
    getJobForUpdate: builder.query({
      query: (id) => ({
        url: `/job/update-job-details/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Job"],
    }),
    getMyJob: builder.mutation({
      query: (body) => ({
        url: "/job/my-jobs",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["Job"],
    }),

    //************ Create Job - POST query
    createJob: builder.mutation({
      query: (body) => ({
        url: "/job/create-job",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Job"],
    }),
    updateJob: builder.mutation({
      query: (body) => ({
        url: "/job/update-job",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Job"],
    }),
    getMyJob: builder.mutation({
      query: (body) => ({
        url: "/job/my-jobs",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Job"],
    }),

    //Publish a job
    publishJob: builder.mutation({
      query: (body) => ({
        url: "/job/publish-job",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
        invalidateTags: ["Job"],
      }),
    }),
    //************ Add Job Skill - POST query
    addJobSkill: builder.mutation({
      query: (body) => ({
        url: "/job/add-job-skills",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
        invalidateTags: ["Job"],
      }),
    }),
    //************ Add Job Question - POST query
    addJobQuestion: builder.mutation({
      headers: {
        authorization: `Bearer ${Cookies.get("jwt")}`,
      },
    }),
    deleteJob: builder.mutation({
      query: (id) => ({
        url: "/job/delete-job",
        method: "POST",
        body: {
          id,
        },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["Job"],
    }),
    //
    countRoles: builder.query({
      query: () => ({
        url: `/role/count-roles`,
        method: "GET",
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["countRoles"],
    }),

    //////
    getAllRoleNames: builder.query({
      query: (data) => ({
        url: `/role/role-names`,
        method: "GET",
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["RoleNames"],
    }),
    /////
    deleteEmployee: builder.mutation({
      query: (id) => ({
        url: "/employee/delete-employee",
        method: "POST",
        body: {
          id,
        },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["Employee"],
    }),
    ///////////
    FindRolePermissions: builder.mutation({
      query: (roleId) => ({
        url: `/role/fetch-role-permissions/${roleId}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
    }),

    //////////////////
    UpdateUserImage: builder.mutation({
      query: (body) => ({
        url: "/profile/update-profile-img",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      invalidatesTags: ["UserProfileImage"],
    }),

    ///////////////////
    //    DropDown search API for Location and SKill in candidates smart search
    ///////////////////
    SearchLocation: builder.mutation({
      query: (location) => ({
        url: "/utils/search-location",
        method: "POST",
        body: {
          location,
        },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Location Search"],
    }),
    SearchSkill: builder.mutation({
      query: (skill) => ({
        url: "/utils/search-skill",
        method: "POST",
        body: {
          skill,
        },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Skill Search"],
    }),

    // /////////////////////////////
    //      SMART SEARCH FILTERS
    // /////////////////////////////
    SearchByFilters: builder.mutation({
      query: (filters) => ({
        url: "/candidate/find-candidate-by-filters",
        method: "POST",
        body: {
          filters,
        },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Candidate Smart Filter Search"],
    }),

    // ////////////////////////////////
    //        USER POFILE SETTINGS
    // ////////////////////////////////
    getUserDetails: builder.mutation({
      query: () => ({
        url: "/profile/user-info",
        method: "POST",
        body: {},
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["User Details"],
    }),
    // update-user-info
    updateUserDetails: builder.mutation({
      query: (userData) => ({
        url: "/profile/update-user-info",
        method: "POST",
        body: { ...userData },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Update User Details"],
    }),

    updateUserProfileImg: builder.mutation({
      query: (body) => ({
        url: "/profile/update-profile-img",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Update User Profile Pic"],
    }),
    getCompanyDetails: builder.mutation({
      query: () => ({
        url: "/profile/get-company-details",
        method: "POST",
        body: {},
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Update User Profile Pic"],
    }),

    updateCompanyPic: builder.mutation({
      query: (body) => ({
        url: "/profile/update-company-logo",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Update company logo"],
    }),

    // update-user-password
    updateUserPassword: builder.mutation({
      query: (userData) => ({
        url: "/profile/update-password",
        method: "POST",
        body: { ...userData },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Update User Password"],
    }),
    // update Company Details
    updateUserCompanyDetails: builder.mutation({
      query: (companyData) => ({
        url: "/profile/update-company-details",
        method: "POST",
        body: { ...companyData },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Update User Password"],
    }),
    // CARD STATS
    cardStats: builder.mutation({
      query: (interval) => ({
        url: "/stats/card-stats",
        method: "POST",
        body: { interval },
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Card Stats"],
    }),
    // MONTHLY CANDIDATES
    monthlyCandidates: builder.mutation({
      query: () => ({
        url: "/stats/get-monthly-candidates",
        method: "POST",
        body: {},
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Monthly Candidates Stats"],
    }),
    // MONTHLY JOBS
    monthlyJobs: builder.mutation({
      query: () => ({
        url: "/stats/get-monthly-jobs",
        method: "POST",
        body: {},
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["Monthly Jobs Stats"],
    }),
    // NEW CONSULTANTS
    newConsultants: builder.mutation({
      query: () => ({
        url: "/stats/get-new-consultants",
        method: "POST",
        body: {},
        headers: {
          authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      }),
      providesTags: ["New Consultants"],
    }),
  }),
});

export const {
  useGetAllJobMutation,
  useGetMyJobMutation,
  useSignupMutation,
  useLoginMutation,
  useGetRolesQuery,
  useCreateJobMutation,
  useAddJobSkillMutation,
  useAddJobQuestionMutation,
  useGetJobQuery,
  usePublishJobMutation,
  useAddCandidateMutation,
  useGetAllCountriesMutation,
  useGetAllCitiesMutation,
  useDeleteCandidateMutation,
  useGetAllCandidatesMutation,
  useGetCandidateByIDQuery,
  useGetCountryByIDMutation,
  useGetCityByIDMutation,
  useGetPermissionResourcesQuery,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useAddEmployeeMutation,
  useGetEmployeesMutation,
  useGetEmployeeWithIDQuery,
  useUpdateEmployeeMutation,
  useCountRolesQuery,
  useGetAllRoleNamesQuery,
  useDeleteEmployeeMutation,
  useFindRolePermissionsMutation,
  useUpdateCompanyPicMutation,
  // useUpdateUserImageMutation,
  useSearchLocationMutation,
  useSearchSkillMutation,
  useSearchByFiltersMutation,
  useGetUserDetailsMutation,
  useUpdateUserDetailsMutation,
  useUpdateUserPasswordMutation,
  useUpdateUserCompanyDetailsMutation,
  useUpdateCandidateMutation,
  useGetConsultantsMutation,
  useDeleteRoleMutation,
  useUpdateUserProfileImgMutation,
  useCardStatsMutation,
  useMonthlyCandidatesMutation,
  useMonthlyJobsMutation,
  useNewConsultantsMutation,
  useDeleteJobMutation,
  useGetJobForUpdateQuery,
  useUpdateJobMutation,
  usePasswordResetEmailMutation,
  useResetPasswordMutation,
  useGetCompanyDetailsMutation,
} = nodeAPI;
