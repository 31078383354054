import React, { useState, useRef, useContext } from "react";
import SideBox from "../Generic/SideBox";
import InputField from "../Generic/InputField";
import "./../Generic/credForm.css";
import { Link } from "react-router-dom";
import { Form } from "antd";
import { VariantType, useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import AppContext from "../../context/appState/AppContext";
import { useSignupMutation } from "../../services/nodeAPI";
import { useNavigate } from "react-router-dom";

const SignUpForm = () => {
  const head = "Introducing smart ways to find candidates";
  const subHead =
    "Meerkat provide smarter way of recruitment, in few easy steps you  can find the best talent for your company";

  const [signup] = useSignupMutation();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [creds, setCreds] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const formRef = useRef(null);
  const confirmPass = useRef(null);

  const { onChangeGeneric } = useContext(AppContext);

  const onChange = onChangeGeneric(creds, setCreds);

  //******** SUBMIT Signup FORM
  const handleSubmit = async (e) => {
    setLoading(true);

    // CHECKING PASSWORDS are same
    if (creds.password !== creds.confirmPassword) {
      setLoading(false);
      enqueueSnackbar("Password and Confirm Password are not same", {
        variant: "error",
      });
    }

    // Submitting form
    else {
      const res = await signup(creds);

      console.log(res);

      if (res.data.success) {
        setLoading(false);
        formRef.current.resetFields();
        setCreds({
          firstname: "",
          lastname: "",
          username: "",
          email: "",
          password: "",
        });
        enqueueSnackbar(res.data.message, { variant: "success" });

        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        setLoading(false);
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="hidden md:block md:w-6/12">
        <SideBox
          image="Group 614.png"
          width="320px"
          heading={head}
          subHeading={subHead}
          imageClass={"w-5/12 mt-28 mb-12"}
        />
      </div>

      <div className="w-full md:w-6/12 flex justify-center">
        <div className="w-full lg:w-10/12 2xl:w-8/12">
          <div className="ps-4 pe-4 mt-10">
            <div className="form_top_content">
              <div className="justify-center">
                <h1 className="text-3xl font-medium text-center">
                  Create Account
                </h1>
                <p className="text-center">
                  Please provide all the required information.
                </p>
              </div>

              <div className="mt-10 signup-form-fields pe-4">
                <Form className="" ref={formRef} onFinish={handleSubmit}>
                  <div className="flex flex-wrap">
                    <div className="singup-flex-input">
                      <InputField
                        name="firstname"
                        label="First Name"
                        type="text"
                        placeholder="Enter first name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter firstname!",
                          },
                        ]}
                        onChange={onChange}
                      />
                    </div>

                    <div className="singup-flex-input">
                      <InputField
                        name="lastname"
                        label="Last Name"
                        type="text"
                        placeholder="Enter last name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter second name!",
                          },
                        ]}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap mt-2">
                    <div className="singup-flex-input">
                      <InputField
                        name="email"
                        label="Email Address"
                        type="email"
                        placeholder="Enter email address"
                        rules={[
                          {
                            required: true,
                            message: "Please enter valid email!",
                            type: "email",
                          },
                        ]}
                        onChange={onChange}
                      />
                    </div>
                    <div className="singup-flex-input">
                      <InputField
                        name="username"
                        label="Username"
                        type="text"
                        placeholder="Enter username"
                        rules={[
                          { required: true, message: "Please enter username!" },
                        ]}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div className="mt-3">
                    <InputField
                      name="password"
                      label="Password"
                      type="password"
                      margin="mx-auto"
                      placeholder="Enter password"
                      rules={[
                        { required: true, message: "Please enter password!" },
                      ]}
                      onChange={onChange}
                    />
                  </div>

                  <div className="mt-3">
                    <InputField
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      margin="mx-auto"
                      onChange={onChange}
                      placeholder="Confirm your password"
                      rules={[
                        { required: true, message: "Confirm your password!" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    />
                  </div>

                  <div className="w-100 text-center mt-4">
                    {/* <button className="btn create_account_btn" style={{ width: "70%" }} type="submit">Create Account</button> */}

                    <LoadingButton
                      size="small"
                      loading={loading}
                      loadingPosition="end"
                      variant="contained"
                      className="btn create_account_btn w-100"
                      type="submit"
                    >
                      Create Account
                    </LoadingButton>
                  </div>

                  <div className="mx-auto text-center mt-4 w-full lg:w-10/12 xl:w-8/12">
                    <p className="Pricay_msg text-muted">
                      By clicking create account, you aggreed to{" "}
                      <a href="/" className="Links">
                        {" "}
                        Terms of Services
                      </a>{" "}
                      and acknowledge you have read the{" "}
                      <a href="/" className="Links">
                        Privacy Policy.
                      </a>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>

          <div className="move_login text-center">
            <p>
              Already have an account?
              <Link to="/login" className="ms-2 inline_link">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
