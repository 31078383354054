import React, {
  useState,
  useContext,
  useRef,
  Fragment,
  useEffect,
} from "react";
// import InputField from '../Generic/InputField'
import { Select, Button } from "antd";
import {
  useAddRoleMutation,
  useGetPermissionResourcesQuery,
} from "../../services/nodeAPI";
import { Spin } from "antd";
import { Form } from "antd";
import AppContext from "../../context/appState/AppContext";
import LoadingButton from "@mui/lab/LoadingButton";
import { VariantType, useSnackbar } from "notistack";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

export default function AddNewRole(props) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const selectRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [addRole] = useAddRoleMutation();

  const { enqueueSnackbar } = useSnackbar();

  const { Option, OptGroup } = Select;

  const [permRes, setPermRes] = useState(null);
  const [roleName, setRoleName] = useState(null);

  const [selectedPermission, setSelectedPermission] = useState([]);
  var [isNewRoleDialogOpen, setIsNewRoleDialogOpen] = useState(false);

  useEffect(() => {
    if (isNewRoleDialogOpen !== true) {
      setIsNewRoleDialogOpen(props.showModal);
      props.closeModal();
    }
  });

  function closeModal() {
    setIsNewRoleDialogOpen(false);
    props.closeModal();
  }

  function openModal() {
    setIsNewRoleDialogOpen(true);
  }

  const handleRoleName = (e) => {
    setRoleName(e.target.value);
  };

  function handleChange(value) {
    setPermRes(value);
  }

  const convertArrayOfObj = (jsonArray) => {
    const arr = jsonArray.map((el) => JSON.parse(el));
    return arr;
  };

  const { data, error, isLoading } = useGetPermissionResourcesQuery();
  console.log(data);
  // console.log( !isLoading&&data );

  // ************** SUbmit form

  const handleSubmit = async () => {
    setLoading(true);

    // const arr=convertArrayOfObj( permRes )
    const arr = convertArrayOfObj(selectedPermission);

    const data = { roleName: roleName, perm_res: arr };

    const res = await addRole(data);

    if (res.data.success) {
      setLoading(false);
      enqueueSnackbar(res.data.message, { variant: "success" });
      setSelectedPermission([]);
      setRoleName(null);
    } else {
      setLoading(false);
      enqueueSnackbar(res.data.message, { variant: "error" });
    }
  };

  return (
    <>
      {
        <Transition appear show={isNewRoleDialogOpen} as={Fragment}>
          <Dialog as="div" className="relative z-30" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all">
                    {/* <!-- Modal content --> */}
                    <div className="relative">
                      <button
                        type="button"
                        class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                        onClick={closeModal}
                      >
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div class="py-6 px-6 lg:px-8">
                        <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                          Create a new Role
                        </h3>
                        <div>
                          <label
                            for="roleName"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Role Name
                          </label>
                          <input
                            type="text"
                            name="roleName"
                            id="roleName"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="New Role Name"
                            value={roleName}
                            required
                            onChange={handleRoleName}
                          />
                        </div>
                        <div className="mt-3">
                          <label
                            for="permissions"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Select Permissions
                          </label>

                          <div className="w-100">
                            <Listbox
                              value={selectedPermission}
                              onChange={setSelectedPermission}
                              multiple
                            >
                              <div className="relative mt-1">
                                {/* <Listbox.Label>Select Permisions</Listbox.Label> */}
                                <Listbox.Button className="focus:outline-none relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedPermission.length > 0
                                      ? `${selectedPermission.length} Permissions Selected`
                                      : "Select Permissions"}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <SelectorIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="focus:outline-none absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                                    {data &&
                                      data.data.map((element, index) => (
                                        <Listbox.Option
                                          key={index}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                              active
                                                ? "bg-blue-100 text-blue-700"
                                                : "text-gray-700"
                                            }`
                                          }
                                          value={JSON.stringify({
                                            pId: element.pid,
                                            rId: element.rid,
                                          })}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${
                                                  selected
                                                    ? "font-medium"
                                                    : "font-normal"
                                                }`}
                                              >
                                                {/* Making First letter of each word capital */}
                                                {element.name.replace(
                                                  /(^\w|\s\w)/g,
                                                  (m) => m.toUpperCase()
                                                )}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          </div>

                          <p
                            id="permissions-helper"
                            class="mt-2 text-sm text-gray-500 dark:text-gray-400"
                          >
                            You can select multiple permissions for a single
                            role.
                          </p>
                        </div>
                        <div className="mt-60">
                          {loading === false ? (
                            <button
                              type="button"
                              class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              onClick={handleSubmit}
                            >
                              Create New Role
                            </button>
                          ) : (
                            <button
                              disabled
                              type="button"
                              className="w-full py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 justify-content-center bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                            >
                              <svg
                                role="status"
                                class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="#1C64F2"
                                />
                              </svg>
                              Please Wait...
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        // <Form className='px-3' ref={formRef} onFinish={handleSubmit}>
        //   <div className='my-5'><h3>Add new Role</h3> </div>
        //   <div className='row'>
        //     <div className='col-4'> <InputField label="Role Name" width='90%' height='32px' placeholder='Role Name' name="roleName" type='text' rules={[ { required: true, message: 'Please enter Role name!' } ]} onChange={handleRoleName} /> </div>
        //   </div>
        //   <div className='row'>
        //     <div className='col-4'>
        //       <p className='mt-3' style={{ fontWeight: 500 }}>Select Permissions for this new role</p>
        //       <Form.Item name="perm_res" rules={[ { required: true, message: "Please select permissions!" } ]}>
        //         <Select showSearch mode="tags" style={{ width: '90%' }} name="perm_res" placeholder="Select Multiple Permissions" onChange={handleChange} allowClear={true} optionFilterProp="children"
        //           filterOption={( input, option ) =>
        //             option.children.toLowerCase().indexOf( input.toLowerCase() )>=0
        //           }
        //           filterSort={( optionA, optionB ) =>
        //             optionA.children.toLowerCase().localeCompare( optionB.children.toLowerCase() )
        //           }>

        //           {data&&data.data.map( ( el, i ) => {
        //             return <Option key={i} required={true} value={JSON.stringify( { pId: el.pid, rId: el.rid } )} >{el.name}</Option>
        //           } )}

        //         </Select>

        //       </Form.Item>

        //     </div>
        //   </div>

        //   <LoadingButton
        //     size="small"
        //     loading={loading}
        //     loadingPosition="end"
        //     variant="contained"
        //     className="btn my-5 px-4 "
        //     type="submit"
        //     style={{ width: '180px', height: '31px', backgroundColor: '#265BC4', color: 'white', borderRadius: '4px' }}

        //   >
        //     Save New Role
        //   </LoadingButton>
        // </Form>
      }
    </>
  );
}
