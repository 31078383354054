import axios from "axios";
import Cookies from "js-cookie";

// const BASE_URL = "https://3.85.232.76";
const BASE_URL = "https://www.meerkatsearch.com";

var responseHeader = {
  "x-access-token": Cookies.get("jwt"),
};

export default {
  async parseResume(inputData) {
    var formData = new FormData();
    formData.append("resume", inputData);

    let config = {
      header: {
        ...responseHeader,
        "Content-Type": "multipart/form-data",
      },
    };

    var res = await axios.post(BASE_URL + "/parse-resume", formData, config);

    return res.data;
  },
};
