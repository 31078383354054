import React, { useState } from "react";
import { Form, Input, Button, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  setJobQuestion,
  setJobId,
  setPhaseDefault,
} from "../../redux/jobSlice";
import { useAddJobQuestionMutation } from "../../services/nodeAPI";
import { useAddJobSkillMutation } from "../../services/nodeAPI";
import { useUpdateJobMutation } from "../../services/nodeAPI";
import "./newJob.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Questions({ moveNext, moveBack }) {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const jobQuestion = useSelector((state) => state.job.jobQuestion);

  const jobId = useSelector((state) => state.job.jobId);

  const jobInfo = useSelector((state) => state.job.jobInfo);
  const defQualification = useSelector((state) => state.job.qualification);
  const [addJobSkill] = useAddJobSkillMutation();
  const [updateJob] = useUpdateJobMutation();
  const [addJobQuestion] = useAddJobQuestionMutation();
  const [action, setAction] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    dispatch(setJobQuestion(values.job_questions));
    const questions = values.job_questions;
    if (action === "save") {
      handleQuestion(questions);
      dispatch(setPhaseDefault());
      enqueueSnackbar("Saved", { variant: "success" });
      navigate("/dashboard/jobs");
    }
    if (action === "next") {
      handleQuestion(questions);
      moveNext();
    }
    if (action === "back") moveBack();
  };

  const handleQuestion = async (questions) => {
    // const qual = defQualification.map((el) => {
    //   return { skill: el.skill, exp: Number(el.exp) };
    // });

    console.log({
      basicInfo: jobInfo,
      skills: defQualification,
      question: questions,
    });

    let res = await updateJob({
      basicInfo: jobInfo,
      skills: defQualification,
      question: questions,
      id: jobId,
    });
    // console.log(res);
    // let id = res.data.jobId;
    // console.log(id);
    // dispatch(setJobId(id));
  };

  return (
    <div className="px-3 py-3 w-full">
      <div className="mb-3">
        <p className="text-xl font-medium">Required Questions</p>
      </div>

      {/* Description Form */}
      <Form
        name="dynamic_form_nest_item"
        initialValues={{ job_questions: jobQuestion }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.List name="job_questions">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, i) => (
                <>
                  <h5 className="newjob_subheading">Question {i + 1}</h5>

                  <Space
                    className="flex flex-wrap w-full"
                    key={key}
                    // style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "job_question"]}
                      rules={[
                        { required: true, message: "Missing description" },
                      ]}
                    >
                      <textarea
                        rows="4"
                        class="block w-12/12 p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Add Question..."
                        style={{ width: "450px" }}
                      ></textarea>
                      {/* <TextArea
                        style={{ height: 120, width: "40rem" }}
                        placeholder="Add Question..."
                      /> */}
                    </Form.Item>
                    <div>
                      <div className="flex w-full justify-end -mt-8">
                        <span
                          className="delete_icon"
                          onClick={() => remove(name)}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                      {/* <a className="w-10 z-20">
                        <i
                          className="fa-solid fa-trash"
                          style={{ color: "red" }}
                          onClick={() => remove(name)}
                        />
                      </a> */}
                    </div>
                  </Space>
                </>
              ))}
              <Form.Item>
                <div className="mt-2 cursor-pointer">
                  <span className="text-blue-600" onClick={() => add()}>
                    <FontAwesomeIcon icon={faPlus} />{" "}
                    <span className="ms-2">Add another Required Question</span>
                  </span>
                </div>
              </Form.Item>
            </>
          )}
        </Form.List>
        <div className="BTN_GROUP">
          <div className="d-flex justify-content-end mb-2 pt-3 ">
            <div>
              {/* <Button
                className="px-5"
                htmlType="submit"
                onClick={() => setAction("back")}
                style={{ color: "#265BC4", border: "1px solid #265BC4" }}
              >
                Back
              </Button> */}
              <button
                onClick={() => setAction("back")}
                type="submit"
                class="py-2 px-3 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
              >
                Back
              </button>
            </div>
            <div>
              {/* <Button
                className="px-5 mx-4"
                htmlType="submit"
                onClick={() => setAction("save")}
                style={{ color: "#265BC4", border: "1px solid #265BC4" }}
              >
                Save & Exit
              </Button> */}
              <button
                type="submit"
                onClick={() => setAction("save")}
                class="py-2 px-3 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
              >
                Save & Exit
              </button>
            </div>
            <div>
              {/* <Button
                className="text-white px-5"
                htmlType="submit"
                onClick={() => setAction("next")}
                style={{
                  backgroundColor: "#265BC4",
                  boxShadow: "3px 3px 6px #00000029",
                  borderRadius: "4px",
                }}
              >
                Next
              </Button> */}
              <button
                type="submit"
                onClick={() => setAction("next")}
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2 text-center inline-flex items-center"
              >
                Next
                <svg
                  class="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
