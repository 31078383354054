import React from 'react'
import './PasswordlessLoginTemplate.css'
import { Button } from 'antd'

export default function PasswordlessLoginTemplate() {
  return (
      <div className='easy_login_body'>

    <div className='container '>
        <div className='row'>
            <div className='col-4 p-5'>Logo</div>
        </div>
        <div>
        <div class="card mx-auto" style={{borderRadius:'0.8rem' , width:'90%'}} >
  <div class="card-body px-4 pb-5">
    <p class="card-title px-4 pt-4 pb-2" style={{fontSize:'3rem',fontWeight:'bold' , marginBottom:'0rem'}}>Let's get you signed in</p>
    <p className='px-4 font__size'>Hi Customer Name,</p>
    <p class="card-text px-4 font__size" style={{marginBottom:'0rem'}}>We use this easy sign-in button so you dont have to remeber or type in yet another long password. </p>
    <div className='px-4 py-4'>

    <Button size='large' className='px-4' style={{backgroundColor:'#0048ff', color:'white', height:'3rem' , borderRadius:'10px'}}>Sign in</Button>
    </div>
    <p className='px-4 fw-bold font__size' style={{marginBottom:'0rem'}}>Or copy and paste the following URL into your browser:</p>
    <a className='px-4 font__size'>https://www/google.com</a>
  </div>
</div>
        </div>
    </div>
      </div>
  )
}
