import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

export default function SearchBoxFilter(props) {
  const [selected, setSelected] = useState([]);
  const [query, setQuery] = useState("");
  const [apiData, updateApiData] = useState([]);
  const [loadingData, setLoadingDataFlag] = useState(false);
  const [errorLoadingData, setErrorLoadingDataFlag] = useState(false);

  function searchData(inputData) {
    setLoadingDataFlag(true);
    props
      .search_api(inputData)
      .then((response) => {
        updateApiData(response.data.data);
        setLoadingDataFlag(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingDataFlag(false);
      });
    setQuery(inputData);
  }

  function updateData(data) {
    let saving_data = data.map((item) => JSON.parse(item));
    console.log(saving_data);
    props.updateFilterData(saving_data);
    setSelected(data);
  }

  return (
    <div
      className={props.className !== undefined ? props.className : "w-72 z-10"}
    >
      {/* {console.log(selected.map((person) => person.name).join(", "))} */}
      <Combobox value={selected} onChange={updateData} multiple>
        <div className="relative">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg border-1 border-gray-300 bg-white text-left shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
            <Combobox.Label className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0">
              <span className="block truncate">
                {selected.length > 0
                  ? `${selected.length} ${
                      selected.length > 1
                        ? props.filter_selection_label + "s"
                        : props.filter_selection_label
                    } Selected`
                  : props.filter_label}
              </span>
            </Combobox.Label>
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md border-1 border-gray-300 bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
              <Combobox.Input
                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                placeholder={`Search for ${props.filter_selection_label}...`}
                onChange={(event) => searchData(event.target.value)}
              />

              {loadingData === true ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  <svg
                    role="status"
                    class="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-500"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="text-gray-500 font-medium">
                    Loading Data ...
                  </span>
                </div>
              ) : (
                <div>
                  {errorLoadingData === true ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      Nothing found...
                    </div>
                  ) : (
                    apiData.map((item) => (
                      <Combobox.Option
                        key={item.id}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? "bg-blue-500 text-white" : "text-gray-900"
                          }`
                        }
                        value={JSON.stringify(item)}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate capitalize ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {item.name}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? "text-white" : "text-blue-500"
                                }`}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </div>
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
