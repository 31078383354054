import React, { useContext, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import AppContext from "../context/appState/AppContext";
import { useFindRolePermissionsMutation } from './../services/nodeAPI';


const ProtectedRoute=( {
  redirectPath='/login',
  children,
  role,
  resource,
  permission
} ) => {


  const [ FindRolePermissions ]=useFindRolePermissionsMutation()


  const getResourcesArray=() => {


    const jwt=Cookies.get( 'jwt' );

    const data=JSON.parse( localStorage.getItem( 'perm_res' ) )

    let data2=[];


    data.forEach( el => {
      if ( !data2.find( e => e===el.resourceId ) ) {
        data2.push( el.resourceId )
      }
    } )

    return data2;


  }

  const getPermissionsOfUserForResource=( rId ) => {

    let data=[];
    const permRes=JSON.parse( localStorage.getItem( 'perm_res' ) )


    permRes.forEach( el => {

      if ( el.resourceId===rId ) {

        if ( !data.find( e => e===el.permissionId ) ) {
          data.push( el.permissionId )
        }

      }

    } )

    return data;

  }


  // Check user token here




  const jwt=Cookies.get( 'jwt' );

  const resources=getResourcesArray()

  const permissions=getPermissionsOfUserForResource( resource );

  if ( !jwt||!localStorage.getItem( 'perm_res' ) ) {
    return <Navigate to={redirectPath} replace />;
  }



  if ( resource ) {

    if ( !resources.includes( resource ) ) {
      return <Navigate to={'/forbidden'} replace />;
    }

  }


  if ( permission ) {

    if ( !permissions.includes( permission ) ) {
      return <Navigate to={'/forbidden'} replace />;
    }


  }


  return children;
};


export default ProtectedRoute;