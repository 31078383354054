import React, { useState, useEffect } from "react";
import InputField from "../Generic/InputField";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import SimpleModal from "../Modal";
import {
  useGetAllCitiesMutation,
  useGetAllCountriesMutation,
  useUpdateUserCompanyDetailsMutation,
  useGetCompanyDetailsMutation,
} from "../../services/nodeAPI";
import { VariantType, useSnackbar } from "notistack";

const CompanyDetails = () => {
  const Input = styled("input")({
    display: "none",
  });

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [getAllCities] = useGetAllCitiesMutation();
  const [getAllCountries] = useGetAllCountriesMutation();
  const [getCompanyData] = useGetCompanyDetailsMutation();
  const [companyData, setCompanyData] = useState({});
  const [updateCompanyData] = useUpdateUserCompanyDetailsMutation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const allCountries = async () => {
    const { data } = await getAllCountries();
    setCountries(data.countryData);
  };

  const allCities = (id) => {
    getAllCities(id).then((res) => {
      setCities(res.data.citiesData);
    });
  };

  useEffect(() => {
    allCountries();
    getCompanyData().then((response) => {
      setCompanyData(response.data.company_data[0]);
      allCities(response.data.company_data[0].countryId);
    });
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const [ photoURL, setPhotoURL ]=useState( currentUser?.photoURL );
  const [photoURL, setPhotoURL] = useState("");
  const [realPhotoURL, setRealPhotoURL] = useState(null);
  const [file, setFile] = useState("");

  const [openCrop, setOpenCrop] = useState(false);

  const handleFileUpload = (e) => {
    console.log(e.target.files[0]);
    const f = e.target.files[0];
    if (f) {
      setOpenCrop(true);
      setPhotoURL(URL.createObjectURL(f));
      setFile(f);
      showModal();
    }
  };

  const handleDeletImg = () => {
    setRealPhotoURL(null);
    setFile("");
  };

  function handleFormData(key, value) {
    if (key === "countryId") {
      allCities(value);
    }

    setCompanyData({
      ...companyData,
      [key]: value,
    });
  }

  const submitFormData = () => {
    updateCompanyData(companyData).then((response) => {
      if (response.data.success) {
        enqueueSnackbar("Company Details Updated!", { variant: "success" });
      } else {
        enqueueSnackbar("Unable to update!", {
          variant: "error",
        });
      }
    });
  };

  return (
    <div id="company_details">
      <div className="mt-5">
        <span className="text-xl font-medium dark:text-white">
          Company Details
        </span>
        <p className="test-base font-base text-gray-500 dark:text-white">
          In this section you can manage your company details.
        </p>
        <hr className="horizontal_bar w-80" />
      </div>

      <div className="mt-4">
        <div className="">
          <div className="gap-3 flex flex-wrap">
            <div className="w-80">
              <span className="text-lg font-semibold dark:text-white">
                Company Logo
              </span>
              <p className="test-base font-base text-gray-500 dark:text-white">
                This will be displayed on the company jobs.
              </p>
            </div>
            <div className="">
              <Avatar
                alt="Avatar"
                src={"https://www.meerkatsearch.com" + companyData.company_img}
                // src={realPhotoURL}
                sx={{ width: 100, height: 100 }}
                style={{ marginTop: "" }}
              />
            </div>

            <div className="pt-4">
              <label htmlFor="contained-button-file">
                {/* <Input accept="image/*" onChange={handleFileUpload} id="contained-button-file" multiple type="file" /> */}
                <input
                  onChange={handleFileUpload}
                  class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  id="user_avatar"
                  type="file"
                  hidden
                />
                <button
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-normal rounded text-sm px-4 py-2.5 mr-2 mb-2 focus:outline-none"
                  onClick={() => {
                    document.getElementById("user_avatar").click();
                  }}
                >
                  Upload Image
                </button>
              </label>
              {/* <button 
                type="button" 
                className="ms-2 py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={handleDeletImg}
              >
                Delete
              </button> */}
              {/* <button className='btn me-4 img_delete_btn' onClick={handleDeletImg} >Delete</button> */}
            </div>

            {
              <SimpleModal
                handleOk={handleOk}
                handleCancel={handleCancel}
                isModalVisible={isModalVisible}
                realPhotoURL={realPhotoURL}
                setRealPhotoURL={setRealPhotoURL}
                photoURL={photoURL}
                setPhotoURL={setPhotoURL}
                type="companyPic"
                setOpenCrop={setOpenCrop}
                setFile={setFile}
              />
            }
          </div>
        </div>

        <div className="gap-3 flex flex-wrap mt-3">
          <div className="md:pt-3 w-80">
            <span className="text-lg font-semibold dark:text-white">
              Company Name
            </span>
          </div>

          <div className="w-72">
            <div className="row">
              <div className="">
                <InputField
                  placeholder="Your Company name"
                  name="company_name"
                  onChange={(event) =>
                    handleFormData(event.target.name, event.target.value)
                  }
                  defaultValue={companyData?.name}
                  type="text"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="gap-3 flex flex-wrap mt-3">
          <div className="w-80">
            <span className="text-lg font-semibold dark:text-white">
              Company Location
            </span>
          </div>

          <div className="">
            <div className="gap-3 flex flex-wrap">
              <div className="w-72">
                <select
                  id="country"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(event) =>
                    handleFormData(event.target.name, event.target.value)
                  }
                  name="countryId"
                >
                  <option selected>Select Country</option>
                  {countries.map((el, i) => {
                    if (el.id === companyData?.countryId) {
                      return (
                        <option key={i} value={el.id} selected>
                          {el.name}
                        </option>
                      );
                    }
                    return (
                      <option key={i} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="w-72">
                <select
                  id="city"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(event) =>
                    handleFormData(event.target.name, event.target.value)
                  }
                  name="cityId"
                >
                  <option selected value="none">
                    Select City
                  </option>
                  {cities.map((el, i) => {
                    if (el.id === companyData?.cityId) {
                      return (
                        <option key={i} value={el.id} selected>
                          {el.name}
                        </option>
                      );
                    }
                    return (
                      <option key={i} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="text-end mt-5">
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={submitFormData}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
