import React from "react";
import SideBox from "./../Generic/SideBox";
import InputField from "./../Generic/InputField";
import "./../Generic/credForm.css";
import { Link } from "react-router-dom";
import { useState, useContext, useRef, Fragment } from "react";
import AppContext from "../../context/appState/AppContext";
import {
  useFindRolePermissionsMutation,
  useLoginMutation,
  usePasswordResetEmailMutation,
} from "../../services/nodeAPI";
import jwt_decode from "jwt-decode";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form } from "antd";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const navigate = useNavigate();

  const getResourcesArray = (data) => {
    let data2 = [];
    data.forEach((el) => {
      if (!data2.find((e) => e === el.resourceId)) {
        data2.push(el.resourceId);
      }
    });

    return data2;
  };

  const [login] = useLoginMutation();

  const [FindRolePermissions] = useFindRolePermissionsMutation();
  const [passwordResetEmail] = usePasswordResetEmailMutation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const head = "Make a smart recruitment solution";

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const subHead =
    "Meerkat provide smarter way of recruitment, in few easy steps you  can find best talent for your company";

  const { enqueueSnackbar } = useSnackbar();

  const [creds, setCreds] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [sendingResetLink, setSendingResetLink] = useState(false);
  const [resetLinkSent, setResetLinkSent] = useState(false);

  const formRef = useRef(null);

  const { onChangeGeneric, Cookies } = useContext(AppContext);

  const onChange = onChangeGeneric(creds, setCreds);

  //******** SUBMIT LOGIN FORM
  const handleSubmitLoginForm = async () => {
    setLoading(true);

    const res = await login(creds);

    console.log(res);

    if (res.data.success) {
      setLoading(false);
      Cookies.set("jwt", res.data.web_token);
      const decoded = jwt_decode(res.data.web_token);

      // console.log( decoded );

      const res2 = await FindRolePermissions(decoded.roleId);

      // console.log( "=====> ", res2.data.data );

      localStorage.setItem("perm_res", JSON.stringify(res2.data.data));
      localStorage.setItem(
        "resources",
        JSON.stringify(getResourcesArray(res2.data.data))
      );

      formRef.current.resetFields();
      setCreds({ email: "", password: "" });
      enqueueSnackbar(res.data.message, { variant: "success" });
      setTimeout(() => {
        navigate("/dashboard/home");
      }, 2000);
    } else {
      setLoading(false);
      enqueueSnackbar(res.data.message, { variant: "error" });
    }
  };

  const sendPasswordResetLink = async () => {
    setSendingResetLink(true);
    passwordResetEmail({ email: resetPasswordEmail }).then((response) => {
      setSendingResetLink(false);
      if (response.data.success === false) {
        setResetLinkSent(false);
        enqueueSnackbar(response.data.message, { variant: "error" });
      } else {
        setResetLinkSent(true);
        enqueueSnackbar(response.data.message, { variant: "success" });
      }
    });
  };

  // function handleClick() {
  //   setLoading( true );
  // }

  return (
    <div className="flex flex-wrap">
      <div className="hidden md:block md:w-6/12">
        <SideBox
          image="illustrations2.png"
          heading={head}
          subHeading={subHead}
          imageClass="w-9/12"
        />
      </div>

      <div className="w-full md:w-6/12 flex justify-center">
        <div className="w-full lg:w-8/12 2xl:w-8/12">
          <div className="px-3 mt-20">
            <div className="form_top_content">
              <div className="justify-center">
                <h1 className="text-3xl font-medium text-center">
                  Welcome Back
                </h1>
                <p className="text-center">
                  Please enter your account details to login.
                </p>
              </div>

              <div className="mt-20">
                <Form ref={formRef}>
                  <div className="w-100">
                    <InputField
                      name="email"
                      label="Email Address"
                      onChange={onChange}
                      margin="mx-auto"
                      placeholder="Enter email address"
                      type="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter valid email!",
                          type: "email",
                        },
                      ]}
                    />
                  </div>

                  <div className="w-100 mt-4">
                    <InputField
                      name="password"
                      label="Password"
                      onChange={onChange}
                      margin="mx-auto"
                      placeholder="Enter password"
                      type="password"
                      required={true}
                      rules={[
                        { required: true, message: "Please enter password!" },
                      ]}
                    />
                  </div>

                  <div className="mt-3 check_input">
                    <div className="w-100 text-end">
                      <a
                        onClick={showModal}
                        className="text-blue-500 hover:text-blue-500"
                      >
                        Forgot Password
                      </a>
                    </div>
                  </div>

                  <div className="w-100 text-center mt-4">
                    <div className="col-md-4 text-end">
                      <Transition appear show={isModalVisible} as={Fragment}>
                        <Dialog
                          as="div"
                          className="relative z-10"
                          onClose={handleCancel}
                        >
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                          </Transition.Child>

                          <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                              >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all">
                                  {/* <!-- Modal content --> */}
                                  <div className="relative">
                                    <button
                                      type="button"
                                      class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                                      onClick={handleCancel}
                                    >
                                      <svg
                                        class="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                          clip-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </button>
                                    <div class="py-3 px-2 lg:px-2">
                                      <div className="mx-auto p-3">
                                        <form className="space-y-6" action="#">
                                          <h5 className="text-xl font-medium text-gray-900 dark:text-white">
                                            Forget Password
                                          </h5>
                                          <div>
                                            <label
                                              for="email"
                                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                            >
                                              Your email
                                            </label>
                                            <input
                                              type="email"
                                              name="email"
                                              id="email"
                                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                              placeholder="name@company.com"
                                              required
                                              onChange={(e) =>
                                                setResetPasswordEmail(
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <div className="text-center">
                                              <p
                                                id="helper-text-explanation"
                                                style={{
                                                  marginBottom: 0,
                                                  fontSize: "12px",
                                                }}
                                                className="mt-2 text-gray-500 dark:text-gray-400"
                                              >
                                                A password reset link will be
                                                sent to your email{" "}
                                                <a
                                                  href="#"
                                                  className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                                                >
                                                  Privacy Policy
                                                </a>
                                                .
                                              </p>
                                            </div>
                                          </div>
                                          {sendingResetLink === true ? (
                                            <button
                                              disabled
                                              type="button"
                                              class="w-full flex justify-center disabled:opacity-80 text-white bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                            >
                                              <svg
                                                role="status"
                                                class="inline w-4 h-4 mr-3 text-white animate-spin"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                  fill="#E5E7EB"
                                                />
                                                <path
                                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                              Sending Reset Link...
                                            </button>
                                          ) : (
                                            <>
                                              {resetLinkSent === true ? (
                                                <button
                                                  disabled
                                                  type="button"
                                                  className="w-full text-white disabled:opacity-80 bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                >
                                                  Reset Link Sent
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                  onClick={() =>
                                                    sendPasswordResetLink()
                                                  }
                                                >
                                                  Send password reset link
                                                </button>
                                              )}
                                            </>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition>
                      {/* <Modal bodyStyle={{height:300}} style={{padding:0}} visible={isModalVisible} onCancel={handleCancel}  footer={null}>
                      </Modal> */}
                    </div>
                  </div>

                  <div>
                    <LoadingButton
                      size="small"
                      loading={loading}
                      loadingPosition="end"
                      variant="contained"
                      className="btn create_account_btn w-100"
                      type="button"
                      onClick={() => handleSubmitLoginForm()}
                    >
                      Login
                    </LoadingButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>

          <div className="move_signup text-center mt-5">
            <p>
              Don't have an account?
              <Link to="/signup" className="ms-2 inline_link">
                Create Account
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
