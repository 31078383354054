import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { PageHeader } from "../Generic/PageHeader";
import "./consultant.css";
import { setEmployeeID } from "../../redux/employee";
import { useGetConsultantsMutation } from "../../services/nodeAPI";
import { Spin, Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function Consultants() {
  const dispatch = useDispatch();
  const [getEmployees, isLoading] = useGetConsultantsMutation();
  const [employee, setEmployee] = useState(null);
  const [EMP, setEMP] = useState([]);

  const getEmployeeData = async (fullname = "") => {
    const res = await getEmployees(fullname);
    setEmployee(res.data.employeeData);
    setEMP(res.data.employeeData);
    console.log(res);
  };

  const handleFilter = (val) => {
    if (val === "all") {
      setEmployee(EMP);
    } else {
      const arr = EMP.filter((el) => el.rolename === val);
      setEmployee(arr);
    }
  };

  const handleSearch = async (e) => {
    await getEmployeeData(e.target.value);
  };

  useEffect(() => {
    getEmployeeData();
  }, []);

  const setEmpId = (id) => {
    dispatch(setEmployeeID(id));
  };
  return (
    <div className="contianer_fluid px-3 pe-5">
      <div className="row">
        <div className="d-flex flex-wrap justify-content-between">
          <div>
            <span className="text-2xl font-medium dark:text-white">
              Consultants
            </span>
            <p className="test-base font-base text-gray-500 dark:text-white">
              In this section you can manage consultants.
            </p>
          </div>
        </div>
        {/* <div className="col-4 mt-4 text-end">
          <Link
            to={"/dashboard/addemployee"}
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-base rounded text-xs px-4 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            <FontAwesomeIcon icon={faPlus} />{" "}
            <span className="ms-2"> Add New Employee</span>
          </Link>
        </div> */}
      </div>

      <div className="row my-3">
        <div className="w-100">
          <span className="text-lg font-semibold dark:text-white">
            Search Filters
          </span>
          <p className="test-base font-base text-gray-500 dark:text-white">
            With the help of filters you can easily find consultants.
          </p>

          <div className="w-80 flex items-center mt-3">
            <div className="relative w-100">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="search"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Consultant by name..."
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Cards */}

      <section className="employee_cards">
        {isLoading.isLoading || !employee ? (
          <Spin tip="Loading..."></Spin>
        ) : employee.length > 0 ? (
          employee.map((el) => {
            return (
              <div
                key={el.id}
                className="w-64 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mr-5 mt-4 pt-3 pr-2"
              >
                <div class="flex flex-col items-center pb-10">
                  <img
                    class="mb-3 w-24 h-24 rounded-full shadow-md"
                    src={`https://www.meerkatsearch.com/${el.profile_img}`}
                    alt={el.fullname}
                  />
                  <h5 class="mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                    {el.fullname}
                  </h5>

                  <span class="bg-blue-100 text-blue-800 text-sm font-normal mr-2 px-4 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                    {el.rolename}
                  </span>

                  <div className="mt-4 text-center">
                    <div class="text-gray-700 text-base font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                      {el.phone_number}
                    </div>

                    <span class="text-blue-600 text-sm font-normal mr-2 px-2.5 rounded dark:bg-gray-700 dark:text-gray-300">
                      {el.email}
                    </span>
                  </div>

                  {/* <span class="text-sm text-gray-500 dark:text-gray-400">

                  </span> */}
                </div>
              </div>
            );
          })
        ) : (
          <Empty className="mt-5" />
        )}
      </section>

      {/* <section className='employee_cards'>
        <div className="card mt-4 employee_card mx-2 " >
          <div className="card-body text-center">
            <div className="employee_card_image ">
              <img src="https://cdn.wisden.com/wp-content/uploads/2017/11/GettyImages-688792304-2-e1517230909915.jpg" />
            </div>
            <h5 className="card-title employee_card_name">Babar Azam</h5>
            <span className="employee_card_role">Candidate Management</span>

            <p className="card-text employee_card_phone">+92333444809</p>
            <p className="card-text employee_card_email">kingbabar@gmail.com</p>
            <button className="employee_card_btn">Manage</button>
          </div>
        </div>
        <div className="card mt-4 employee_card mx-2 " >
          <div className="card-body text-center">
            <div className="employee_card_image ">
              <img src="https://cdn.wisden.com/wp-content/uploads/2017/11/GettyImages-688792304-2-e1517230909915.jpg" />
            </div>
            <h5 className="card-title employee_card_name">Babar Azam</h5>
            <span className="employee_card_role">Candidate Management</span>

            <p className="card-text employee_card_phone">+92333444809</p>
            <p className="card-text employee_card_email">kingbabar@gmail.com</p>
            <button className="employee_card_btn">Manage</button>
          </div>
        </div>
        <div className="card mt-4 employee_card mx-2 " >
          <div className="card-body text-center">
            <div className="employee_card_image ">
              <img src="https://cdn.wisden.com/wp-content/uploads/2017/11/GettyImages-688792304-2-e1517230909915.jpg" />
            </div>
            <h5 className="card-title employee_card_name">Babar Azam</h5>
            <span className="employee_card_role">Candidate Management</span>

            <p className="card-text employee_card_phone">+92333444809</p>
            <p className="card-text employee_card_email">kingbabar@gmail.com</p>
            <button className="employee_card_btn">Manage</button>
          </div>
        </div>
        <div className="card mt-4 employee_card mx-2 " >
          <div className="card-body text-center">
            <div className="employee_card_image ">
              <img src="https://cdn.wisden.com/wp-content/uploads/2017/11/GettyImages-688792304-2-e1517230909915.jpg" />
            </div>
            <h5 className="card-title employee_card_name">Babar Azam</h5>
            <span className="employee_card_role">Candidate Management</span>

            <p className="card-text employee_card_phone">+92333444809</p>
            <p className="card-text employee_card_email">kingbabar@gmail.com</p>
            <button className="employee_card_btn">Manage</button>
          </div>
        </div>
        <div className="card mt-4 employee_card mx-2 " >
          <div className="card-body text-center">
            <div className="employee_card_image ">
              <img src="https://cdn.wisden.com/wp-content/uploads/2017/11/GettyImages-688792304-2-e1517230909915.jpg" />
            </div>
            <h5 className="card-title employee_card_name">Babar Azam</h5>
            <span className="employee_card_role">Candidate Management</span>

            <p className="card-text employee_card_phone">+92333444809</p>
            <p className="card-text employee_card_email">kingbabar@gmail.com</p>
            <button className="employee_card_btn">Manage</button>
          </div>
        </div>
        <div className="card mt-4 employee_card mx-2 " >
          <div className="card-body text-center">
            <div className="employee_card_image ">
              <img src="https://cdn.wisden.com/wp-content/uploads/2017/11/GettyImages-688792304-2-e1517230909915.jpg" />
            </div>
            <h5 className="card-title employee_card_name">Babar Azam</h5>
            <span className="employee_card_role">Candidate Management</span>

            <p className="card-text employee_card_phone">+92333444809</p>
            <p className="card-text employee_card_email">kingbabar@gmail.com</p>
            <button className="employee_card_btn">Manage</button>
          </div>
        </div>

      </section> */}
    </div>
  );
}
