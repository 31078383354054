import "./App.css";
import io from 'socket.io-client'
import LoginForm from "./components/Login";
import { Navbar } from "./components/Navbar";
import SignUpForm from "./components/Signup";
import { Jobs } from "./components/Jobs/Jobs";
import Dashboard from "./components/Dashboard";
import { Link, Outlet } from "react-router-dom";
import { SideNavbar } from "./components/SideNavbar";
import ResetPassword from "./components/ResetPassword";
import ProtectedRoute from "./components/ProtectedRoute";
import PasswordlessLoginTemplate from "./components/PasswordlessLoginTemplate/PasswordlessLoginTemplate";

// import ProtectedRoute from './components/ProtectedRoute.js';
import "antd/dist/antd.css";
import { Routes, Route } from "react-router-dom";
import Employees from "./components/Employees/Employees";
import AddConsultant from "./components/AddConsultant/AddConsultant";
import AddEmployees from "./components/AddEmployees/AddEmployees";
import AddNewCandidate from "./components/AddNewCandidate/AddNewCandidate";
import AddNewRole from "./components/AddNewRole/AddNewRole";
import Candidates from "./components/Candidates/Candidates";
import CandidateTracking from "./components/CandidatesTracking/CandidateTracking";
import RoleManagement from "./components/RoleManagement/RoleManagement";
import Settings from "./components/Settings";
import SmartSearch from "./components/SmartSearch/SmartSearch";
import Consultants from "./components/Consultants/Consultants";
import NewJob from "./components/NewJob/NewJob";
import UpdateJob from "./components/EditJob/EditJob";
import Landing from "./components/LandingPage/Landing";
import EditCandidate from "./components/EditCandidate/EditCandidate";
import ViewCandidate from "./components/ViewCandidate/ViewCandidate";
import { useSelector, useDispatch } from "react-redux";
import { setCandidateId } from "./redux/candidateSlice";
import { useEffect, useState } from "react";
import Forbidden from "./components/ErrorTemplates/ForbiddenPage";
import UpdateEmployees from "./components/AddEmployees/UpdateEmployee";
import PageNotFound from "./components/ErrorTemplates/PageNotFound";
import CrawlerSearch from "./updates/pages/crawlerSearch";
import { useLocation } from 'react-router-dom'

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const candidateId = useSelector((state) => state.candidate.candidateId);
  const [connectedSocket, setConnectedSocket] = useState(undefined)

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      disconnectSocketConnection()
    })
  }, [])

  useEffect(() => {
    if (!location.pathname.includes('/dashboard/home')) {
      disconnectSocketConnection()
    }
  }, [location])

  const [mobileMenu, setMobileMenu] = useState(false);

  function toggleMobileMenu() {
    setMobileMenu(!mobileMenu);
  }

  function createSocketConnection() {
    const socket = io('https://www.meerkatsearch.com')
    setConnectedSocket(socket)
  }

  function disconnectSocketConnection() {
    if (connectedSocket !== undefined) {
      connectedSocket.disconnect();
    }
  }

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Landing />} />

        <Route
          exact
          path="/passwordlesslogin"
          element={<PasswordlessLoginTemplate />}
        />

        <Route exact path="/signup" element={<SignUpForm />} />
        <Route exact path="/login" element={<LoginForm />} />
        <Route
          exact
          path="/reset-password/:token"
          element={<ResetPassword />}
        />

        <Route exact path="/forbidden" element={<Forbidden />} />

        <Route
          exact
          path="dashboard"
          element={
            <ProtectedRoute>
              <>
                <SideNavbar mobileMenu={mobileMenu} />
                <Navbar
                  mobileMenu={mobileMenu}
                  toggleMobileMenu={toggleMobileMenu}
                />
                <div className="pt-20 md:ml-24 2xl:ml-72">
                  <Outlet />
                </div>
              </>
            </ProtectedRoute>
          }
        >
          {/****************** ROUTES  *****************/}
          <Route
            exact
            path="home"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="jobs"
            element={
              <ProtectedRoute resource="1">
                <Jobs />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="addnewjob"
            element={
              <ProtectedRoute resource="1" permission="2">
                <NewJob />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="updatejob"
            element={
              <ProtectedRoute resource="1" permission="1">
                <UpdateJob />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="candidates"
            element={
              <ProtectedRoute resource="2">
                <Candidates />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="editcandidate/:candidateId"
            element={
              <ProtectedRoute resource="2" permission="1">
                <EditCandidate candidateId={candidateId} />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="addnewcandidate"
            element={
              <ProtectedRoute resource="2" permission="2">
                <AddNewCandidate />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="candidatetracking"
            element={
              <ProtectedRoute resource="2">
                <CandidateTracking />
              </ProtectedRoute>
            }
          />

          <Route exact path="smartsearch" element={
              <ProtectedRoute resource="2">
                <CrawlerSearch 
                  socketConnection={connectedSocket} 
                  createSocketConnection={createSocketConnection}
                  disconnectSocketConnection={disconnectSocketConnection} 
                />
              </ProtectedRoute>
            } 
          />

          {/* <Route
            exact
            path="smartsearch"
            element={
              <ProtectedRoute resource="2">
                <SmartSearch />
              </ProtectedRoute>
            }
          /> */}

          <Route
            exact
            path="consultants"
            element={
              <ProtectedRoute resource="3">
                <Consultants />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="addconsultant"
            element={
              <ProtectedRoute resource="3" permission="2">
                <AddConsultant />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="employees"
            element={
              <ProtectedRoute resource="4">
                <Employees />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="addemployee"
            element={
              <ProtectedRoute resource="4" permission="2">
                <AddEmployees />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="updateemployee"
            element={
              <ProtectedRoute resource="4" permission="1">
                <UpdateEmployees />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="manageroles"
            element={
              <ProtectedRoute resource="5">
                <RoleManagement />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* ---------------- Routes that does not exist -------------------------- */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
