import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Input, Tag } from "antd";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import TableComp from "../TableComp/TableComp";
import { useState } from "react";

export default function CandidateTracking() {

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      width: "16.66%",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "16.66%",
      align: "center",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: "16.66%",
      align: "center",
    },
    {
      title: "Current Phase",
      key: "currentPhase",
      dataIndex: "currentPhase",
      width: "16.66%",
      align: "center",
    },
    {
      title: "Consultant Name",
      key: "consultantName",
      dataIndex: "consultantName",
      width: "16.66%",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: () => (
        <>
          <FeaturedPlayListIcon style={{ color: "#69AC64" }} />
        </>
      ),
      width: "16.66%",
      align: "center",
    },
  ];

  const data = [
    {
      key: "1",
      fullName: "Jim",
      email: "ok@gmail.com",
      location: "New York No. 1 Lake Park",
      currentPhase: (
        <>
          <Tag
            color="blue"
            className=" my-auto"
            style={{
              borderRadius: "3px",
              width: "131px",
              height: "34px",
              paddingTop: "6.5px",
            }}
          >
            Initial Phase
          </Tag>
        </>
      ),
      consultantName: "Bsc",
    },
    {
      key: "2",
      fullName: "Joe",
      email: "ok@gmail.com",
      location: "New York No. 1 Lake Park",
      currentPhase: (
        <>
          <Tag
            color="green"
            className=" my-auto"
            style={{
              borderRadius: "3px",
              width: "131px",
              height: "34px",
              paddingTop: "6.5px",
            }}
          >
            Initial Interview
          </Tag>
        </>
      ),
      consultantName: "Bsc",
    },
    {
      key: "3",
      fullName: "Rat",
      email: "ok@gmail.com",
      location: "New York No. 1 Lake Park",
      currentPhase: (
        <>
          <Tag
            color="blue"
            className=" my-auto"
            style={{
              borderRadius: "3px",
              width: "131px",
              height: "34px",
              paddingTop: "6.5px",
            }}
          >
            Initial Phase
          </Tag>
        </>
      ),
      consultantName: "Bsc",
    },
    {
      key: "4",
      fullName: "Kim",
      email: "ok@gmail.com",
      location: "New York No. 1 Lake Park",
      currentPhase: (
        <>
          <Tag
            color="green"
            className=" my-auto"
            style={{
              borderRadius: "3px",
              width: "131px",
              height: "34px",
              paddingTop: "6.5px",
            }}
          >
            Initial Interview
          </Tag>
        </>
      ),
      consultantName: "Bsc",
    },
    {
      key: "5",
      fullName: "John",
      email: "ok@gmail.com",
      location: "New York No. 1 Lake Park",
      currentPhase: (
        <>
          <Tag
            color="blue"
            className=" my-auto"
            style={{
              borderRadius: "3px",
              width: "131px",
              height: "34px",
              paddingTop: "6.5px",
            }}
          >
            Initial Interview
          </Tag>
        </>
      ),
      consultantName: "Bsc",
    },
  ];

  const [dataSource, setDataSource] = useState(data);
  const [value, setValue] = useState("");

  const searchByName = (e) => {
    const currValue = e.target.value;
    setValue(currValue);
    const filteredData = data.filter((entry) =>
      entry.fullName.includes(currValue)
    );
    setDataSource(filteredData);
  };

  return (
    <>
      <div className="px-3">
        <div className="row my-5">
          <div className="d-flex justify-content-between">
            <div>
              <h3 style={{ marginBottom: "0rem" }}>Candidate Tracking</h3>
              <small className="text-muted">
                In this section you can track candidate from initial interview
                to the final job offer.{" "}
              </small>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <h5 style={{ marginBottom: "0rem" }}>Search Filters</h5>
            <small className="text-muted">
              With the help of filters you can easily find candidates.
            </small>
            <Input
              value={value}
             
              onChange={searchByName}
              placeholder="Search candidate by name"
              className="px-3 my-2"
              style={{
                borderRadius: "4px",
                border: "1px solid #265BC4",
                width: "335px",
              }}
              suffix={
                <FontAwesomeIcon icon={faMagnifyingGlass} color="#265BC4" />
              }
              
            />
          </div>
        </div>
        <TableComp data={dataSource} footer='150 candidates found' columns={columns} />
      </div>
    </>
  );
}
