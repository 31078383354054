import React, { useState, useContext, useEffect, useRef } from "react";
import { Select, Input } from "antd";
import { Upload, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputField from "../Generic/InputField";
import { Avatar } from "@mui/material";
import "./addnewcandidate.css";
import SimpleModal from "../Modal";
import AppContext from "../../context/appState/AppContext";
import {
  useAddCandidateMutation,
  useGetAllCitiesMutation,
  useGetAllCountriesMutation,
} from "../../services/nodeAPI";
import { VariantType, useSnackbar } from "notistack";
import { XIcon } from "@heroicons/react/solid";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useLocation, Redirect } from "react-router-dom";
import WorkExpForm from "./WorkExpForm";

export default function AddNewCandidate(props) {
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const { parsedData } = location.state;
  // const parsedData = {
  //   FIRST_NAME: "Abdul",
  //   LAST_NAME: "Rehman",
  //   EMAIL: "abdul789150@gmail.com",
  //   PHONE_NUMBER: "+923359059199",
  //   SKILLS: ["python", "javascript", "nodejs"],
  //   degree: "Bachelor",
  // };

  const formRef = useRef(null);

  const [addCandidate] = useAddCandidateMutation();
  const [getAllCities] = useGetAllCitiesMutation();
  const [getAllCountries] = useGetAllCountriesMutation();

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const [data, setData] = useState({
    firstname: parsedData?.FIRST_NAME,
    lastname: parsedData?.LAST_NAME,
    email: parsedData?.EMAIL,
    linkedin_url: "",
    phone_number: parsedData?.PHONE_NUMBER,
    date_of_birth: "",
    gender: "",
    address: "",
    countryId: "",
    cityId: "",
    skills: parsedData?.SKILLS,
    education: "Bachelor",
    resume: parsedData?.resume,
    experience: parsedData?.ROLE_TITLE.map((role) => ({ role_title: role })),
    // experience: [
    //   { role_title: "Temp" },
    //   { role_title: "Temp1" },
    //   { role_title: "Temp2" },
    // ],
  });

  function handleFormData(key, value) {
    if (key === "countryId") {
      allCities(value);
    }

    setData({
      ...data,
      [key]: value,
    });
  }

  const [tags, setTags] = useState([...data.skills]);
  const [workExpData, setWorkExpData] = useState([]);
  const [workExpForm, setWorkExpForm] = useState([]);

  useEffect(() => {
    var expForms = [];
    for (var i = 0; i < data.experience.length; i++) {
      expForms.push(workExperienceForm(data.experience[i], i));
    }
    setWorkExpForm(expForms);
  }, []);

  const updateWorkData = (index, object) => {
    setWorkExpData(() => {
      workExpData[index] = object;
      return workExpData;
    });
  };

  const updateTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  const removeTags = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const allCountries = async () => {
    const { data } = await getAllCountries();
    setCountries(data.countryData);
  };
  const allCities = (id) => {
    getAllCities(id).then((res) => {
      console.log("Fetched All data");
      console.log(res.data.citiesData);
      setCities(res.data.citiesData);
    });
  };

  useEffect(() => {
    allCountries();
  }, [cities]);

  useEffect(() => {
    setTimeout(() => {
      handleFormData("skills", tags);
    }, 0);
  }, [tags]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     handleFormData("experience", workExpData);
  //   }, 0);
  // }, [workExpData]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [photoURL, setPhotoURL] = useState(null);
  const [realPhotoURL, setRealPhotoURL] = useState(null);
  const [file, setFile] = useState("");

  const [openCrop, setOpenCrop] = useState(false);

  const handleFileUpload = (e) => {
    const f = e.target.files[0];
    if (f) {
      setOpenCrop(true);
      setPhotoURL(URL.createObjectURL(f));
      setFile(f);
      showModal();
    }
  };

  const handleSubmit = async (e) => {
    // console.log(data);
    // console.log(
    //   workExpData.filter((item) => {
    //     if (!Object.values(item).some((item1) => item1 === undefined)) {
    //       return item;
    //     }
    //   })
    // );

    if (data.email === undefined && data.email === "" && data.email === " ") {
      enqueueSnackbar("Email is required!", { variant: "error" });
    } else if (
      data.linkedin_url === undefined ||
      data.linkedin_url === "" ||
      data.linkedin_url === " "
    ) {
      enqueueSnackbar("Linkedin URL is required!", { variant: "error" });
    } else if (
      data.firstname === undefined ||
      data.firstname === "" ||
      data.firstname === " "
    ) {
      enqueueSnackbar("First Name is required!", { variant: "error" });
    } else if (
      data.lastname === undefined ||
      data.lastname === "" ||
      data.lastname === " "
    ) {
      enqueueSnackbar("Last Name is required!", { variant: "error" });
    } else if (
      data.countryId === undefined ||
      data.countryId === "" ||
      data.countryId === " " ||
      data.cityId === undefined ||
      data.cityId === "" ||
      data.cityId === " "
    ) {
      enqueueSnackbar("Candidate's Country & City is required!", {
        variant: "error",
      });
    } else {
      e.preventDefault();
      const img = new File([file], `profile_img${Date.now()}`);
      let formData = new FormData();

      let finalWorkData = workExpData.filter((item) => {
        if (!Object.values(item).some((item1) => item1 === undefined)) {
          return item;
        }
      });

      formData.append("firstname", data.firstname);
      formData.append("lastname", data.lastname);
      formData.append("email", data.email);
      formData.append("phone_number", data.phone_number);
      formData.append("date_of_birth", data.date_of_birth);
      formData.append("countryId", data.countryId);
      formData.append("cityId", data.cityId);
      formData.append("gender", data.gender);
      formData.append("linkedin_url", data.linkedin_url);
      formData.append("profile_img", img);
      formData.append("resume", data.resume);
      formData.append("education", data.education);
      formData.append("experience", JSON.stringify(finalWorkData));
      formData.append("skills", data.skills);
      // console.log(workExpData);

      addCandidate(formData).then((response) => {
        if (response.data.success) {
          enqueueSnackbar("Candidate added successfully!", {
            variant: "success",
          });
          window.location.reload(true);
          // formRef.current.reset();
        } else {
          enqueueSnackbar(response.data.message, {
            variant: "error",
          });
        }
      });
    }
  };

  function addWorkExperienceForm() {
    setWorkExpForm([...workExpForm, workExperienceForm({})]);
    // setWorkExpData([...workExpData, {}]);
  }

  function workExperienceForm(previousData, form_index) {
    var curr_index = workExpForm.length;
    if (form_index !== null && form_index !== undefined) {
      curr_index = form_index;
    }
    return (
      <WorkExpForm
        index={curr_index}
        updateWorkExpData={updateWorkData}
        candidateData={previousData}
      />
    );
  }

  function removeWorkExperienceForm(indexToRemove) {
    setWorkExpForm(workExpForm.filter((_, index) => index !== indexToRemove));
    setWorkExpData(workExpData.filter((_, index) => index !== indexToRemove));
  }

  return (
    <>
      <div className="px-2">
        <div className="flex flex-wrap mb-3">
          <div className="w-100 mt-2">
            <span className="text-2xl dark:text-white font-medium dark:text-white">
              Add new Candidate
            </span>
            <p className="text-sm dark:text-white font-normal text-gray-400 dark:text-white">
              In this section you can add new candidates data, you can add
              single or multiple candidates data.
            </p>
          </div>
        </div>

        {/* <form > */}

        <div className="my-3 pt-2">
          <span style={{ fontSize: "20px", fontWeight: "500" }}>
            Candidate Details
          </span>
          <p className="text-sm dark:text-white font-normal text-gray-400 dark:text-white">
            All fields marked with * are required
          </p>
        </div>

        <div className="gap-3 flex flex-wrap">
          <div>
            <div className="gap-3 flex flex-wrap">
              <div className="">
                <div className="">
                  <InputField
                    label="First Name *"
                    name="firstname"
                    onChange={(event) =>
                      handleFormData(event.target.name, event.target.value)
                    }
                    placeholder="First Name"
                    type="text"
                    defaultValue={data.firstname}
                  />
                </div>
              </div>
              <div className="">
                <div className="">
                  <InputField
                    label="Last Name *"
                    name="lastname"
                    onChange={(event) =>
                      handleFormData(event.target.name, event.target.value)
                    }
                    placeholder="Last Name"
                    type="text"
                    defaultValue={data.lastname}
                  />
                </div>
              </div>
            </div>
            <div className="gap-3 flex flex-wrap mt-3">
              <div className="">
                <div className="">
                  <InputField
                    label="Email *"
                    name="email"
                    onChange={(event) =>
                      handleFormData(event.target.name, event.target.value)
                    }
                    placeholder="Email"
                    type="email"
                    defaultValue={data.email}
                  />
                </div>
              </div>
              <div className="">
                <div className="">
                  <InputField
                    label="LinkedIn Profile Url *"
                    onChange={(event) =>
                      handleFormData(event.target.name, event.target.value)
                    }
                    name="linkedin_url"
                    type="text"
                    placeholder="LinkedIn profile url"
                    defaultValue={data.linkedin_url}
                  />
                  <p
                    id="helper-text-explanation"
                    class="mt-2 text-xs text-gray-500 dark:text-gray-400"
                  >
                    Provide Linkedin Profile Handle.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="gap-3 flex lg:w-4/12">
            <div className="">
              <label
                for="profileImage"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Profile Image
              </label>
              <div className="p-2">
                <Avatar
                  alt="Candidate Avatar"
                  src={realPhotoURL}
                  sx={{ width: 100, height: 100 }}
                  style={{ marginTop: "" }}
                />
              </div>
            </div>
            <div className="relative pt-16">
              <Input
                className="z-10 border relative opacity-0 w-50"
                onChange={handleFileUpload}
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
              />
              <button
                type="button"
                class="text-white absolute left-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-normal rounded text-sm px-4 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Upload Image
              </button>
            </div>

            {
              <SimpleModal
                handleOk={handleOk}
                handleCancel={handleCancel}
                isModalVisible={isModalVisible}
                realPhotoURL={realPhotoURL}
                setRealPhotoURL={setRealPhotoURL}
                photoURL={photoURL}
                setPhotoURL={setPhotoURL}
                setOpenCrop={setOpenCrop}
                setFile={setFile}
              />
            }
          </div>
        </div>

        <div className="gap-3 flex flex-wrap xl:w-10/12 2xl:w-8/12 mt-3">
          <div className="w-72">
            <InputField
              label="Phone Number"
              name="phone_number"
              onChange={(event) =>
                handleFormData(event.target.name, event.target.value)
              }
              placeholder="Phone Number"
              type="text"
              width={"100%"}
              height="32px"
              defaultValue={data.phone_number}
            />
          </div>
          <div className="w-72">
            <label
              for="gender"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Select Gender
            </label>
            <select
              id="gender"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(event) =>
                handleFormData(event.target.name, event.target.value)
              }
              name="gender"
            >
              <option selected>Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <div className="w-72">
            <div className="">
              <label
                for="dob"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Date Of Birth
              </label>
              <DatePicker
                id="dob"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                name="date_of_birth"
                onChange={(_, dateString) =>
                  handleFormData("date_of_birth", dateString)
                }
                defaultValue={data.date_of_birth}
              />
            </div>
          </div>
          <div className="w-72">
            <label
              for="country"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Select Country
            </label>
            <select
              id="country"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(event) =>
                handleFormData(event.target.name, event.target.value)
              }
              name="countryId"
            >
              <option selected>Select Country</option>
              {countries.map((el, i) => {
                return (
                  <option key={i} value={el.id}>
                    {el.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="w-72">
            <label
              for="city"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Select City
            </label>
            <select
              id="city"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(event) =>
                handleFormData(event.target.name, event.target.value)
              }
              name="cityId"
            >
              <option selected value="none">
                Select City
              </option>
              {cities.map((el, i) => {
                return (
                  <option key={i} value={el.id}>
                    {el.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="w-72">
            <InputField
              label="Highest Education"
              name="education"
              onChange={(event) =>
                handleFormData(event.target.name, event.target.value)
              }
              placeholder="Bachelors, Masters, etc..."
              type="text"
              defaultValue={data.education}
            />
          </div>
        </div>

        <div className="row mt-3 lg:w-11/12 xl:w-8/12 2xl:w-6/12">
          <div className="">
            <label
              for="address"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Candidate's Skills
            </label>

            <div className="flex flex-wrap w-100 border-1 rounded-lg p-2 hover:border-blue-600 focus:border-blue-600 ">
              <ul id="" className="flex flex-wrap space-x-2">
                {tags.map((tag, index) => (
                  <li
                    key={index}
                    className="flex block bg-blue-100 py-1.5 px-2.5 text-center text-blue-800 rounded-xl mt-2"
                  >
                    <span className="font-normal text-sm">{tag}</span>
                    <XIcon
                      className="h-4 w-4 ms-1 mt-0.5 cursor-pointer"
                      aria-hidden="true"
                      onClick={() => removeTags(index)}
                    />
                  </li>
                ))}
              </ul>
              <input
                type="text"
                placeholder="Press Enter to add skills..."
                className="border-transparent focus:border-transparent focus:ring-0 text-sm block focus:outline-none"
                onKeyUp={(event) =>
                  event.key === "Enter" ? updateTags(event) : null
                }
              />
            </div>
          </div>
        </div>

        {/* 
            ////////////////////////////////////////////
            ///////////////////////////////////////////
          */}

        <div className="my-3 pt-2 lg:w-8/12 xl:w-7/12 2xl:w-6/12">
          <div style={{ fontSize: "20px", fontWeight: "500" }}>
            Work Experience
          </div>

          <div>
            {workExpForm.map((expForm, index) => (
              <div key={index}>
                {expForm}

                <div
                  className="flex justify-content-start cursor-pointer"
                  onClick={() => removeWorkExperienceForm(index)}
                >
                  <span className="text-red-600">
                    <FontAwesomeIcon icon={faMinus} />{" "}
                    <span className="ms-2">Remove</span>
                  </span>
                </div>
              </div>
            ))}
          </div>

          <div
            className="flex justify-content-end cursor-pointer"
            onClick={addWorkExperienceForm}
          >
            <span className="text-blue-600">
              <FontAwesomeIcon icon={faPlus} />{" "}
              <span className="ms-2">Add Work Experience</span>
            </span>
          </div>
        </div>

        {/* 
            //////////////////////////////////////////
            /////////////////////////////////////////
          */}

        <div className="flex">
          <button
            onClick={handleSubmit}
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 mr-2 mb-2 mt-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Save Candidate Data
          </button>
        </div>
      </div>
    </>
  );
}
