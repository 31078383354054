import React, { useState, useContext, useRef, useEffect } from "react";
import { Button, Select, Pagination, Input } from "antd";
import AddEmployeeCard from "./AddEmployeeCard";
import "./addEmployee.css";
import InputField from "../Generic/InputField";
import Avatar from "@mui/material/Avatar";
import {
  useGetEmployeeWithIDQuery,
  useGetRolesQuery,
  useCountRolesQuery,
  useUpdateEmployeeMutation,
} from "../../services/nodeAPI";
import { Spin, Alert } from "antd";
import { Form } from "antd";
import SimpleModal from "../Modal";
import AppContext from "../../context/appState/AppContext";
import { VariantType, useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setEmployeeData } from "../../redux/employee";
import Spinner from "../Spinner";

export default function UpdateEmployees() {
  const [updateEmployee] = useUpdateEmployeeMutation();
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);
  const [itemLimit, setitemLimit] = useState(5);
  // Calling API to get all roles
  const {
    data: rolesData,
    error,
    isLoading: rolesLoading,
  } = useGetRolesQuery({
    itemLimit,
    pageNumber,
  });

  const roles = !rolesLoading && rolesData.roles;

  const { emp_id } = useSelector((state) => state.emp);

  const {
    data: EmpData,
    error: empError,
    isLoading: empDataLoading,
  } = useGetEmployeeWithIDQuery(emp_id);

  var { data: totalRoles } = useCountRolesQuery();

  console.log(!empDataLoading && EmpData);

  if (!empDataLoading) {
    dispatch(setEmployeeData(EmpData.employeeData));
  }

  const { employeeData } = useSelector((state) => state.emp);

  const { onChangeGeneric } = useContext(AppContext);

  const [isModalVisible, setIsModalVisible] = useState(false); //state of modal of img Cropper

  const [employeeCreds, setEmployeeCreds] = useState({
    id: employeeData && employeeData.id,
    roleId: employeeData && employeeData.roleId,
    firstname: employeeData && employeeData.firstname,
    lastname: employeeData && employeeData.lastname,
    email: employeeData && employeeData.email,
    username: employeeData && employeeData.username,
    phone_number: employeeData && employeeData.phone_number,
    address: employeeData && employeeData.address,
  }); // FORM Credentials

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const formRef = useRef(null);

  // MODAL FUNCTIONS
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // IMG Cropper configurations/funcyions
  const [photoURL, setPhotoURL] = useState(null);
  const [realPhotoURL, setRealPhotoURL] = useState(null);
  const [file, setFile] = useState("");

  const [openCrop, setOpenCrop] = useState(false);

  const handleFileUpload = (e) => {
    console.log(e.target.files[0]);
    const f = e.target.files[0];
    if (f) {
      setOpenCrop(true);
      setPhotoURL(URL.createObjectURL(f));
      setFile(f);
      showModal();
    }
  };

  // ONCHANGE FUNCTION for form
  const onChange = onChangeGeneric(employeeCreds, setEmployeeCreds);

  // HELPER FUNCTIONS
  const convertToUnifiedString = (arr) => {
    const newArr = arr.map((el) => el.split(" ")[1]);
    const set = new Set(newArr);

    const CommonArray = [...set];
    return CommonArray.join(", ");
  };

  function capitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function prevPageData() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  function nextPageData() {
    var maxRoles = totalRoles.roles[0].count;
    if (itemLimit * pageNumber < maxRoles) {
      setPageNumber(pageNumber + 1);
    }
  }

  //******** SUBMIT Create Employee FORM
  const handleSubmit = async (e) => {
    setLoading(true);

    const img = new File([file], `employee_img-${Date.now()}`);
    let formData = new FormData();

    formData.append("id", employeeCreds.id);
    formData.append("firstname", employeeCreds.firstname);
    formData.append("lastname", employeeCreds.lastname);
    formData.append("email", employeeCreds.email);
    formData.append("username", employeeCreds.username);
    formData.append("phone_number", employeeCreds.phone_number);
    formData.append("address", employeeCreds.address);
    formData.append("roleId", employeeCreds.roleId);
    if (file) {
      formData.append("profile_img", img);
    }

    const res = await updateEmployee(formData);
    console.log(res);

    if (res.data.success) {
      setLoading(false);

      enqueueSnackbar(res.data.message, { variant: "success" });
    } else {
      setLoading(false);
      enqueueSnackbar(res.data.message, { variant: "error" });
    }
  };

  return (
    <>
      {rolesLoading || empDataLoading || !employeeData ? (
        // <Spin tip="Loading..."></Spin>

        <Spinner marginTop="14rem" />
      ) : (
        <Form
          className=" my-4 px-3"
          ref={formRef}
          initialValues={{
            ["firstname"]: employeeData.firstname,
            ["lastname"]: employeeData.lastname,
            ["email"]: employeeData.email,
            ["username"]: employeeData.username,
            ["address"]: employeeData.address,
            ["phone_number"]: employeeData.phone_number,
          }}
        >
          <div className="row">
            <p className="text-2xl dark:text-white font-medium dark:text-white">
              Update Employee Data
            </p>
          </div>

          <div className="row">
            <p className="text-lg text-gray-500 dark:text-white font-medium dark:text-white">
              Choose Employee Role
            </p>
          </div>
          <div className="gap-3 flex flex-wrap">
            {/* EMPLOYEE CURRENT ROLE */}
            {roles
              .filter((role) => role.roleid === employeeData.roleId)
              .map((el) => {
                const text = `${capitalizeString(
                  el.rolename
                )}, responsible for managing ${convertToUnifiedString(
                  el.permissions
                )}.`;
                const heading = `${capitalizeString(el.rolename)}`;

                return (
                  <div className="w-72">
                    <AddEmployeeCard
                      heading={heading}
                      checked
                      text={text}
                      key={el.roleid}
                      name="roleId"
                      value={el.roleid}
                      onChange={onChange}
                    />
                  </div>
                );
              })}
            {/* EMPLOYEE CURRENT ROLE */}

            {roles
              .filter((role) => role.roleid !== employeeData.roleId)
              .map((el) => {
                const text = `${capitalizeString(
                  el.rolename
                )}, responsible for managing ${convertToUnifiedString(
                  el.permissions
                )}.`;
                const heading = `${capitalizeString(el.rolename)}`;

                return (
                  <div className="w-72">
                    <AddEmployeeCard
                      heading={heading}
                      text={text}
                      key={el.roleid}
                      name="roleId"
                      value={el.roleid}
                      onChange={onChange}
                    />
                  </div>
                );
              })}
          </div>
          <div className="flex flex-col items-center">
            <div className="inline-flex mt-4">
              {/* <!-- Buttons --> */}
              <button
                class="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-700 bg-gray-200 rounded-l hover:bg-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={prevPageData}
              >
                <svg
                  class="mr-2 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Prev
              </button>
              <button
                class="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-700 bg-gray-200 rounded-r border-0 border-l border-gray-700 hover:bg-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={nextPageData}
              >
                Next
                <svg
                  class="ml-2 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
              {/* <!-- Help text --> */}
            </div>
            <span class="text-sm text-gray-700 dark:text-gray-400">
              Showing{" "}
              <span class="font-semibold text-gray-900 dark:text-white">
                {itemLimit * pageNumber - (itemLimit - 1)}
              </span>{" "}
              to{" "}
              <span class="font-semibold text-gray-900 dark:text-white">
                {itemLimit * pageNumber}
              </span>{" "}
              of{" "}
              <span class="font-semibold text-gray-900 dark:text-white">
                {totalRoles?.roles[0].count}
              </span>{" "}
              Roles
            </span>
          </div>
          <hr className="my-4" />
          <div className="row">
            <div className="col-12">
              <h5 className="text-xl text-dark dark:text-white font-medium dark:text-white">
                Employee Details
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="w-full gap-3 flex flex-wrap">
              <div className="gap-3 flex flex-wrap w-full md:w-6/12">
                <div className="mb-3 w-72">
                  <InputField
                    label="First Name"
                    placeholder="First Name"
                    type="text"
                    height="32px"
                    rules={[
                      { required: true, message: "Please enter firstname!" },
                    ]}
                    defaultValue={employeeData.firstname}
                    name="firstname"
                    required={true}
                    onChange={onChange}
                  />
                </div>
                <div className="">
                  <div className="mb-3 w-72">
                    <InputField
                      label="Last Name"
                      placeholder="Last Name"
                      type="text"
                      height="32px"
                      rules={[
                        { required: true, message: "Please enter lastname!" },
                      ]}
                      defaultValue={employeeData.lastname}
                      required={true}
                      name="lastname"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <div className="w-72">
                    <InputField
                      label="Email"
                      placeholder="Email"
                      type="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter valid email!",
                          type: "email",
                        },
                      ]}
                      defaultValue={employeeData.email}
                      name="email"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="mb-3 w-72">
                    <InputField
                      label="Username"
                      placeholder="Username"
                      type="text"
                      height="32px"
                      rules={[
                        { required: true, message: "Please enter username!" },
                      ]}
                      defaultValue={employeeData.username}
                      required={true}
                      name="username"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="">
                <div className="mb-3">
                  <div className="">
                    <div className="">
                      <label class="block text-sm font-medium text-gray-900 dark:text-gray-400">
                        Profile Image
                      </label>
                      <div className="p-2">
                        <Avatar
                          alt="Remy Sharp"
                          src={`https://www.meerkatsearch.com/${employeeData.profile_img}`}
                          sx={{ width: 100, height: 100 }}
                          style={{ marginTop: "" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="gap-3 flex flex-wrap">
            <div className="w-72">
              <span>Address</span>

              <Form.Item
                name="address"
                className="my-2"
                rules={[
                  {
                    required: true,
                    message: "Please enter address",
                  },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={100}
                  rows={5}
                  name="address"
                  onChange={onChange}
                  placeholder="Add employee address.."
                />
              </Form.Item>
            </div>
            <div className="w-72">
              <InputField
                label="Phone Number"
                placeholder="Phone no"
                type="text"
                rules={[
                  { required: true, message: "Please enter valid phone no!" },
                ]}
                defaultValue={employeeData.phone_number}
                name="phone_number"
                onChange={onChange}
              />
            </div>
          </div>
          <div className="ms-auto text-end my-5" style={{ width: "50%" }}>
            {/* <button type="submit" className="px- btn" style={{ backgroundColor: '#265BC4', color: 'white', borderRadius: '4px', marginBottom: "1rem" }}>
          Save Employee Data
            </button> */}

            <LoadingButton
              size="small"
              loading={loading}
              loadingPosition="end"
              variant="contained"
              className="btn save_emp_data_btn "
              type="button"
              onClick={handleSubmit}
            >
              Update Employee Data
            </LoadingButton>
          </div>
        </Form>
      )}
    </>
  );
}
