import { Empty } from 'antd'
import { TbSearch } from 'react-icons/tb'
import { useEffect, useState } from 'react'
import { BiMenuAltLeft } from 'react-icons/bi'
import ScraperAPI from '../../services/ScraperAPI'
import CandidateAPI from '../../services/CandidateAPI'
import { MdTitle, MdLocationOn } from "react-icons/md";
import { TbLoader3, TbCalendarStats } from 'react-icons/tb';
import CustomSpinner from '../components/spinner/CustomSpinner'
import SmallCardList from '../components/crawlerSearch/SmallCardList';
import StaticFilter from '../components/crawlerSearch/filter/StaticFilter'
import CandidateDetails from '../components/crawlerSearch/CandidateDetails'
import SingleSelectListBox from '../components/listBox/SingleSelectListBox'
import DynamicFilter from '../components/crawlerSearch/filter/DynamicFilter'

export default function CrawlerSearch({
    socketConnection,
    createSocketConnection,
    disconnectSocketConnection
}) {
    const [error, setError] = useState('');
    const [result, setResult] = useState('');
    const [filters, setFilters] = useState({'job_title': [], 'location': [], 'experience': [], 'skills': []});
    const [isWorking, setIsWorking] = useState(false)
    const [candidatesData, setCandidatesData] = useState([])
    const [candidatesFound, setCandidatesFound] = useState()
    const [scrapedCandidateId, setScrapedCandidateId] = useState(undefined);
    const [selectedCandidateDetails, setSelectedCandidateDetails] = useState(undefined);

    // const job_type_items = ['Full-Time', 'Part-Time', 'Contract', 'Internship']
    // const workplace_type_items = ['Onsite', 'Remote', 'Hybrid']
    const exp_items = ['1 year', '3 years', '5 years', '7 years', '10 years']

    useEffect(() => {
        if (result?.candidate_id) {
            setScrapedCandidateId(result?.candidate_id)
        } else if (result?.found) {
            setCandidatesFound(result?.found)
        }
    }, [result])

    useEffect(() => {
        console.log(error)
    }, [error])

    useEffect(() => {
        if (scrapedCandidateId !== undefined) {
            fetchCandidate(scrapedCandidateId)
        }
    }, [scrapedCandidateId])

    useEffect(() => {
        if (socketConnection !== undefined){
            // listen for the 'requestId' event
            socketConnection.on('connect', async () => {
                // updateFilters('socketId', socketConnection.id)
                setIsWorking(true)
                setCandidatesData([])
                setSelectedCandidateDetails(undefined)

                await ScraperAPI.saveCrawlerData({...filters, ['socketId']:socketConnection.id})
            });
        
            // listen for the 'result' event with the request ID
            socketConnection.on('message', (data) => {
                setResult(JSON.parse(data))
            });
      
            // clean up the event listeners
            return () => {
                socketConnection.off('connect');
                socketConnection.off('message');
                socketConnection.off('error');
            };
        }
    }, [socketConnection])

    const updateFilters = (key, value) => {
        setFilters({...filters, [key]: value})
    }

    const publishFiltersToCrawler = async () => {
        if(socketConnection !== undefined){
            disconnectSocketConnection()
        }
        createSocketConnection()
    }

    const fetchCandidate = (candidateId) => {
        CandidateAPI.getCandidateDataV2(candidateId).then((data) => {
            setCandidatesData([...candidatesData, data])
        }).catch((error) => {
            console.log(error)
        })
    }

    const updateCandidateSelection = (candidateId) => {
        CandidateAPI.getCandidateDataV2(candidateId).then((data) => {
            setSelectedCandidateDetails(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <div className=''>
            <div className='mt-2'>
                <span className='text-2xl font-medium'>
                    Search Filters
                </span>
            </div>
            <div className='mt-3 gap-2 flex flex-wrap h-28 mr-4 overflow-scroll no-scrollbar'>
                <DynamicFilter 
                    icon={<MdTitle className='h-5 w-5 text-gray-500'/>}
                    label={'Job Title'}
                    name={'job_title'}
                    updateFilter={updateFilters}
                />
                <DynamicFilter 
                    icon={<MdLocationOn className='h-5 w-5 text-gray-500'/>}
                    label={'Location'}
                    name={'location'}
                    updateFilter={updateFilters}
                />
                <DynamicFilter
                    icon={<TbLoader3 className='h-5 w-5 text-gray-500'/>}
                    label={'Skills'}
                    name={'skills'}
                    updateFilter={updateFilters}                    
                />                
                <StaticFilter 
                    icon={<TbCalendarStats className='h-5 w-5 text-gray-500'/>}
                    label={'Experience'}
                    filter_items={exp_items}                    
                    name={'experience'}
                    updateFilter={updateFilters}
                />
                {/* <StaticFilter
                    icon={<GiSandsOfTime className='h-5 w-5 text-gray-500'/>}
                    label={'Job Type'}
                    filter_items={job_type_items}
                    name={'job_type'}
                    updateFilter={updateFilters}
                />
                <StaticFilter
                    icon={<MdWork className='h-5 w-5 text-gray-500'/>}
                    label={'Workplaces Type'}
                    filter_items={workplace_type_items}
                    name={'workplace_type'}
                    updateFilter={updateFilters}
                /> */}
                <div>
                    <button
                        type='button'
                        className='flex flex-row space-x-1 border border-gray-200 text-black rounded-md px-3 h-11 items-center hover:bg-gray-100'
                        onClick={publishFiltersToCrawler}
                    >
                        <TbSearch className='w-5 h-5' />
                        <span className='text-base font-medium'>Search</span>
                    </button>
                </div>
            </div>

            <div className='mt-2 flex flex-row justify-between space-x-4 pr-4'>
                {/* Candidates Small Card Bar */}
                {
                    candidatesData?.length > 0 ? (
                        <div className='w-3/12 max-h-[41rem]'>
                            <div className='flex flex-row justify-between px-2 items-center'>
                                <div className='flex items-center'>
                                    <BiMenuAltLeft className='w-8 h-8' />
                                    <SingleSelectListBox 
                                        filter_label={'Database'} 
                                        filter_id={'search-items-toggle'} 
                                        filter_data={[{id: 1, text: 'Database'}, {id:2, text: 'Crawl'}]}                                        
                                        btn_className={'focus:outline-none relative w-36 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left sm:text-sm'}
                                        opt_className={'focus:outline-none absolute max-h-60 w-36 overflow-auto rounded-md bg-white py-1 text-base shadow-sm ring-1 ring-black ring-opacity-5 sm:text-sm border z-10'}
                                    />
                                </div>
                                <div>
                                    {
                                        candidatesFound !== undefined ? (
                                            <span className='font-normal'>{candidatesFound} Candidates Found</span>
                                        ) : (<></>)
                                    }
                                </div>
                            </div>
                            <SmallCardList 
                                candidates={candidatesData} 
                                candidatesFound={candidatesFound} 
                                updateCandidateSelection={updateCandidateSelection} 
                            />
                        </div>                        
                    ) : (
                        <div className='w-11/12 flex flex-row justify-center mt-36'>
                            {
                                isWorking ? (
                                    <CustomSpinner size={10} helperText={'Please Wait...'} />
                                ) : (
                                    <Empty className="" />
                                )
                            }
                        </div>
                    )
                }
                {
                    selectedCandidateDetails !== undefined ? (
                        <div className='w-9/12 border rounded-md h-[44rem] p-4 overflow-scroll no-scrollbar '>
                            <CandidateDetails candidateData={selectedCandidateDetails} />
                        </div>
                    ) : (<></>)
                }
            </div>
        </div>
    )
}