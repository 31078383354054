import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Avatar from '../avatar/Avatar';
import { TfiLinkedin } from 'react-icons/tfi'
import { HiOutlineMail } from 'react-icons/hi'
import { FaGraduationCap } from 'react-icons/fa'
import { MdTitle, MdLocationOn, MdOutlineWorkHistory } from "react-icons/md";
import { isStringEmpty, setupDate } from '../../../lib/helper'

const CandidateDetails = ({
    candidateData,
    className,
    ...divProps 
}) => {

    const [skills, setSkills] = useState([])
    const [workExp, setWorkExp] = useState([])
    const [basicInfo, setBasicInfo] = useState({})
    const [education, setEducation] = useState([])

    useEffect(() => {
        setSkills(candidateData?.skills)
        setWorkExp(candidateData?.work_exp)
        setEducation(candidateData?.education)
        setBasicInfo(candidateData?.basic_info)
    }, [candidateData])

    return (
        <div className={`${className}`} {...divProps}>
            <div className='flex flex-row space-x-6 items-center'>
                {/* Avatar - Image */}
                <div className=''>
                    {/* Image */}
                    <Avatar
                        className='w-36 h-36'
                        profileData={basicInfo}
                    />
                </div>
                <div className='flex flex-col space-y-4'>
                    <div>
                        {/* Full Name */}
                        <span className='font-semibold text-3xl'>{basicInfo?.firstname} {basicInfo?.lastname}</span>
                    </div>
                    <div>
                        <div className='flex flex-row flex-wrap gap-4'>
                            {/* Linkedin Profile Button */}
                            <a 
                                href={basicInfo?.linkedin_url}
                                target="_blank"
                                type='button'
                                className='flex flex-col group cursor-pointer'
                            >
                                <div                                   
                                    className='w-max border rounded-full p-3 text-gray-900 group-hover:bg-black group-hover:text-white'
                                >
                                    <TfiLinkedin className='w-5 h-5' />
                                    {/* <span className='text-sm font-medium'>LinkedIn Profile</span> */}
                                </div>
                                <div className='flex flex-col text-sm mt-1.5'>
                                    <span className='capitalize font-semibold text-black'>Check Profile</span>
                                    <span className='font-normal text-gray-700'>LinkedIn Profile</span>
                                </div>
                            </a>
                            {/* Location */}
                            {
                                ((isStringEmpty(basicInfo?.city) === true) && (isStringEmpty(basicInfo?.country))) === true ? (
                                    <></>
                                ) : (
                                    <div className='flex flex-col space-x-2 justify-start'>
                                        <div className='rounded-full w-max border p-3'>
                                            <MdLocationOn className='w-5 h-5' />
                                        </div>
                                        <div className='flex flex-col text-sm mt-1.5'>
                                            <span className='capitalize font-semibold text-black'>{basicInfo?.city}, {basicInfo?.country}</span>
                                            <span className='font-normal text-gray-700'>Location</span>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                isStringEmpty(basicInfo?.email) === true ? (
                                    <></>
                                ) : (
                                    <div className='flex flex-col space-x-2 justify-start'>
                                        <div className='border rounded-full w-max p-3'>
                                            <HiOutlineMail className='w-5 h-5'/>
                                        </div>
                                        <div className='flex flex-col text-sm mt-1.5'>
                                            <span className='font-semibold text-black'>{basicInfo?.city}</span>
                                            <span className='font-normal text-gray-700'>Email Address</span>
                                        </div>
                                    </div>
                                )
                            }
                            {/* Years of Experience */}
                            <div className='flex flex-col space-x-2 justify-start'>
                                <div className='rounded-full w-max border p-3'>
                                    <MdOutlineWorkHistory className='w-5 h-5' />
                                </div>
                                <div className='flex flex-col text-sm mt-1.5'>
                                    <span className='capitalize font-semibold text-black'>
                                        { basicInfo?.experience?.years }
                                        { basicInfo?.experience?.years > 1 ? <> Years</>:<>Year</>} 
                                    </span>
                                    <span className='font-normal text-gray-700'>Experience</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col space-y-2 mt-4'>
                {/* Current Role / Job Title */}
                {
                    workExp?.length > 0 ? (
                        <div className='flex flex-row space-x-2 items-center'>
                            <div className='border rounded-full w-max p-3'>
                                <MdTitle className='w-5 h-5'/>
                            </div>
                            <div className='flex flex-col text-sm mt-1.5'>
                                <span className='font-semibold text-black'>{workExp[0]?.position}</span>
                                <span className='font-normal text-gray-700'>Job Title</span>
                            </div>
                        </div>
                    ) : ( <></> )
                }
                {/* Highest Education */}
                {
                    education?.length > 0 ? (
                        <div className='flex flex-row space-x-2 items-center'>
                            <div className='border rounded-full w-max p-3'>
                                <FaGraduationCap className='w-5 h-5'/>
                            </div>
                            <div className='flex flex-col text-sm mt-1.5'>
                                <span className='font-semibold text-black capitalize'>
                                    { education[0]?.type ? <>{education[0]?.type}, </> : <></>} {education[0]?.title}
                                </span>
                                <span className='font-normal text-gray-700'>Education</span>
                            </div>
                        </div>
                    ) : (<></>)
                }
            </div>
            {/* Summary */}
            {
                isStringEmpty(basicInfo?.summary) !== true ? (
                    <div className='text-sm text-left whitespace-pre-wrap mt-4'>
                        <span className='text-2xl font-medium text-gray-600'>Summary</span>
                        <p className='mt-2'>
                            {basicInfo?.summary}
                        </p>
                    </div>
                ) : <></>
            }
            {/* Experiences */}
            <div className='flex flex-col w-full mt-4'>
                <span className='text-2xl font-medium text-gray-600'>Work Experience</span>
                {
                    workExp?.map((item, index) => {
                        return (
                            <div key={index} className='flex flex-col w-full mt-2'>
                                <div className='flex flex-row justify-between'>
                                    <div className='flex flex-col'>
                                        <span className='text-base font-semibold text-gray-900'>{item?.position}</span>
                                        <span className='text-base font-medium text-gray-600'>{item?.company_name}</span>
                                    </div>
                                    <div className=''>
                                        <span className='text-normal font-semibold text-gray-800'>{setupDate(item?.join_date, item?.end_date)}</span>
                                    </div>
                                </div>
                                <div className='text-sm text-left mt-1.5 whitespace-pre-wrap'>
                                    <p>{item?.summary}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>        
    );
};

export default CandidateDetails;

CandidateDetails.defaultProps = {};
CandidateDetails.propTypes = {
    candidateData: PropTypes.object,
    className: PropTypes.string,
};
