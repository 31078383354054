import React from "react";
import "./addEmployeeCard.css";

export default function AddEmployeeCard(props) {
  return (
    <>
      <div
        onClick={() => {
          console.log(`Role id: ${props.value} Clicked`);
        }}
        className={`me-4 px-3 pt-3 pb-3 cursor-pointer shadow-md w-full h-24 block bg-white rounded-lg border-1 hover:border-blue-500 ${
          props.checked ? "border-blue-500" : null
        }`}
      >
        <div className="d-flex space-x-4">
          <div className="mt-3">
            {props.checked ? (
              <input
                className="rad_io"
                type="radio"
                checked
                name={props.name}
                value={props.value}
                onChange={props.onChange}
              />
            ) : (
              <input
                className="rad_io"
                type="radio"
                name={props.name}
                value={props.value}
                onChange={props.onChange}
              />
            )}
          </div>
          <div className="">
            <span className="text-md font-medium">{props.heading}</span>
            <div className="">
              <p className="text-xs text-gray-400 line-clamp-2">{props.text}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
