import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useGetJobQuery, usePublishJobMutation } from "../../services/nodeAPI";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setJobId, setPhaseDefault } from "../../redux/jobSlice";
import { useSnackbar } from "notistack";

export default function Review({ moveBack, navigation }) {
  const id = useSelector((state) => state.job.jobId);

  const { data, error, isLoading, isSuccess } = useGetJobQuery(id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [publishJob] = usePublishJobMutation();

  const handlePublish = async (e) => {
    e.preventDefault();
    const res = await publishJob({ id: id });
    console.log(res);
    dispatch(setPhaseDefault());
    enqueueSnackbar(res.data.message, { variant: "success" });
    dispatch(setJobId(null));
    navigate("/dashboard/jobs");
  };

  const handleExit = (e) => {
    e.preventDefault();
    dispatch(setPhaseDefault());
    enqueueSnackbar("Saved", { variant: "success" });
    dispatch(setJobId(null));
    navigate("/dashboard/jobs");
  };

  return isLoading ? (
    <div className="flex justify-center px-10 py-10">
      <Spin tip="Loading..." />
    </div>
  ) : (
    <>
      <div className="px-4 py-4">
        {/* <div className="p-5 flex-grow-1 bd-highlight" style={{border:'1px solid #E2E2E2', borderRadius: '8px'}}> */}
        {console.log(data)}
        <div>
          <h4 className="font-medium text-2xl">
            {" "}
            {data.job_details[0]?.title}
          </h4>
        </div>
        <div className="my-3">
          <h5 className="font-medium text-lg">Job Description</h5>
        </div>

        <p className="text-left">{data.job_details[0]?.description}</p>

        <div className="my-3">
          <h5 className="font-medium text-lg">Responsibilities</h5>
        </div>
        <ul className="list-disc text-left ml-5">
          {data.job_details[0]?.responsibilites?.split(".").map((resp) => {
            if (resp !== "" && resp !== " ") {
              return <li>{resp}</li>;
            }
          })}
        </ul>

        <div className="my-3">
          <h5 className="font-medium text-lg">Required Qualification</h5>
        </div>

        <ul className="list-disc text-left ml-5">
          {data.job_details[0]?.skills?.map((e, i) => {
            return <li key={i}>{e}</li>;
          })}
        </ul>

        <div className="flex justify-content-end mt-10 mb-3">
          {/* <div>
              <Button className="px-5" onClick={moveBack} style={{color:'#265BC4', border: '1px solid #265BC4'}}>Back</Button>
            </div> */}
          <div>
            {/* <Button
            className="px-5 mx-4"
            style={{ color: "#265BC4", border: "1px solid #265BC4" }}
            onClick={handleExit}
          >
            Exit
          </Button> */}
            <button
              onClick={handleExit}
              class="py-2 px-3 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
            >
              Save & Exit
            </button>
          </div>
          <div>
            <button
              type="submit"
              onClick={handlePublish}
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2 text-center inline-flex items-center"
            >
              Publish Job
            </button>
            {/* <Button
            className="text-white px-5"
            onClick={handlePublish}
            style={{
              backgroundColor: "#265BC4",
              boxShadow: "3px 3px 6px #00000029",
              borderRadius: "4px",
            }}
          >
            Publish
          </Button> */}
          </div>
        </div>

        {/* </div> */}
      </div>
    </>
  );
}

//Full stack developer

// Businesses that partner with Google come in all shapes, sizes and market caps, and no one Google advertising solution works for all. Your knowledge of online media combined with your communication skills and analytical abilities shapes how new and existing businesses grow. Using your relationship-building skills, you provide Google-caliber client service, research and market analysis. You anticipate how decisions are made, persistently explore and uncover the business needs of Google's key clients and understand how our range of product offerings can grow their business. Working with them, you set the vision and the strategy for how their advertising can reach thousands of users.

//responsibilities
{
  /* Build and cultivate long-term relationship with C-Levels and customers decision makers, as well as their agency counterparts.Develop scalable and leading-edge solutions for customers in order to optimize their advertising investment and maximize the use of all Google products (Video, Display, Search, Mobile, etc.).*/
}

{
  /* <li>2+ years of experience in building/operating highly available, distributed systems of data extraction, ingestion, and processing of large data sets</li>
          <li>2+ years of Python development experience.</li>
          <li>2+ years of JavaScript development experience.</li> */
}
