import React, { useState, useContext, useRef } from "react";
import { Button, Select, Pagination, Input } from "antd";
import AddEmployeeCard from "./AddEmployeeCard";
import "./addEmployee.css";
import InputField from "../Generic/InputField";
import Avatar from "@mui/material/Avatar";
import { useGetRolesQuery, useCountRolesQuery } from "../../services/nodeAPI";
import { Spin, Alert } from "antd";
import { Form } from "antd";
import SimpleModal from "../Modal";
import AppContext from "../../context/appState/AppContext";
import { useAddEmployeeMutation } from "../../services/nodeAPI";
import { VariantType, useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import Spinner from "../Spinner";

export default function AddEmployees() {
  const [addEmployee] = useAddEmployeeMutation();
  const { onChangeGeneric } = useContext(AppContext);
  const [isModalVisible, setIsModalVisible] = useState(false); //state of modal of img Cropper

  const [employeeCreds, setEmployeeCreds] = useState({
    roleId: "",
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    phone_number: "",
    address: "",
  }); // FORM Credentials

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemLimit, setitemLimit] = useState(5);

  // IMG Cropper configurations/funcyions
  const [photoURL, setPhotoURL] = useState(null);
  const [realPhotoURL, setRealPhotoURL] = useState(null);
  const [file, setFile] = useState("");

  const [openCrop, setOpenCrop] = useState(false);

  var { data: totalRoles } = useCountRolesQuery();

  // MODAL FUNCTIONS
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFileUpload = (e) => {
    console.log(e.target.files[0]);
    const f = e.target.files[0];
    if (f) {
      setOpenCrop(true);
      setPhotoURL(URL.createObjectURL(f));
      setFile(f);
      showModal();
    }
  };

  // ONCHANGE FUNCTION for form
  const onChange = onChangeGeneric(employeeCreds, setEmployeeCreds);

  // HELPER FUNCTIONS
  const convertToUnifiedString = (arr) => {
    const newArr = arr.map((el) => el.split(" ")[1]);
    const set = new Set(newArr);

    const CommonArray = [...set];

    return CommonArray.join(", ");
  };

  function capitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  //******** SUBMIT Create Employee FORM
  const handleSubmit = async (e) => {
    setLoading(true);

    const img = new File([file], `employee_img-${Date.now()}`);
    let formData = new FormData();

    formData.append("firstname", employeeCreds.firstname);
    formData.append("lastname", employeeCreds.lastname);
    formData.append("email", employeeCreds.email);
    formData.append("username", employeeCreds.username);
    formData.append("phone_number", employeeCreds.phone_number);
    formData.append("address", employeeCreds.address);
    formData.append("roleId", employeeCreds.roleId);
    formData.append("profile_img", img);

    if (file) {
      const res = await addEmployee(formData);
      console.log(res);

      if (res.data.success) {
        setLoading(false);

        enqueueSnackbar(res.data.message, { variant: "success" });
        formRef.current.resetFields();
        setEmployeeCreds({
          roleId: "",
          firstname: "",
          lastname: "",
          email: "",
          username: "",
          phone_number: "",
          address: "",
        });
        setFile("");
        setRealPhotoURL(null);
      } else {
        setLoading(false);
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } else {
      setLoading(false);

      enqueueSnackbar("Please select profile image", { variant: "error" });
    }
  };

  // Calling API to get all roles
  const { data, error, isLoading } = useGetRolesQuery({
    itemLimit,
    pageNumber,
  });

  const roles = !isLoading && data.roles;

  function prevPageData() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  function nextPageData() {
    var maxRoles = totalRoles.roles[0].count;
    console.log(maxRoles);
    if (itemLimit * pageNumber < maxRoles) {
      setPageNumber(pageNumber + 1);
    }
  }

  return (
    <>
      {isLoading ? (
        // <Spin tip="Loading..."></Spin>
        <Spinner marginTop="14rem" />
      ) : (
        <Form className="my-4 px-2" ref={formRef}>
          <div className="row">
            <p className="text-2xl dark:text-white font-medium dark:text-white">
              Add Employees
            </p>
          </div>

          <div className="row">
            <p className="text-lg text-gray-500 dark:text-white font-medium dark:text-white">
              Choose Employee Role
            </p>
          </div>
          <div className="gap-3 flex flex-wrap">
            {roles.map((el) => {
              const text = `Responsible for managing ${convertToUnifiedString(
                el.permissions
              )}.`;
              const heading = `${capitalizeString(el.rolename)}`;

              return (
                <div className="w-72">
                  <AddEmployeeCard
                    heading={heading}
                    text={text}
                    key={el.roleid}
                    name="roleId"
                    value={el.roleid}
                    onChange={onChange}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex flex-col items-center">
            <div className="inline-flex mt-4">
              {/* <!-- Buttons --> */}
              <button
                class="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-700 bg-gray-200 rounded-l hover:bg-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={prevPageData}
              >
                <svg
                  class="mr-2 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Prev
              </button>
              <button
                class="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-700 bg-gray-200 rounded-r border-0 border-l border-gray-700 hover:bg-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={nextPageData}
              >
                Next
                <svg
                  class="ml-2 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
              {/* <!-- Help text --> */}
            </div>
            <span class="text-sm text-gray-700 dark:text-gray-400">
              Showing{" "}
              <span class="font-semibold text-gray-900 dark:text-white">
                {itemLimit * pageNumber - (itemLimit - 1)}
              </span>{" "}
              to{" "}
              <span class="font-semibold text-gray-900 dark:text-white">
                {itemLimit * pageNumber}
              </span>{" "}
              of{" "}
              <span class="font-semibold text-gray-900 dark:text-white">
                {/* 10 */}
                {totalRoles?.roles[0].count}
              </span>{" "}
              Roles
            </span>
          </div>

          <hr className="my-4" />
          <div className="row my-4">
            <div className="col-12">
              <h5 className="text-xl text-dark dark:text-white font-medium dark:text-white">
                Employee Details
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="w-full gap-3 flex flex-wrap">
              <div className="gap-3 flex flex-wrap w-full md:w-6/12">
                <div className="w-72">
                  <InputField
                    label="First Name"
                    placeholder="First Name"
                    type="text"
                    width="100%"
                    height="32px"
                    rules={[
                      { required: true, message: "Please enter firstname!" },
                    ]}
                    name="firstname"
                    required={true}
                    onChange={onChange}
                  />
                </div>
                <div className="w-72">
                  <InputField
                    label="Last Name"
                    placeholder="Last Name"
                    type="text"
                    width={"100%"}
                    height="32px"
                    rules={[
                      { required: true, message: "Please enter lastname!" },
                    ]}
                    required={true}
                    name="lastname"
                    onChange={onChange}
                  />
                </div>
                <div className="w-72">
                  <InputField
                    label="Email"
                    placeholder="Email"
                    type="email"
                    width={"100%"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid email!",
                        type: "email",
                      },
                    ]}
                    name="email"
                    onChange={onChange}
                  />
                </div>
                <div className="w-72">
                  <InputField
                    label="Username"
                    placeholder="Username"
                    type="text"
                    width={"100%"}
                    height="32px"
                    rules={[
                      { required: true, message: "Please enter username!" },
                    ]}
                    required={true}
                    name="username"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="">
                  <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                    Profile Image
                  </label>
                  <div className="p-2">
                    <Avatar
                      alt="Remy Sharp"
                      src={realPhotoURL}
                      sx={{ width: 100, height: 100 }}
                      style={{ marginTop: "" }}
                    />
                  </div>
                </div>
                <div className="md:pt-2 md:pl-4">
                  <label htmlFor="contained-button-file">
                    <Input
                      onChange={handleFileUpload}
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      style={{
                        position: "relative",
                        top: "2rem",
                        zIndex: "11",
                        opacity: "0",
                        width: "150px",
                      }}
                    />
                    <button
                      type="button"
                      variant="contained"
                      className="img_upload_btn text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-normal rounded-lg text-xs px-3 py-2 mr-2 mb-2 focus:outline-none"
                      // component="span"
                    >
                      Upload Image
                    </button>
                    {/* <Button

                    >
                      Upload Image
                    </Button> */}
                  </label>

                  {
                    <SimpleModal
                      handleOk={handleOk}
                      handleCancel={handleCancel}
                      isModalVisible={isModalVisible}
                      realPhotoURL={realPhotoURL}
                      setRealPhotoURL={setRealPhotoURL}
                      photoURL={photoURL}
                      setPhotoURL={setPhotoURL}
                      setOpenCrop={setOpenCrop}
                      setFile={setFile}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="gap-3 flex flex-wrap mt-3">
            <div className="w-72">
              <label
                for="address"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Address
              </label>
              <textarea
                id="address"
                rows="4"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Add employee address..."
                name="address"
                onChange={onChange}
              ></textarea>

              {/* <Form.Item
                name="address"
                className="my-2"
                rules={[
                  {
                    required: true,
                    message: "Please enter address",
                  },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={100}
                  rows={5}
                  name="address"
                  onChange={onChange}
                  placeholder="Add employee address.."
                />
              </Form.Item> */}
            </div>
            <div className="w-72">
              <InputField
                label="Phone Number"
                placeholder="Phone no"
                type="text"
                width={"100%"}
                rules={[
                  { required: true, message: "Please enter valid phone no!" },
                ]}
                name="phone_number"
                onChange={onChange}
              />
            </div>
          </div>
          <div className="ms-auto text-end my-5" style={{ width: "50%" }}>
            {/* <button type="submit" className="px- btn" style={{ backgroundColor: '#265BC4', color: 'white', borderRadius: '4px', marginBottom: "1rem" }}>
          Save Employee Data
            </button> */}

            <LoadingButton
              size="small"
              loading={loading}
              loadingPosition="end"
              variant="contained"
              className="btn save_emp_data_btn "
              type="button"
              onClick={handleSubmit}
            >
              Save Employee Data
            </LoadingButton>
          </div>
        </Form>
      )}
    </>
  );
}
