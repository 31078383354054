import React from "react";
// import { Form, Input, Button, Checkbox } from 'antd';

const InputField = ({
  label,
  name,
  placeholder,
  width = "70%",
  margin,
  rules,
  defaultValue,
  type,
  onChange,
  message,
  disabled = false,
}) => {
  // margin => ms-auto, mx-auto, me-auto
  return (
    <>
      {/* <div className={` ${margin}`} style={{ width: width }}>
        <label htmlFor={name} className="form-label">{label}</label>
      </div> */}

      <label
        for="email"
        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        {label}
      </label>
      <input
        type={type}
        id="email"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder={placeholder}
        name={name}
        value={defaultValue}
        disabled={disabled}
        onChange={onChange}
      />

      {/* <Form.Item
        name={name}
        className={`mb-3 ${margin}`} style={{ width: width }}
        rules={rules}
      >

        <Input type={type} name={name} defaultValue={defaultValue} disabled={disabled} onChange={onChange} placeholder={placeholder} />

      </Form.Item> */}
    </>
  );
};

export default InputField;
