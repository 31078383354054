import React from 'react';
import PropTypes from 'prop-types';

const Skeleton = ({
    className,
    ...divProps 
}) => {
    return (
        <div className={`border-1 rounded-lg p-3 cursor-pointer animate-pulse hover:shadow-md ${className}`} {...divProps}>
            <div className="flex items-center space-x-3">
                <svg 
                    className="text-gray-200 w-16 h-16" 
                    aria-hidden="true" 
                    fill="currentColor" 
                    viewBox="0 0 20 20" 
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path 
                        fillRule="evenodd" 
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                        clipRule="evenodd"
                    >
                    </path>
                </svg>
                <div>
                    <div className="w-48 h-2 bg-gray-200 rounded-full mb-2"></div>
                    <div className="h-2.5 bg-gray-200 rounded-full w-32"></div>
                </div>
            </div>
            <div className='mt-3'>
                <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full max-w-[360px] mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full max-w-[330px] mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full max-w-[300px] mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full max-w-[360px]"></div>
            </div>
            <span className="sr-only">Loading...</span>
        </div>
    );
};

export default Skeleton;

Skeleton.defaultProps = {};

Skeleton.propTypes = {
    className: PropTypes.string
};
