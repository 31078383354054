import axios from 'axios';
import Cookies from "js-cookie";

const BASE_URL = "https://www.meerkatsearch.com/api";
// const baseUrl = "https://3.85.232.76/";
// const BASE_URL = "http://127.0.0.1:8000/api";

async function uploadUrlsFile(fileData) {
  const apiURL = BASE_URL + '/scraper/store-file';
  const options = {};

  const formData = new FormData()
  formData.append('urls_file', fileData.urls_file)

  const response = await axios.post(apiURL, formData, options);
  return response.data;
}

async function saveCrawlerData(formData) {
  const apiURL = BASE_URL + '/scraper/crawl-profiles';
  const options = {};

  const response = await axios.post(apiURL, formData, options);
  return response.data;
}

export default {
  uploadUrlsFile,
  saveCrawlerData
};
