import React from "react";
import "./candidates.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Input } from "antd";
import TableComp from "../TableComp/TableComp";
import { Link } from "react-router-dom";
import {
  useGetAllCandidatesMutation,
  useDeleteCandidateMutation,
} from "../../services/nodeAPI";
import { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCandidateId } from "../../redux/candidateSlice";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import { Dialog, Transition } from "@headlessui/react";
import FlaskAPI from "../../services/FlaskAPI";
import "./customSpinner.scss";
import { ReactComponent as DeleteIcon } from "../../img/delete.svg";
import { ReactComponent as EditIcon } from "../../img/edit.svg";
import { ReactComponent as VisibilityIcon } from "../../img/visibility.svg";
import DeleteCandidate from "./DeleteCandidate";
import DisplayCandidate from "./DisplayCandidate";
import { Empty } from "antd";
import ResumeDialog from "../../updates/components/modal/resumeModal/ResumeDialog";
import SmallDialog from "../../updates/components/modal/smallDialog/SmallDialog";
import SimpleInput from "../../updates/components/input/SimpleInput";
import ScraperAPI from "../../services/ScraperAPI";


const Candidates = () => {
  const rId = "2";
  const [pageNumber, setPageNumber] = useState(1);
  const [itemLimit, setitemLimit] = useState(5);

  const getPermissionsOfUserForResource = () => {
    let data = [];
    const permRes = JSON.parse(localStorage.getItem("perm_res"));

    permRes.forEach((el) => {
      if (el.resourceId === rId) {
        if (!data.find((e) => e === el.permissionId)) {
          data.push(el.permissionId);
        }
      }
    });

    return data;
  };

  const { enqueueSnackbar } = useSnackbar();

  let [viewCandidateModal, setViewCandidateModal] = useState(false);

  function closeViewCandidateModal() {
    setViewCandidateModal(false);
  }

  function openViewCandidateModal() {
    setViewCandidateModal(true);
  }

  const dispatch = useDispatch();

  const candidateId = useSelector((state) => state.candidate.candidateId);

  const { Search } = Input;
  const [getAllCandidates] = useGetAllCandidatesMutation();
  const [deleteCandidate] = useDeleteCandidateMutation();
  const [candidates, setCandidates] = useState([]);
  const [delCandidateData, setDelCandidateData] = useState({});
  const [viewCandidateData, setViewCandidateData] = useState();
  const [showDelModal, setShowDelModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [total_records, setTotalRecords] = useState(0);

  const allCandidates = async () => {
    const { data } = await getAllCandidates({
      fullname: "",
      itemLimit,
      pageNumber,
    });

    setTotalRecords(data.candidateData[0].total_records);
    setCandidates(data.candidateData);
  };

  const deleteOneCandidate = async (id) => {
    const { data } = await deleteCandidate(id);
    if (data.message === "Success") {
      enqueueSnackbar("Candidate deleted successfully!", {
        variant: "success",
      });
      setCandidates(
        candidates.filter((el) => {
          return el.id !== id;
        })
      );
    } else {
      enqueueSnackbar("Error occured during deleting candidate!!", {
        variant: "error",
      });
    }
  };

  const data =
    candidates &&
    candidates.map((el, i) => {
      // var img =
      //    === null
      //     ? "null"
      //     : `https://www.meerkatsearch.com${el.profile_img}`;

      let exp =
        el["experience"] === undefined ||
        el["experience"] === null ||
        el["experience"] === 0
          ? "0 years"
          : el["experience"] === 1
          ? el["experience"] + " year"
          : el["experience"] + " years";

      return {
        key: el.id,
        profile_img: el.profile_img,
        fullname: el.fullname,
        email: el.email,
        location: el.location,
        experience: el.experience,
        linkedin: el.linkedin_url,        
      };
    });

  var [addCandidateModal, setAddCandidateModal] = useState(false);
  var [candidateResumeModal, setCandidateResumeModal] = useState(false);

  function closeModal() {
    setAddCandidateModal(false);
  }

  function openModal() {
    setAddCandidateModal(true);
  }

  function openResumeModal() {
    setAddCandidateModal(false);
    setCandidateResumeModal(true);
  }

  useEffect(() => {
    allCandidates();
  }, []);

  useEffect(() => {
    allCandidates();
  }, [pageNumber]);

  const columns = [
    {
      title: "Full Name",
      key: "fullname",
      colSpan: 2,
    },
    {
      title: "Email",
      key: "email",
      colSpan: 1,
    },
    {
      title: "Location",
      key: "location",
      colSpan: 1,
    },
    {
      title: "Experience",
      key: "experience",
      colSpan: 1,
    },
    {
      title: "Linkedin",
      key: "linkedin",
      colSpan: 1,
    },
    {
      title: "Action",
      key: "action",
      colSpan: 1,
    },
  ];

  const [value, setValue] = useState("");

  // const searchByName = (e)=>{
  //   const currValue = e.target.value;
  //   setValue(currValue);
  //   const filteredData = data.filter(entry =>
  //     entry.fullName.includes(currValue)
  //   );
  //   setDataSource(filteredData);

  // }

  const [parsingResumeText, setResumeParsingText] = useState(
    "Started Parsing Resume..."
  );
  const [isResumeParsed, setisResumeParsed] = useState(false);
  const [isResumeUploading, setIsResumeUploading] = useState(false);
  const [isResumeParsingError, setIsResumeParsingError] = useState(false);
  const [selectedFileToUpload, setSelectedFileToUpload] = useState("");
  const [dataToSendNextStep, setDataToSendNextStep] = useState({});

  function fileSelected(file) {
    if (file) {
      setSelectedFileToUpload(file.name);
    }
  }

  function resumeParsed() {
    setResumeParsingText("Resume Parsing Finished!");
    setisResumeParsed(true);
  }

  function uploadResume(fileData) {
    setIsResumeUploading(true);
    FlaskAPI.parseResume(fileData)
      .then((responseData) => {
        setDataToSendNextStep({ ...responseData });
        // console.log(responseData);
        resumeParsed();
        if (responseData.error === true) {
          setIsResumeParsingError(true);
          setResumeParsingText("");
        }
      })
      .catch(() => {
        setDataToSendNextStep({});
        resumeParsed();
        setIsResumeParsingError(true);
        setResumeParsingText("");
      });
  }

  function closeResumeModal() {
    setCandidateResumeModal(false);
    setisResumeParsed(false);
    setResumeParsingText("Started Parsing Resume...");
    setIsResumeParsingError(false);
    setIsResumeUploading(false);
    setSelectedFileToUpload("");
  }

  function openDelModal(id, name) {
    setDelCandidateData({ id, name });
    setShowDelModal(true);
  }

  function closeDelModal() {
    setShowDelModal(false);
  }

  function openViewModal(id) {
    setViewCandidateData(id);
    setShowViewModal(true);
  }

  function closeViewModal() {
    setShowViewModal(false);
  }

  const handleSearch = async (e) => {
    const { data } = await getAllCandidates({
      fullname: e.target.value,
      itemLimit,
      pageNumber,
    });
    if(data.candidateData.length <= 0){
      setTotalRecords(0);
      setCandidates([]);
    }else{
      setTotalRecords(data.candidateData[0].total_records);
      setCandidates(data.candidateData);      
    }
  };

  function prevPageData() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  function nextPageData() {
    var maxRoles = total_records;
    if (itemLimit * pageNumber <= maxRoles) {
      setPageNumber(pageNumber + 1);
    }
  }

  const permissions = getPermissionsOfUserForResource();
  // console.log(permissions);

  // Function for Scraping Modules

  const [isFileDialogOpen, setFileDialog] = useState(false)
  const [isSearchDialogOpen, setSearchDialog] = useState(false)
  const [crawlerFilterData, setCrawlerFilterData] = useState({})
  const [urlsFileData, setUrlsFileData] = useState({})
  const [isWorking, setIsWorking] = useState(false)
  const [savedCrawlerQueryState, setSavedCrawlerQueryState] = useState({})
  const [savedUrlsFileState, setSavedUrlsFileState] = useState({})  

  const setFileDataOnChange = (key, value) => {
    setUrlsFileData({ ...urlsFileData, [key]: value });
  };

  const setCrawlerDataOnChange = (key, value) => {
    setCrawlerFilterData({ ...crawlerFilterData, [key]: value });
  };

  const saveUrlsFile = () => {
    setIsWorking(true)
    ScraperAPI.uploadUrlsFile(urlsFileData).then((response) => {
      setIsWorking(false)
      setSavedUrlsFileState({ success: response.success, message: response.message })
    }).catch((error) => {
      console.log(error)
      setSavedUrlsFileState({ success: false, message: 'Internal Server Error, Unable to Store file!' })      
      setIsWorking(false)
    })
  }

  const saveCrawlerSearchQuery = () => {
    setIsWorking(true)    
    ScraperAPI.saveCrawlerData(crawlerFilterData).then((response) => {
      setIsWorking(false)
      setSavedCrawlerQueryState({ success: response.success, message: response.message })
    }).catch((error) => {
      console.log(error)
      setSavedCrawlerQueryState({ success: false, message: 'Internal Server Error, Please try again later!' })      
      setIsWorking(false)      
    })
  }

  return (
    <>

      <ResumeDialog
        isModalOpen={showViewModal}
        closeModal={closeViewModal}
        candidateId={viewCandidateData}
      />

      <SmallDialog 
        title='Import Candidates'
        titleHelperText={`Please upload csv file having candidate's linkedIn urls.`}
        modalState={isFileDialogOpen}
        closeModal={() => setFileDialog(false)}
      >
        <div className='flex flex-col justify-between'>
          <div className="flex items-center justify-center w-full">
              <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">CSV Files are accepted!</p>
                  </div>
                  <input 
                    id="dropzone-file" 
                    type="file" 
                    className="hidden"
                    accept='.csv'
                    onChange={(event) => {
                      setFileDataOnChange('urls_file', event.target.files[0])
                    }}
                  />
              </label>
          </div> 

          <div className="mt-10">
            {
              savedUrlsFileState?.success !== undefined ? (
                <>
                  {
                    savedUrlsFileState?.success === true ? (
                      <div className="flex justify-center">
                        <span
                          className="w-full bg-green-100 text-green-800 text-xs text-center font-medium px-2.5 py-2 rounded border border-green-400"
                        >
                          {savedUrlsFileState?.message}
                        </span>
                      </div>
                    )
                    : (
                      <div className="flex justify-center">
                        <span
                          className="w-full bg-red-100 text-red-800 text-xs text-center font-medium px-2.5 py-2 rounded border border-red-400"
                        >
                          {savedUrlsFileState?.message}
                        </span>
                      </div>
                    )
                  }                
                </>
              ) : (
                <></>
              )
            }           
          </div>

          <div className='mt-10'>
            <button 
              type="button" 
              className="flex space-x-1 w-full justify-center text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center leading-4"
              onClick={() => saveUrlsFile()}
            >
              {isWorking ? (
                <>
                  <div role="status">
                    <svg
                      className="inline -mt-1 mr-2 w-4 h-4 text-gray-200 animate-spin fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                  <span>Please wait...</span>
                </>
              ) : (
                <span>Save Candidate URLs</span>
              )}
            </button>
          </div>
        </div>
      </SmallDialog>

      <SmallDialog 
        title='Search Candidates'
        titleHelperText={`You can use Search filters for more precise search results.`}
        modalState={isSearchDialogOpen}
        closeModal={() => setSearchDialog(false)}
      >
        <div className='flex flex-col justify-between -mt-2'>
          <div className="flex flex-col w-full space-y-4">
            <span className="text-lg font-medium text-gray-900">Search Filters</span>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900">Years of Experience</label>
              <SimpleInput
                type="number" 
                name="experience" 
                placeholder="Years of Experience" 
                required={false}
                updateData={setCrawlerDataOnChange} 
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900">Candidate Location</label>
              <SimpleInput
                type="text" 
                name="location" 
                placeholder="Amsterdam, Netherlands" 
                required={false}
                updateData={setCrawlerDataOnChange} 
              />
              <small className="text-gray-700 font-normal mt-1 text-xs">
                City Name, Country Name or City Name or Country Name
              </small>
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900">Candidate Skills</label>
              <SimpleInput
                type="text" 
                name="skills" 
                placeholder="python javascript sql" 
                required={false}
                updateData={setCrawlerDataOnChange} 
              />
              <small className="text-gray-700 font-normal mt-1 text-xs">
                Provide a space between each skill
              </small>
            </div>
          </div> 
          <div className="mt-10">
            {
              savedCrawlerQueryState?.success !== undefined ? (
                <>
                  {
                    savedCrawlerQueryState?.success === true ? (
                      <div className="flex justify-center">
                        <span
                          className="w-full bg-green-100 text-green-800 text-xs text-center font-medium px-2.5 py-2 rounded border border-green-400"
                        >
                          {savedCrawlerQueryState?.message}
                        </span>
                      </div>
                    )
                    : (
                      <div className="flex justify-center">
                        <span
                          className="w-full bg-red-100 text-red-800 text-xs text-center font-medium px-2.5 py-2 rounded border border-red-400"
                        >
                          {savedCrawlerQueryState?.message}
                        </span>
                      </div>
                    )
                  }                
                </>
              ) : (
                <></>
              )
            }           
          </div>
          <div className='mt-10'>
            <button 
              type="button" 
              className="flex space-x-1 w-full justify-center text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center leading-4"
              onClick={() => saveCrawlerSearchQuery()}
            >
              {isWorking ? (
                <>
                  <div role="status">
                    <svg
                      className="inline -mt-1 mr-2 w-4 h-4 text-gray-200 animate-spin fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                  <span>Please wait...</span>
                </>
              ) : (
                <span>Search Candidates</span>
              )}
            </button>
          </div>
        </div>
      </SmallDialog>

      <div>
        <DeleteCandidate
          showModal={showDelModal}
          closeModal={closeDelModal}
          candidateId={delCandidateData.id}
          candidateName={delCandidateData.name}
        />
      </div>

      {/* <div>
        <DisplayCandidate
          showModal={showViewModal}
          closeModal={closeViewModal}
          candidateId={viewCandidateData.id}
        />
      </div> */}

      <div className="px-3">
        <div className="row my-4 pt-3">
          <div className="d-flex flex-wrap justify-content-between">
            <div>
              <span className="text-2xl font-medium dark:text-white">
                Candidates
              </span>
              <p className="test-base font-base text-gray-500 dark:text-white">
                You can manage candidates for jobs in this section
              </p>
            </div>

            {permissions.includes("2") && (
              <div className="my-auto">
                <button
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-base rounded text-sm px-4 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  onClick={openModal}
                >
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  <span className="ms-2"> Add Candidates</span>
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="w-100">
            <div>
              <span className="text-lg font-semibold dark:text-white">
                Search Filters
              </span>
              <p className="test-base font-base text-gray-500 dark:text-white">
                With the help of filters you can easily find candidates.
              </p>
            </div>

            {/* <div>
                  <Input placeholder="Search candidate by name"  value={value} className="px-3 my-2" style={{ borderRadius: '4px', border: '1px solid #265BC4', width: '335px' }} suffix={<FontAwesomeIcon icon={faMagnifyingGlass} color='#265BC4' />} enterButton />
                </div> */}

            <form className="gap-3 flex flex-wrap items-center mt-3">
              <div className="relative w-74 md:w-50">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search Candidates by name..."
                  onChange={(event) => handleSearch(event)}
                />
              </div>
              <button
                type="button"
                className="inline-flex items-center py-2 px-3 md:ml-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                <svg
                  className="mr-2 -ml-1 w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
                Search
              </button>
            </form>
          </div>
        </div>

        <div className="mt-4">
          {
            data?.length <= 0 ? <Empty /> : 
            
            <TableComp
              data={data}
              columns={columns}
              deleteModelOpen={openDelModal}
              showViewModal={openViewModal}
              tableName="candidate"
              permissions={permissions}
            />
          }
          {data !== undefined && data.length > 0 && (
            <div className="mt-4">
              <div className="flex flex-col items-end">
                <span className="text-sm text-gray-700 dark:text-gray-400">
                  <span className="text-sm text-gray-700 dark:text-gray-400">
                    Showing{" "}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {itemLimit * pageNumber - (itemLimit - 1)}
                    </span>{" "}
                    to{" "}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {itemLimit * pageNumber > total_records
                        ? total_records
                        : itemLimit * pageNumber}
                    </span>{" "}
                    of{" "}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {total_records !== undefined ? total_records : 0}
                    </span>{" "}
                    Records
                  </span>
                </span>
                <div className="inline-flex mt-2 xs:mt-0">
                  <button
                    className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-700 bg-gray-200 rounded-l hover:bg-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    onClick={prevPageData}
                  >
                    <svg
                      className="mr-2 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    Prev
                  </button>
                  <button
                    className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-700 bg-gray-200 rounded-r border-0 border-l border-gray-700 hover:bg-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    onClick={nextPageData}
                  >
                    Next
                    <svg
                      className="ml-2 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* =============================================================================================== */}
      {/*                             HEADLESS UI MODAL                                                   */}
      {/* =============================================================================================== */}

      <Transition appear show={addCandidateModal} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all">
                  {/* <!-- Modal content --> */}
                  <div className="relative">
                    <button
                      type="button"
                      className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                      onClick={closeModal}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <div className="py-6 px-6 lg:px-8">
                      <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                        Add New Candidate
                      </h3>
                      <div className="text-sm font-base text-gray-500 dark:text-gray-300">
                        Select an appropriate method to add candidate.
                      </div>
                      <div className="mt-4">
                        <Tooltip
                          title="Under Maintainance"
                          placement="right"
                          arrow
                        >
                          <div data-modal-toggle="new-candidate-option-modal">
                            <button
                              type="button"
                              className="w-full py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-gray rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                              disabled
                              // onClick={openResumeModal}
                            >
                              Add Candidate Using Resume
                            </button>
                          </div>
                        </Tooltip>

                        <div>
                          <button
                            type="button"
                            className="w-full text-gray-900 bg-gray border border-gray-300 focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                            onClick={() => setFileDialog(true)}
                          >
                            Import LinkedIn URLs From File
                          </button>
                        </div>

                        <div>
                          <button
                            type="button"
                            className="w-full text-gray-900 bg-gray border border-gray-300 focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                            onClick={() => setSearchDialog(true)}
                          >
                            Find Candidates using Search Filters
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* =============================================================================================== */}
      {/*                             END OF HEADLESS UI MODAL                                            */}
      {/* =============================================================================================== */}

      {/* =============================================================================================== */}
      {/*                             HEADLESS UI MODAL                                                   */}
      {/* =============================================================================================== */}

      <Transition appear show={candidateResumeModal} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeResumeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all">
                  {/* <!-- Modal content --> */}
                  <div className="relative">
                    <button
                      type="button"
                      className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                      onClick={closeResumeModal}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <div className="py-6 px-6 lg:px-8">
                      <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                        Candidate Resume
                      </h3>
                      <span className="text-sm font-normal text-gray-600">
                        This step will parse important information from resume.
                      </span>
                      <div className="mt-4">
                        <div className="p-6 space-y-6">
                          {isResumeUploading === false ? (
                            <div className="flex items-center justify-center w-full">
                              <label
                                for="dropzone-file"
                                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                              >
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                  <svg
                                    className="w-10 h-10 mb-3 text-gray-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    ></path>
                                  </svg>
                                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">
                                      Click to upload
                                    </span>{" "}
                                    or drag and drop
                                  </p>
                                  <p className="text-xs text-gray-500 dark:text-gray-400">
                                    PDF, DOCX
                                  </p>
                                  <p className="mb-2 text-sm text-blue-500 dark:text-blue-400 font-semibold">
                                    {selectedFileToUpload}
                                  </p>
                                </div>
                                <input
                                  id="dropzone-file"
                                  type="file"
                                  className="hidden"
                                  accept=".pdf, .docx, .doc"
                                  onChange={(event) =>
                                    fileSelected(event.target.files[0])
                                  }
                                />
                              </label>
                            </div>
                          ) : (
                            <div className="flex justify-content-center">
                              <div className="text-center">
                                {isResumeParsed === false ? (
                                  <svg
                                    id="resume-parsing-animation"
                                    role="status"
                                    className="inline w-20 h-20 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                ) : isResumeParsingError === true ? (
                                  <div>
                                    <svg
                                      className="checkmark"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 52 52"
                                    >
                                      <circle
                                        className="checkmark__circle"
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none"
                                      />
                                      <path
                                        className="checkmark__check"
                                        fill="none"
                                        d="M16 16 36 36 M36 16 16 36"
                                      />
                                    </svg>

                                    <p className="mt-3 font-medium text-red-600">
                                      Error While Parsing Resume, Please try
                                      again!
                                    </p>
                                  </div>
                                ) : (
                                  <div id="finished-resume-parse-animation">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="svg-success"
                                      viewBox="0 0 24 24"
                                    >
                                      <g
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-miterlimit="10"
                                      >
                                        <circle
                                          className="success-circle-outline"
                                          cx="12"
                                          cy="12"
                                          r="11.5"
                                        />
                                        <circle
                                          className="success-circle-fill"
                                          cx="12"
                                          cy="12"
                                          r="11.5"
                                        />
                                        <polyline
                                          className="success-tick"
                                          points="17,8.5 9.5,15.5 7,13"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                )}

                                <div className="mt-3 font-medium text-blue-600">
                                  {parsingResumeText}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="flex justify-content-end p-6 space-x-2 rounded-b dark:border-gray-600">
                          {isResumeUploading === false ? (
                            <button
                              // to="/dashboard/addnewcandidate"
                              type="button"
                              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              onClick={() => {
                                let resumeFile =
                                  document.getElementById("dropzone-file")
                                    .files[0];
                                if (resumeFile) {
                                  uploadResume(resumeFile);
                                }
                              }}
                            >
                              Upload Resume
                            </button>
                          ) : isResumeParsed === false ? (
                            <button
                              disabled
                              type="button"
                              className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                            >
                              <svg
                                role="status"
                                className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="#1C64F2"
                                />
                              </svg>
                              Parsing Data...
                            </button>
                          ) : isResumeParsingError === true ? (
                            <button
                              type="button"
                              className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                              onClick={closeResumeModal}
                            >
                              Close
                            </button>
                          ) : (
                            <Link
                              type="button"
                              to="/dashboard/addnewcandidate"
                              state={{
                                parsedData: {
                                  ...dataToSendNextStep,
                                },
                              }}
                              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                              Next Step
                              <svg
                                className="w-5 h-5 ml-2 -mr-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* =============================================================================================== */}
      {/*                             END OF HEADLESS UI MODAL                                            */}
      {/* =============================================================================================== */}
    </>
  );
};

export default Candidates;
