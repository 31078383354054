import "../../css/jobs.css";
import { useState, useEffect, Fragment } from "react";
import { PageHeader } from "../Generic/PageHeader";
import {
  useGetAllJobMutation,
  useGetJobQuery,
  useGetMyJobMutation,
  useSearchLocationMutation,
} from "../../services/nodeAPI";
import { setMyJob } from "../../redux/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import SearchBoxFilter from "../SmartSearch/SearchBoxFilter";
import MultiRangeSlider from "../multiRangeSlider";
import { Menu, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Spin, Empty } from "antd";
import Cookies from "js-cookie";
import DeleteJob from "./DeleteJob";
import { setJobId } from "../../redux/jobSlice";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ReactTimeAgo from "react-time-ago";

TimeAgo.addDefaultLocale(en);

export const Jobs = (props) => {
  // const [jobs,setJobs]= useState([]);

  const [showJobModal, setShowJobModal] = useState(false);
  const [salaryRange, setSalaryRange] = useState({
    min: 0,
    max: 200000,
  });

  const [locationSearchApi] = useSearchLocationMutation();

  const [locationSearch, setLocationSearch] = useState([]);

  const rId = "1";

  const getPermissionsOfUserForResource = () => {
    let data = [];
    const permRes = JSON.parse(localStorage.getItem("perm_res"));

    permRes.forEach((el) => {
      if (el.resourceId === rId) {
        if (!data.find((e) => e === el.permissionId)) {
          data.push(el.permissionId);
        }
      }
    });

    return data;
  };

  const dispatch = useDispatch();
  const [getAllJob] = useGetAllJobMutation();
  const [allJobs, setAllJobs] = useState([]);
  const [filter, setFilter] = useState({});
  const [jobId, setCardJobId] = useState(null);
  const [getMyJob] = useGetMyJobMutation();
  const [jobsLoading, setJobsLoading] = useState(true);
  const myJob = useSelector((state) => state.job.myJob);
  const [total_jobs, setTotalJobs] = useState(0);
  const [showDelModal, setShowDelModal] = useState(false);
  const [delJobData, setDelJobData] = useState({});

  const handleJobTypeChange = (value) => {
    setFilter({ ...filter, job_type: [value.target.value] });
  };

  const handleDatePostedChange = (event) => {
    setFilter({ ...filter, date_posted: [event.target.value] });
  };

  const handleCardClick = (e) => {
    e.preventDefault();
    setCardJobId(e.target.closest(".job_card").getAttribute("id"));
    setShowJobModal(true);
  };

  const getJobs = async () => {
    setJobsLoading(true);
    if (myJob !== true) {
      getAllJob(filter).then((response) => {
        setAllJobs(response.data.job_details);
        setTotalJobs(response.data.job_details[0].total_jobs);
        setJobsLoading(false);
      });
    } else {
      getMyJob().then((response) => {
        setAllJobs(response.data.job_details);
        setTotalJobs(response.data.job_details[0].total_jobs);
        setJobsLoading(false);
      });
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    setFilter({ ...filter, salary: salaryRange });
  }, [salaryRange]);

  useEffect(() => {
    setFilter({ ...filter, location: locationSearch });
    console.log(locationSearch);
  }, [locationSearch]);

  useEffect(() => {
    getJobs();
  }, [filter]);

  useEffect(() => {
    getJobs();
  }, [myJob]);

  function convertSalary(value) {
    let newValue = value;
    const suffixes = ["", "k", "m", "b", "t"];
    let suffixNum = 0;

    while (newValue >= 1000) {
      newValue /= 1000;
      suffixNum++;
    }

    try {
      newValue =
        newValue.toString().length > 2
          ? newValue.toPrecision(3)
          : newValue.toPrecision();

      newValue += suffixes[suffixNum];
    } catch (error) {
      return " ";
    }

    return newValue;
  }

  function openDelModal(id, title) {
    setDelJobData({ id, title });
    setShowDelModal(true);
  }

  function closeDelModal() {
    setShowDelModal(false);
  }

  const setUpdateJobId = (id) => {
    dispatch(setJobId(id));
  };

  const permissions = getPermissionsOfUserForResource();
  console.log(permissions);

  return (
    <>
      <div>
        <DeleteJob
          showModal={showDelModal}
          closeModal={closeDelModal}
          jobId={delJobData.id}
          jobTitle={delJobData.title}
        />
      </div>

      <div className="px-3">
        <div className="flex flex-wrap justify-content-between">
          <div>
            <span className="text-2xl font-medium dark:text-white">Jobs</span>
            <p className="test-base font-base text-gray-500 dark:text-white">
              You can manage jobs in this section
            </p>
          </div>

          {permissions.includes("2") && (
            <div className="my-auto">
              <Link to={"/dashboard/addnewjob"}>
                <button
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-base rounded text-sm px-4 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  <span className="ms-2">Add New Job</span>
                </button>
              </Link>
            </div>
          )}
        </div>

        <div className="row mt-2">
          {/* Left */}
          <div className="w-full lg:w-10/12 xl:w-7/12 2xl:w-6/12">
            {/* Header and search title*/}
            <div className="w-full">
              <div className="row">
                <form className="gap-2 flex flex-wrap items-center mt-3">
                  {/* <div className="relative w-full md:w-6/12 lg:w-4/12">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <svg
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search Job by name..."
                    />
                  </div>
                  <button
                    type="button"
                    className="inline-flex items-center py-2 px-3 md:ml-2 text-sm font-medium focus:outline-none rounded-lg text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
                  >
                    <svg
                      className="mr-2 -ml-1 w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      ></path>
                    </svg>
                    Search
                  </button> */}
                  <div className="gap-2 flex flex-wrap">
                    <button
                      onClick={() => dispatch(setMyJob(true))}
                      type="button"
                      className="inline-flex items-center py-2 px-3 md:ml-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      My Jobs
                    </button>
                    <button
                      onClick={() => dispatch(setMyJob(false))}
                      type="button"
                      className="inline-flex items-center py-2 px-3 md:ml-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      All Jobs
                    </button>
                  </div>
                </form>
              </div>
              <hr className="search_margin mt-4" />
              <div className="gap-3 flex flex-wrap mt-3">
                <div className="w-full sm:w-5/12 lg:w-3/12">
                  <select
                    id="datePosted"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm shadow-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 cursor-pointer"
                    onChange={(e) => handleDatePostedChange(e)}
                  >
                    <option value="3 years" selected>
                      Date Posted
                    </option>
                    <option value="24 hours">24 hours</option>
                    <option value="1 week">1 week</option>
                    <option value="1 month">1 month</option>
                    <option value="1 year">1 year</option>
                  </select>
                </div>
                <div className="w-full sm:w-5/12 lg:w-3/12">
                  <select
                    id="datePosted"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm shadow-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 cursor-pointer"
                    onChange={handleJobTypeChange}
                  >
                    <option value="1">Full Time</option>
                    <option value="2">Part Time</option>
                    <option value="3">Contract</option>
                  </select>
                </div>
                <div className="w-full sm:w-5/12 lg:w-5/12">
                  <Menu
                    as="div"
                    className="relative inline-block text-left bg-gray-50 border border-gray-300 text-gray-900 text-sm shadow-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 cursor-pointer"
                  >
                    <div>
                      <Menu.Button className="w-full flex justify-between">
                        {salaryRange.min === undefined
                          ? "Select Salary Range"
                          : "$" +
                            convertSalary(salaryRange.min) +
                            " - $" +
                            convertSalary(salaryRange.max)}
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="focus:outline-none absolute right-0 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white border shadow-md ring-1 ring-black ring-opacity-5">
                        <div className="px-1 py-1 ">
                          <Menu.Item>
                            <div>
                              <div class="pt-3 pl-4">Salary in USD</div>
                              <div class="pt-3 pb-4 px-3">
                                <MultiRangeSlider
                                  min={0}
                                  max={200000}
                                  onChange={({ min, max }) => {
                                    console.log(`min = ${min}, max = ${max}`);
                                  }}
                                  updateSelectedSalary={setSalaryRange}
                                />
                              </div>
                            </div>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="w-full sm:w-5/12 lg:w-5/12">
                  <SearchBoxFilter
                    filter_id={filter.id}
                    filter_label={"Select Desired Location"}
                    filter_selection_label={"Location"}
                    search_api={locationSearchApi}
                    updateFilterData={setLocationSearch}
                    className={"w-full z-10"}
                  />
                </div>
              </div>
            </div>

            {/* All Jobs */}
            <p className="jobs_found">{total_jobs} Jobs found</p>
            <div className="row job_table space-y-4">
              {jobsLoading === true ? (
                <Spin tip="Loading..."></Spin>
              ) : (
                <div>
                  {console.log(allJobs)}
                  {allJobs.length <= 0 ? (
                    <Empty className="mt-5" />
                  ) : (
                    allJobs.map((j) => {
                      return (
                        <div className="w-full flex flex-wrap">
                          <div
                            className="job_card block bg-white py-2 border-1 border-gray-150 rounded-lg shadow-sm cursor-pointer mb-3"
                            id={j.id}
                            onClick={handleCardClick}
                          >
                            <div className="md:hidden">
                              <div className="flex justify-between px-3">
                                <div className="pt-1">
                                  {j.logo !== null && j.logo !== undefined ? (
                                    <img
                                      className="w-14 h-14 rounded-full"
                                      src={
                                        "https://www.meerkatsearch.com" + j.logo
                                      }
                                      alt={j.company_name}
                                    />
                                  ) : (
                                    <div className="flex items-center justify-center w-14 h-14 text-base font-medium text-white bg-blue-800 rounded-full">
                                      {j.title.charAt(0)}
                                    </div>
                                  )}
                                </div>
                                <div className="pt-2">
                                  <span className="last_updated">
                                    <ReactTimeAgo
                                      date={new Date(j.updatedAt)}
                                      locale="en-US"
                                      timeStyle="round-minute"
                                    />
                                  </span>
                                </div>
                              </div>
                              {/* className="w-10/12 ms-4" */}
                              <div>
                                <div className="ps-2 mt-2 pb-2">
                                  <div>
                                    <span className="font-semibold text-base">
                                      {j.title}
                                    </span>
                                    <p className="font-medium text-xs text-gray-400">
                                      {j.company_name}
                                    </p>
                                  </div>
                                  <div className="mt-3 gap-3 flex flex-wrap">
                                    <span className="inline-block bg-blue-100 text-blue-800 py-1.5 px-2 text-xs font-medium rounded-md">
                                      {j.job_type}
                                    </span>
                                    <span className="inline-block bg-blue-100 text-blue-800 py-1.5 px-2 text-xs font-medium rounded-md">
                                      {j.location}
                                    </span>
                                    <span className="inline-block bg-blue-100 text-blue-800 py-1.5 px-2 text-xs font-medium rounded-md">
                                      {"USD "}
                                      {j.salary
                                        .split("-")
                                        .map((salary, index) => {
                                          if (index === 0) {
                                            return (
                                              convertSalary(salary) + " - "
                                            );
                                          }
                                          return convertSalary(salary);
                                        })}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="hidden md:block">
                              <div className="flex px-3">
                                <div className="pt-1">
                                  {j.logo !== null && j.logo !== undefined ? (
                                    <img
                                      className="w-14 h-14 rounded-full"
                                      src={
                                        "https://www.meerkatsearch.com" + j.logo
                                      }
                                      alt={j.company_name}
                                    />
                                  ) : (
                                    <div className="flex items-center justify-center w-14 h-14 text-base font-medium text-white bg-blue-800 rounded-full">
                                      {j.title.charAt(0)}
                                    </div>
                                  )}
                                </div>
                                <div className="pt-2 flex justify-between w-11/12">
                                  <div className="">
                                    <div className="ps-2 mt-2 pb-2">
                                      <div>
                                        <span className="font-semibold text-base">
                                          {j.title}
                                        </span>
                                        <p className="font-medium text-xs text-gray-400">
                                          {j.company_name}
                                        </p>
                                      </div>
                                      <div className="mt-3 gap-3 flex flex-wrap">
                                        <span className="inline-block bg-blue-100 text-blue-800 py-1.5 px-2 text-xs font-medium rounded-md">
                                          {j.job_type}
                                        </span>
                                        <span className="inline-block bg-blue-100 text-blue-800 py-1.5 px-2 text-xs font-medium rounded-md">
                                          {j.location}
                                        </span>
                                        <span className="inline-block bg-blue-100 text-blue-800 py-1.5 px-2 text-xs font-medium rounded-md">
                                          {"USD "}
                                          {j.salary
                                            .split("-")
                                            .map((salary, index) => {
                                              if (index === 0) {
                                                return (
                                                  convertSalary(salary) + " - "
                                                );
                                              }
                                              return convertSalary(salary);
                                            })}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="last_updated">
                                    <ReactTimeAgo
                                      date={new Date(j.updatedAt)}
                                      locale="en-US"
                                      timeStyle="round-minute"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Right */}
          {jobId && (
            <JobDetail
              id={jobId}
              convertSalary={convertSalary}
              showModal={showJobModal}
              setJobModal={setShowJobModal}
              openDelModal={openDelModal}
              setUpdateJobId={setUpdateJobId}
            />
          )}
        </div>
      </div>
    </>
  );
};

function JobDetail(props) {
  const { data, error, isLoading, isSuccess } = useGetJobQuery(props.id);
  // console.log(data);

  const [isModalVisible, setIsModalVisible] = useState(props.showModal);
  // console.log("Model shown clicked", props.showModal);
  const [jobDeleting, setJobDeleting] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setTimeout(() => {
      showModal(props.showModal);
    }, 0);
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
    props.setJobModal(false);
  };

  const deleteModalOpen = (id, title) => {
    props.openDelModal(id, title);
    props.setJobModal(false);
    setJobDeleting(true);
  };

  return (
    !isLoading &&
    isSuccess && (
      <>
        {!jobDeleting && (
          <>
            <Transition appear show={isModalVisible} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-30 xl:hidden"
                onClose={handleCancel}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all">
                        {/* <!-- Modal content --> */}
                        <div className="relative">
                          <button
                            type="button"
                            class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                            onClick={handleCancel}
                          >
                            <svg
                              class="w-5 h-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </button>
                          <div className="pt-10">
                            <div className="w-full 2xl:w-10/12">
                              <div className="block bg-white py-3">
                                <div className="px-3">
                                  <div className="flex">
                                    <div className="ps-4 pt-3">
                                      {data.job_details[0].logo !== null &&
                                      data.job_details[0].logo !== undefined ? (
                                        <img
                                          className="w-14 h-14 rounded-full"
                                          src={
                                            "https://www.meerkatsearch.com" +
                                            data.job_details[0].logo
                                          }
                                          alt={data.job_details[0].company_name}
                                        />
                                      ) : (
                                        <div className="flex items-center justify-center w-14 h-14 text-base font-medium text-white bg-blue-800 rounded-full">
                                          {data.job_details[0].title.charAt(0)}
                                        </div>
                                      )}

                                      {data.loggedInUser ===
                                      data.job_details[0].userId ? (
                                        <div className="flex mt-4 sm:hidden">
                                          <div
                                            className="w-8 text-blue-500 cursor-pointer"
                                            onClick={() =>
                                              props.setUpdateJobId(
                                                data.job_details[0].id
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              class="h-5 w-5"
                                              viewBox="0 0 20 20"
                                              fill="currentColor"
                                            >
                                              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                              <path
                                                fill-rule="evenodd"
                                                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                                clip-rule="evenodd"
                                              />
                                            </svg>
                                          </div>
                                          <div
                                            className="w-8 text-red-500 cursor-pointer"
                                            onClick={() =>
                                              deleteModalOpen(
                                                data.job_details[0].id,
                                                data.job_details[0].title
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              class="h-6 w-6"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                              stroke-width="2"
                                            >
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                    <div className="w-10/12 ms-4 pt-2">
                                      <div className="w-full">
                                        <div className="flex justify-between">
                                          <div>
                                            <span className="font-medium text-lg">
                                              {data.job_details[0].title}
                                            </span>
                                            <p className="font-medium text-xs text-gray-400">
                                              {data.job_details[0].company_name}
                                              {/* {"Google Inc."} */}
                                            </p>
                                          </div>
                                          <div className="">
                                            {data.loggedInUser ===
                                            data.job_details[0].userId ? (
                                              <div className="hidden sm:flex">
                                                <div
                                                  className="w-8 text-blue-500 cursor-pointer"
                                                  onClick={() =>
                                                    props.setUpdateJobId(
                                                      data.job_details[0].id
                                                    )
                                                  }
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="h-5 w-5"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                  >
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path
                                                      fill-rule="evenodd"
                                                      d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                                      clip-rule="evenodd"
                                                    />
                                                  </svg>
                                                </div>
                                                <div
                                                  className="w-8 text-red-500 cursor-pointer"
                                                  onClick={() =>
                                                    deleteModalOpen(
                                                      data.job_details[0].id,
                                                      data.job_details[0].title
                                                    )
                                                  }
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                  >
                                                    <path
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                    />
                                                  </svg>
                                                </div>
                                              </div>
                                            ) : (
                                              <div></div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="mt-3 gap-3 flex flex-wrap">
                                          <span className="inline-block bg-blue-100 text-blue-800 py-1.5 px-2 text-xs font-medium rounded-md">
                                            {data.job_details[0].job_type}
                                          </span>
                                          <span className="inline-block bg-blue-100 text-blue-800 py-1.5 px-2 text-xs font-medium rounded-md">
                                            {data.job_details[0].location}
                                            {/* {"Quetta, Pakistan"} */}
                                          </span>
                                          <span className="inline-block bg-blue-100 text-blue-800 py-1.5 px-2 text-xs font-medium rounded-md">
                                            {"USD "}
                                            {data.job_details[0].salary
                                              .split("-")
                                              .map((salary, index) => {
                                                if (index === 0) {
                                                  return (
                                                    props.convertSalary(
                                                      salary
                                                    ) + " - "
                                                  );
                                                }
                                                return props.convertSalary(
                                                  salary
                                                );
                                              })}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Description */}
                                  <div className="my-3 px-2">
                                    <h3 className="font-medium text-lg">
                                      Job Description
                                    </h3>
                                    <p className="text-left text-sm font-normal">
                                      {data.job_details[0].description}
                                    </p>
                                  </div>

                                  {/* Responsibilities */}
                                  <div className="my-3 px-2">
                                    <h3 className="font-medium text-lg">
                                      Responsibilities
                                    </h3>
                                    <ul className="list-disc text-left ml-5">
                                      {data.job_details[0]?.responsibilites
                                        ?.split(".")
                                        .map((resp) => {
                                          if (resp !== "" && resp !== " ") {
                                            return <li>{resp}</li>;
                                          }
                                        })}
                                    </ul>
                                  </div>

                                  {/* Required Qualification */}
                                  <div className="my-3 px-2">
                                    <h3 className="font-medium text-lg">
                                      Required Qualification
                                    </h3>
                                    <ul className="list-disc text-left ml-5">
                                      {data.job_details[0]?.skills?.map(
                                        (e, i) => {
                                          return <li key={i}>{e}</li>;
                                        }
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
            <div className="w-0 xl:w-5/12 2xl:w-6/12 flex justify-end">
              <div className="w-full 2xl:w-10/12 hidden xl:block">
                <div className="block bg-white py-3 border-1 border-gray-150 rounded-lg shadow-sm">
                  <div className="px-3">
                    <div className="flex">
                      <div className="ps-4 pt-3">
                        {data.job_details[0].logo !== null &&
                        data.job_details[0].logo !== undefined ? (
                          <img
                            className="w-14 h-14 rounded-full"
                            src={
                              "https://www.meerkatsearch.com" +
                              data.job_details[0].logo
                            }
                            alt={data.job_details[0].company_name}
                          />
                        ) : (
                          <div className="flex items-center justify-center w-14 h-14 text-base font-medium text-white bg-blue-800 rounded-full">
                            {data.job_details[0].title.charAt(0)}
                          </div>
                        )}
                      </div>
                      <div className="w-10/12 ms-4 pt-2">
                        <div className="w-full">
                          <div className="flex justify-between">
                            <div>
                              <span className="font-medium text-lg">
                                {data.job_details[0].title}
                              </span>
                              <p className="font-medium text-xs text-gray-400">
                                {data.job_details[0].company_name}
                                {/* {"Google Inc."} */}
                              </p>
                            </div>
                            {data.loggedInUser ===
                            data.job_details[0].userId ? (
                              <div className="flex flex-wrap">
                                <div
                                  className="w-8 text-blue-500 cursor-pointer"
                                  onClick={() =>
                                    props.setUpdateJobId(data.job_details[0].id)
                                  }
                                >
                                  <Link to="/dashboard/updatejob" type="button">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="h-5 w-5"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                      <path
                                        fill-rule="evenodd"
                                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                  </Link>
                                </div>
                                <div
                                  className="w-8 text-red-500 cursor-pointer"
                                  onClick={() =>
                                    deleteModalOpen(
                                      data.job_details[0].id,
                                      data.job_details[0].title
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                  </svg>
                                </div>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                          <div className="mt-3 flex space-x-4">
                            <span className="inline-block bg-blue-100 text-blue-800 py-1.5 px-2 text-xs font-medium rounded-md">
                              {data.job_details[0].job_type}
                            </span>
                            <span className="inline-block bg-blue-100 text-blue-800 py-1.5 px-2 text-xs font-medium rounded-md">
                              {data.job_details[0].location}
                              {/* {"Quetta, Pakistan"} */}
                            </span>
                            <span className="inline-block bg-blue-100 text-blue-800 py-1.5 px-2 text-xs font-medium rounded-md">
                              {"USD "}
                              {data.job_details[0].salary
                                .split("-")
                                .map((salary, index) => {
                                  if (index === 0) {
                                    return props.convertSalary(salary) + " - ";
                                  }
                                  return props.convertSalary(salary);
                                })}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Description */}
                    <div className="my-3 px-2">
                      <h3 className="font-medium text-lg">Job Description</h3>
                      <p className="text-left text-sm font-normal">
                        {data.job_details[0].description}
                      </p>
                    </div>

                    {/* Responsibilities */}
                    <div className="my-3 px-2">
                      <h3 className="font-medium text-lg">Responsibilities</h3>
                      <ul className="list-disc text-left ml-5">
                        {data.job_details[0]?.responsibilites
                          ?.split(".")
                          .map((resp) => {
                            if (resp !== "" && resp !== " ") {
                              return <li>{resp}</li>;
                            }
                          })}
                      </ul>
                    </div>

                    {/* Required Qualification */}
                    <div className="my-3 px-2">
                      <h3 className="font-medium text-lg">
                        Required Qualification
                      </h3>
                      <ul className="list-disc text-left ml-5">
                        {data.job_details[0]?.skills?.map((e, i) => {
                          return <li key={i}>{e}</li>;
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )
  );
}
