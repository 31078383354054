import React, {useState} from 'react'
import InputField from '../Generic/InputField'
import { useUpdateUserPasswordMutation } from "../../services/nodeAPI";
import { VariantType, useSnackbar } from "notistack";

const Password=() => {

  const { enqueueSnackbar } = useSnackbar();
  const [userData, setUserData] = useState({});
  const [updateuserPassword] = useUpdateUserPasswordMutation();
  
  const updatePasswordFormData = (key, value) => {
    setUserData({
      ...userData,
      [key]: value,
    })
  }

  const submitPasswordForm = () => {
    if(userData.newPassword === userData.confNewPassword){
      updateuserPassword(userData).then((response) => {
        if (response.data.success) {
          enqueueSnackbar(response.data.message, { variant: "success" });
        } else {
          enqueueSnackbar(response.data.message, {
            variant: "error",
          });
        }
      }).catch((error) => {
        console.log(error)
      })
    }else{
      enqueueSnackbar("Confirm Password Missmatch!", {
        variant: "error",
      });
    }

  }

  return (

    <div id="password">

      <div className="mt-5">

        <h5 className='text-xl font-medium dark:text-white'>Security Settings</h5>
        <p className='test-base font-base text-gray-500 dark:text-white'>Update your profile password here.</p>
        <hr className='horizontal_bar w-64' />
      </div>

      <div className="gap-3 flex flex-wrap">

        <div className="pt-4 w-80">
          <span className='text-lg font-semibold dark:text-white'>Required Information</span>
          <p className='test-base font-base text-gray-500 dark:text-white'>Please provide all the required details to update your password</p>
        </div>

        <div className="">
          <div className=''><InputField placeholder="Current Password" type="password" width='100%' name="password" onChange={(event) => updatePasswordFormData(event.target.name, event.target.value)}/></div>
          <div className=''><InputField placeholder="New Password" type="password" width="100%" name="newPassword" onChange={(event) => updatePasswordFormData(event.target.name, event.target.value)}/></div>
          <div className=''><InputField placeholder="Confirm Password" type="password" width="100%" name="confNewPassword" onChange={(event) => updatePasswordFormData(event.target.name, event.target.value)}/></div>

        </div>

        {/* <hr className='horizontal_bar2' /> */}


      </div>
      <div className="text-end mt-5">
        <button 
          type="button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          onClick={submitPasswordForm}
        >
          Update Password
        </button>
        {/* <button className='btn save_changes' style={{ marginRight: "-20px !important" }} >Update Password</button> */}

      </div>

    </div>
  )
}

export default Password