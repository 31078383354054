import React, { useState, useEffect, Fragment } from "react";
import { PageHeader } from "../Generic/PageHeader";
import "./employees.css";
import { Select } from "antd";
import {
  useGetEmployeeWithIDQuery,
  useGetAllRoleNamesQuery,
} from "../../services/nodeAPI";
import { useGetEmployeesMutation } from "../../services/nodeAPI";
import { Spin, Alert } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEmployeeID } from "../../redux/employee";
import { Empty } from "antd";
import { Transition, Listbox, Menu } from "@headlessui/react";
import {
  CheckIcon,
  SelectorIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DeleteEmployee from "./DeleteEmployee";
import Spinner from "../Spinner";

export default function Employees() {
  const rId="4";

  const getPermissionsOfUserForResource=() => {

    let data=[];
    const permRes=JSON.parse( localStorage.getItem( 'perm_res' ) )


    permRes.forEach( el => {

      if ( el.resourceId==="4" ) {

        if ( !data.find( e => e===el.permissionId ) ) {
          data.push( el.permissionId )
        }

      }

    } )

    return data;

  }


  const { Option }=Select;
  const [ reloadStatus, setReloadStatus ]=useState( Date.now() )

  const dispatch=useDispatch();

  const [ getEmployees, isLoading ]=useGetEmployeesMutation();
  const [ employee, setEmployee ]=useState( null );
  const [ EMP, setEMP ]=useState( [] );

  const getEmployeeData=async ( fullname="", roleId ) => {
    var data={ fullname }
    if ( roleId!==0 ) {
      data[ "roleId" ]=roleId
    }
    const res=await getEmployees( data );
    setEmployee( res.data.employeeData );
    setEMP( res.data.employeeData );
    console.log( res );
  };

  const handleFilter=( val ) => {
    console.log( val )
    setSelectedRole( val )
    if ( val.name==="all" ) {
      setEmployee( EMP );
    } else {
      const arr=EMP.filter( ( el ) => el.rolename===val.name );
      setEmployee( arr );
    }

  };


  useEffect( () => {
    getEmployeeData();
    console.log( "Employee component has been mounted" )
  }, [ reloadStatus ] );

  const setEmpId=( id ) => {
    dispatch( setEmployeeID( id ) );
  };

  const {
    data: rolesData,
    error,
    isLoading: rolesLoading,
  }=useGetAllRoleNamesQuery();

  const [ selectedRole, setSelectedRole ]=useState( JSON.stringify( { id: 0, name: "All Employees" } ) );
  const [ showModal, setShowModal ]=useState( false );
  const [ delEmployeeId, setDelEmployeeId ]=useState( undefined );
  const [ delEmployeeName, setDelEmployeeName ]=useState( "" );

  const handleSearch=async ( e ) => {
    let roleId=JSON.parse( selectedRole ).id
    await getEmployeeData( e.target.value, roleId );
  };

  const getEmployeeDataWithRole=async ( data ) => {
    var fullname=""
    let roleData=JSON.parse( data )
    setSelectedRole( data )
    console.log( roleData )
    await getEmployeeData( fullname, roleData.id );
  };

  function openModal( id, name ) {
    setDelEmployeeId( id );
    setDelEmployeeName( name );
    setShowModal( true );
  }

  function closeModal() {
    setShowModal( false );
  }


  const permissions=getPermissionsOfUserForResource();
  console.log( "========", permissions )



  return (
    <div className="contianer_fluid px-2 pe-2" key={reloadStatus}>
      <DeleteEmployee
        showModal={showModal}
        closeModal={closeModal}
        employeeId={delEmployeeId}
        employeeName={delEmployeeName}
        setReloadStatus={setReloadStatus}
      />

      <div className="row my-4 pt-3">
        <div className="d-flex flex-wrap justify-content-between">
          <div>
            <span className="text-2xl font-medium dark:text-white">
              Employees
            </span>
            <p className="test-base font-base text-gray-500 dark:text-white">
              You can manage Meerkat employees in this section.
            </p>
          </div>
          {

            permissions.includes( "2" )&&
          <div className="my-auto">
            <Link
              to={"/dashboard/addemployee"}
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-base rounded text-xs px-4 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              <FontAwesomeIcon icon={faPlus} />{" "}
              <span className="ms-2"> Add New Employee</span>
            </Link>
          </div>
          }
        </div>
      </div>

      <div className="row my-3">
        <div className="w-100">
          <span className="text-lg font-semibold dark:text-white">
            Search Filters
          </span>
          <p className="test-base font-base text-gray-500 dark:text-white">
            With the help of filters you can easily find employees.
          </p>

          <div className="gap-3 flex flex-wrap items-center mt-3">
            <div className="relative w-74 md:w-50">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="search"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Employee by name..."
                onChange={handleSearch}
              />
            </div>

            <div className="w-52">
              <div className="">
                <Listbox value={selectedRole} onChange={getEmployeeDataWithRole}>
                  <div className="relative md:ml-2">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white border py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      <span className="block truncate">
                        {/* {console.log(selectedRole)} */}
                        {JSON.parse( selectedRole ).name}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 z-10 cursor-pointer w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {
                          <Listbox.Option
                            key={0}
                            className={( { active } ) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                ? "bg-blue-100 text-blue-700"
                                :"text-gray-900"
                              }`
                            }
                            value={JSON.stringify( {
                              id: 0,
                              name: "All Employees",
                            } )}
                          >
                            {( { selected } ) => (
                              <>
                                <span
                                  className={`block truncate ${selected? "font-medium":"font-normal"
                                    }`}
                                >
                                  {"All Employees"}
                                </span>
                                {selected? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ):null}
                              </>
                            )}
                          </Listbox.Option>
                        }
                        {rolesData&&
                          rolesData.roles.filter( role => role.id!=='1' ).map( ( role, roleIdx ) => (
                            <Listbox.Option
                              key={roleIdx+1}
                              className={( { active } ) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                  ? "bg-blue-100 text-blue-700"
                                  :"text-gray-900"
                                }`
                              }
                              value={JSON.stringify( {
                                id: role.id,
                                name: role.name,
                              } )}
                            >
                              {( { selected } ) => (
                                <>
                                  <span
                                    className={`block truncate ${selected? "font-medium":"font-normal"
                                      }`}
                                  >
                                    {role.name}
                                  </span>
                                  {selected? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ):null}
                                </>
                              )}
                            </Listbox.Option>
                          ) )}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <hr className="separation_margin" /> */}

      {/* Cards */}

      <section className="employee_cards">
        {isLoading.isLoading||!employee||rolesLoading? (
          // <Spin tip="Loading..."></Spin>

          <Spinner />

        ):employee.length>0? (
          employee.map( ( el ) => {
            return (
              <div
                key={el.id}
                className="w-64 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mr-5 mt-4 pt-3 pr-2"
              >
                <div class="flex justify-end">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="sm:inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5">
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                        </svg>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">

                          {

                            permissions.includes( "1" )&&
                            <Menu.Item
                            onClick={() => {
                              console.log( el.id )
                              setEmpId( el.id );
                            }}

                          >
                            {( { active } ) => (
                              <Link
                                to="/dashboard/updateemployee"
                                type="button"
                                // disabled={true}
                                className={`${active
                                  ? "bg-blue-500 text-white"
                                  :"text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                <PencilIcon
                                  className="mr-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                                Edit
                              </Link>
                            )}
                          </Menu.Item>
                          }


                          {
                            permissions.includes( "4" )&&
                            <Menu.Item
                            onClick={() => {
                              openModal( el.id, el.fullname );
                            }}
                          >
                            {( { active } ) => (
                              <button
                                className={`${active
                                  ? "bg-blue-500 text-white"
                                  :"text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                <TrashIcon
                                  className="mr-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                                Delete
                              </button>
                            )}
                            </Menu.Item>}


                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div class="flex flex-col items-center pb-10">
                  {
                    el.profile_img===null?
                      <div
                        id="avatar"
                        className="flex items-center justify-center mb-3 w-24 h-24 text-3xl font-medium text-white bg-blue-500 rounded-full"
                      >
                        {el.fullname!==undefined? el.fullname.charAt( 0 ):""}
                      </div>

                      :

                      <img
                        class="mb-3 w-24 h-24 rounded-full shadow-md"
                        src={`https://www.meerkatsearch.com/${el.profile_img}`}
                        alt={el.fullname}
                      />

                  }

                  <h5 class="mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                    {el.fullname}
                  </h5>

                  <span class="bg-blue-100 text-blue-800 text-sm font-normal mr-2 px-4 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                    {el.rolename}
                  </span>

                  <div className="mt-4 text-center">
                    <div class="text-gray-700 text-base font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                      {el.phone_number}
                    </div>

                    <span class="text-blue-600 text-sm font-normal mr-2 px-2.5 rounded dark:bg-gray-700 dark:text-gray-300">
                      {el.email}
                    </span>
                  </div>

                  {/* <span class="text-sm text-gray-500 dark:text-gray-400">

                  </span> */}
                </div>
              </div>
            );
          } )
        ):(
          <Empty className="mt-5" />
        )}
      </section>
    </div>
  );
}
