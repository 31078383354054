import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../avatar/Avatar';
import { FaLinkedin } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { HiOutlineMail } from 'react-icons/hi';
import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { isStringEmpty } from '../../../../lib/helper';
import CandidateAPI from '../../../../services/CandidateAPI';

const ResumeDialog = ({
  className,
  closeModal,
  isModalOpen,
  candidateId,
  ...divProps
}) => {
  const [skills, setSkills] = useState()
  const [workExp, setWorkExp] = useState()
  const [basicInfo, setBasicInfo] = useState()
  const [education, setEducation] = useState()

  useEffect(() => {
    getData()
  }, [candidateId])

  function getData() {
    CandidateAPI.getCandidateData(candidateId).then((response) => {
      setSkills(response?.skills)
      setWorkExp(response?.work_exp)
      setEducation(response?.education)
      setBasicInfo(response?.basic_info)
    }).catch((error) => {
      console.log(error)
    })
  }

  function getFormattedDate(date) {
    if (date !== undefined && date !== null) {
      const formatted_date = new Date(date).toLocaleString("default", {
        month: "short",
        year: "numeric"
      });

      if (formatted_date === "Jan 1") {
        return undefined
      }
      return formatted_date
    }
    return undefined
  }

  function setupDate(start_date, end_date) {
    const formatted_start_date = getFormattedDate(start_date)
    const formatted_end_date = getFormattedDate(end_date)

    if (formatted_start_date === undefined && formatted_end_date === undefined) {
      return ""
    } else if (formatted_start_date !== undefined && formatted_end_date === undefined) {
      return `${formatted_start_date} - Present`
    } else {
      return `${formatted_start_date} - ${formatted_end_date}`
    }
  }

  return (
    <div {...divProps}>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[96rem] transform overflow-hidden rounded-xl bg-white px-6 pt-6 pb-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >                
                  </Dialog.Title>

                  <div className='flex justify-between'>
                    {/* Left Side Tab, Info, pic, skills */}
                    <div className='flex flex-col w-3/12 space-y-4 items-end'>
                      <Avatar 
                        className='w-44 h-44'
                        profileData={basicInfo}
                      />
                      {/* Info Tab Location, email, etc */}
                      <div className='flex flex-col w-full space-y-2 rounded-md text-right mt-5'>
                        {
                          isStringEmpty(basicInfo?.email) === false ? (
                            <div className='flex flex-row space-x-2 justify-end'>
                              <div className='flex flex-col text-sm mt-1.5'>
                                <span className='font-semibold text-black'>{basicInfo?.email}</span>
                                <span className='font-normal text-gray-700'>Email Address</span>
                              </div>
                              <div className='border border-black rounded-full w-max p-3'>
                                <HiOutlineMail className='w-5 h-5'/>
                              </div>                          
                            </div>
                          ):(
                            <></>
                          )
                        }
                        {
                          ((isStringEmpty(basicInfo?.city) === true) && (isStringEmpty(basicInfo?.country))) === true ? (
                            <></>
                          ) : (
                            <div className='flex flex-row space-x-2 justify-end'>
                              <div className='flex flex-col text-sm mt-1.5'>
                                <span className='capitalize font-semibold text-black'>{basicInfo?.city}, {basicInfo?.country}</span>
                                <span className='font-normal text-gray-700'>Location</span>
                              </div>
                              <div className='border border-black rounded-full w-max p-3'>
                                <MdLocationOn className='w-5 h-5 text-gray-700' />
                              </div>                          
                            </div>
                          )
                        }
                      </div>
                      <div>
                          <a
                            href={basicInfo?.linkedin_url}
                            target="_blank"
                            type='button'
                            className='flex flex-row space-x-2 bg-blue-600 text-white rounded-md shadow-sm px-4 py-2'
                          >
                            <FaLinkedin className='w-5 h-5' />
                            <span className='text-sm font-medium'>LinkedIn Profile</span>
                          </a>
                      </div>

                      <div className='mt-5 mb-3 w-8/12 h-1 border-2 border-black bg-black rounded-full'>
                      </div>                      

                      <div className='text-xl font-semibold text-gray-800'>
                        Skills
                      </div>
                      {/* Skills Part */}
                      <div className='w-full gap-2 flex flex-wrap p-2 max-h-[22rem] overflow-y-scroll no-scrollbar'>
                        {
                          skills?.map((item, index) => {
                            return (
                              <div key={index} className='border bg-gray-50 text-sm font-medium p-2 rounded-lg'>
                                <span className='capitalize' >{item?.name}</span>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    {/* Right Side Tab (Name, Summary, Expereinces, Education) */}
                    <div className='flex flex-col w-9/12 max-h-[54rem] ml-16 space-y-4 px-4 text-start items-start overflow-y-scroll custom-scrollbar'>
                      <div className='flex flex-col'>
                        <span className='capitalize text-3xl font-semibold text-gray-900'>{basicInfo?.firstname} {basicInfo?.lastname}</span>
                        <span className='text-sm font-medium text-gray-600'></span>
                      </div>
                      <div className='text-sm text-left whitespace-pre-wrap'>
                        <p className=''>{basicInfo?.summary}</p>
                      </div>

                      <div className='mt-3 w-2/12 h-1 border-2 border-black bg-black rounded-full'>
                      </div>

                      <div className='flex flex-col w-full'>
                        <span className='text-lg font-medium text-gray-600' >Education</span>
                        {
                          education?.map((item, index) => {
                            return (
                              <div key={index} className='flex flex-row w-full justify-between mt-2'>
                                <div className='flex flex-col capitalize'>
                                  <span className='text-base font-semibold text-gray-900'>{item?.institute}</span>
                                  <span className='text-sm font-medium text-gray-600'>{item?.type}</span>
                                  <span className='text-sm font-medium text-gray-600'>{item?.title}</span>
                                </div>
                                <div className=''>
                                  <span className='text-normal font-semibold text-gray-800'>{setupDate(item?.start_date, item?.end_date)}</span>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                      
                      <div className='mt-4 w-2/12 h-1 border-2 border-black bg-black rounded-full'>
                      </div>

                      <div className='flex flex-col w-full'>
                        <span className='text-lg font-medium text-gray-600'>Work Experience</span>
                        {
                          workExp?.map((item, index) => {
                            return (
                              <div key={index} className='flex flex-col w-full mt-2'>
                                <div className='flex flex-row justify-between'>
                                  <div className='flex flex-col'>
                                    <span className='text-base font-semibold text-gray-900'>{item?.position}</span>
                                    <span className='text-base font-medium text-gray-600'>{item?.company_name}</span>
                                  </div>
                                  <div className=''>
                                    <span className='text-normal font-semibold text-gray-800'>{setupDate(item?.join_date, item?.end_date)}</span>
                                  </div>
                                </div>
                                <div className='text-sm text-left mt-1.5 whitespace-pre-wrap'>
                                  <p>{item?.summary}</p>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default ResumeDialog;

ResumeDialog.defaultProps = {
};

ResumeDialog.propTypes = {
  className: PropTypes.string
};
