import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../avatar/Avatar';
import { isStringEmpty } from '../../../lib/helper';
import { MdTitle, MdLocationOn, MdOutlineWorkHistory } from "react-icons/md";
import CustomSpinner from '../spinner/CustomSpinner';
import Skeleton from './Skeleton';

const SmallCardList = ({
    candidates,
    className,
    candidatesFound,
    updateCandidateSelection,
    ...divProps 
}) => {
    return (
        <div className={`max-h-[42rem] space-y-4 overflow-scroll no-scrollbar mt-2 ${className}`} {...divProps}>
            {
                candidates?.map((candidate, index) => {
                    return (
                        <div 
                            key={index} 
                            className='border-1 rounded-lg p-3 cursor-pointer hover:shadow-md'
                            onClick={() => updateCandidateSelection(candidate?.basic_info?.id)}
                        >
                            <div className='flex flex-row space-x-4 items-center'>
                                <div className=''>
                                    {/* Image */}
                                    <Avatar 
                                        className='w-16 h-16'
                                        profileData={candidate?.basic_info}
                                    />
                                </div>
                                <div className='flex flex-col'>
                                    {/* Name */}
                                    <span className='font-semibold text-lg'>{candidate?.basic_info?.firstname} {candidate?.basic_info?.lastname}</span>
                                    {/* Location */}
                                    {
                                        ((isStringEmpty(candidate?.basic_info?.city) === true) && (isStringEmpty(candidate?.basic_info?.country))) === true ? (
                                            <></>
                                        ) : (
                                            <div className='flex flex-row space-x-2 justify-start items-center'>
                                                <div className='rounded-full w-max'>
                                                    <MdLocationOn className='w-5 h-5 text-gray-700' />
                                                </div>
                                                <div className='flex flex-col text-sm'>
                                                    <span className='capitalize font-normal text-gray-700'>{candidate?.basic_info?.city}, {candidate?.basic_info?.country}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className='flex flex-col mt-2 space-y-2'>
                                {/* Years of Experience */}
                                <div className='flex flex-row space-x-2 items-center justify-start'>
                                    <div className='border rounded-full w-max p-2.5'>
                                        <MdOutlineWorkHistory className='w-5 h-5' />
                                    </div>
                                    <div className='flex text-sm'>
                                        <span className='capitalize font-normal text-gray-700'>
                                            { candidate?.basic_info?.experience.years }
                                            { candidate?.basic_info?.experience.years > 1 ? <> Years of Experience</>:<>Year of Experience</>} 
                                        </span>
                                    </div>
                                </div>
                                {/* Job Title */}
                                {
                                    candidate?.work_exp?.length > 0 ? (<>
                                        <div className='flex flex-row space-x-2 items-center justify-start'>
                                            <div className='border rounded-full w-max p-2.5'>
                                                <MdTitle className='w-5 h-5'/>
                                            </div>
                                            <div className='flex text-sm'>
                                                <span className='capitalize font-normal text-gray-700'>{candidate?.work_exp[0]?.position}</span>
                                            </div>
                                        </div>
                                    </>) : (<></>)
                                }

                                {/* Summary */}
                                <div>
                                    <span className='line-clamp-3'>{candidate?.basic_info?.summary}</span>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {
                candidates?.length < candidatesFound ? <Skeleton className={'relative'} />: <></>
            }
        </div>
    );
};

export default SmallCardList;

SmallCardList.defaultProps = {
    candidates: []
};

SmallCardList.propTypes = {
    candidates: PropTypes.array,
    className: PropTypes.string,
    candidatesFound: PropTypes.number,
    updateCandidateSelection: PropTypes.func
};
