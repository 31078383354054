import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { MdOutlineAdd } from 'react-icons/md'
import { IoIosClose } from 'react-icons/io'

const DynamicFilter = ({
    name,
    icon,
    label,
    className,
    updateFilter,
    ...divProps 
}) => {
    const [inputValue, setInputValue] = useState("")
    const [isInputHidden, setInputHidden] = useState(true)
    const [items, setItems] = useState([])

    const containerRef = useRef(null)

    useEffect(() => {
        const container = containerRef.current;
        container.scrollLeft = container.scrollWidth;
        containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
    }, [isInputHidden])

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && inputValue !== "") {
          setItems([...items, inputValue]);
          setInputHidden(true);
          setInputValue("");
        }
    };
    
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };
    
    useEffect(() => {
        if (name !== undefined) {
            updateFilter(name, items)
        }
    }, [items])

    const removeItem = (data) => {
        const filteredItems = items.filter(item => item !== data)
        setItems(filteredItems)
    }

    return (
        <div
        {...divProps}        
        className={`flex flex-row border-1 border-gray-300 rounded-md h-11 ${className}`}
        >
            <div ref={containerRef} className='gap-2 flex flex-row items-center text-sm px-2.5 py-1.5 max-w-md overflow-scroll no-scrollbar'>
                <div className='flex flex-row space-x-1'>
                    {icon}
                    <span className='w-max font-medium text-black'>
                        {label}
                    </span>
                </div>
                {
                    items.map((item, index) => {
                        return (
                            <div key={index} className='flex flex-row space-x-2 bg-gray-100 border px-2.5 py-0.5 text-sm rounded-xl hover:bg-gray-200'>
                                <div className='w-max'>{item}</div>
                                <IoIosClose className='w-5 h-5 cursor-pointer' onClick={
                                    () => removeItem(item)
                                } />
                            </div>
                        )
                    })
                }
                <input 
                    type='text' 
                    name='filter-item' 
                    className={`w-36 bg-gray-100 px-2.5 py-0.5 text-sm rounded-md border hover:bg-gray-200 ring-0 focus:ring-0 ${
                        isInputHidden ? 'hidden' : ''
                    }`}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <div
                className='flex items-center border-l px-2 cursor-pointer hover:bg-gray-100'
                onClick={() => setInputHidden(false)}
            >
                <MdOutlineAdd className='w-5 h-5' />
            </div>
        </div>
    );
};

export default DynamicFilter;

DynamicFilter.defaultProps = {};

DynamicFilter.propTypes = {
    key: PropTypes.string,
    icon: PropTypes.object,
    label: PropTypes.string,
    className: PropTypes.string,
    updateFilter: PropTypes.func,
};
